import React,{useEffect, useState, useRef} from "react";
import {useRecoilValue,useRecoilState} from "recoil";
import API from "../../api";
import {usersID, usersLoginToken, firebaseId} from "../../App";
import SessionPopupComponent from "../common/SessionPopup"

export const withHealthMonitorApiCall = UIComponent => (props) => {
    const {setUserId, sessionFirebaseToken, tokenId, setTokenId, refreshId} = props;
    const usersuid = useRecoilValue(usersID);
    const usersToken = useRecoilValue(usersLoginToken);
    const [logoutUserToken, setLogoutUserToken] = useRecoilState(usersLoginToken);
    const firebaseid = useRecoilValue(firebaseId);
    const [sessionTitle, setSessionTitle] = useState("");
    const [sessionMessage, setSessionMessage] = useState("");
    const [sessionToken, setSessionToken] = useState("");
    const documentVisible = useRef(true);

    const resetState = () => {
      setSessionMessage('')
      setSessionTitle('')
      setSessionToken('')
    }
    
      const retryApiPost = (url,data,header)=>{
        return API.post(url,{...data,id:usersuid},{...header,headers:{...header.headers,uid:firebaseid}}).then((res)=>{
          if(res?.headers?.userid===String(usersuid)){
            return Promise.resolve(res)
          }else{
            throw {message:"Invalid Response"}
            //return Promise.reject(new Error({message:'invalid access'}))
          }
        }).catch(function(error){if([401,500,502,504,703,704].includes(error?.response?.status)){
          return new Promise(resolve=>{
            API.post("/userEndpoints/v1/renewIdToken",{
              "token": refreshId
            })
            .then((res)=>{
              setTokenId(res.data.idToken)
              if(!res.data.idToken){
               // setExpiredMsg(true)
                throw error
              }else{
                resolve(API.post(url,{...data,id:usersuid},{headers:{
                  Authorization:res.data.idToken,
                  uid:firebaseid
                }}).then((res)=>{
                  if(res?.headers?.userid===String(usersuid)){
                    return Promise.resolve(res)
                  }else{
                    throw {message:"Invalid Response"}
                    //return Promise.reject(new Error({message:'invalid access'}))
                  }
                }))
              }
            })
            .catch((error) =>{Promise.reject(error)})
          })
        }else{
          throw error
        }})
      } 
   useEffect(()=>{
       if(logoutUserToken !== sessionFirebaseToken){
         const pageAccessedByReload = (
          (PerformanceNavigationTiming && PerformanceNavigationTiming.TYPE === 1) ||
            window.performance
              .getEntriesByType('navigation')
              .map((nav) => nav.type)
              .includes('reload')
        );
       
        const timerApi = ()=>{
          if(usersuid&&usersToken){
            retryApiPost("adminUserEndpoints/v1/sendAdminHealthMonitorEvent",{
                "id":usersuid,
                "token":usersToken,
                "screenName":'Hospital'
            },{
                headers: {
                  'Authorization': tokenId,
                },
            }).then((res)=>{
                const response = res.data
                setSessionTitle(response.title)
                setSessionMessage(response.errorMessage);
                setSessionToken(response.token);
            }).catch(()=>{
    
            })
           }}
           const docVisibility=()=>{
            if (document.hidden) {
              documentVisible.current = false
            } else {
              documentVisible.current = true
                timerApi()
            }
           }

           document.addEventListener('visibilitychange', docVisibility);

          const interval = setInterval(()=>{
             if(documentVisible.current){
              timerApi()
            }
          }, 120*1000)
           
         // const interval = setInterval(timerApi, 120*1000)
          window.addEventListener('focus', timerApi)

          if (pageAccessedByReload == true) {
            timerApi()
          }
          return ()=>{
              if(interval){
                  clearInterval(interval)
              }
              window.removeEventListener('focus', timerApi)
              document.removeEventListener('visibilitychange', docVisibility);
          }
         
       }
    },[logoutUserToken, sessionFirebaseToken])
    return(
        <>
        <UIComponent {...props} />
        <SessionPopupComponent 
        sessionTitle={sessionTitle} 
        sessionMessage={sessionMessage} 
        sessionToken={sessionToken}
        setUserId={setUserId}
        setLogoutUserToken={setLogoutUserToken}
        logoutUserToken={ logoutUserToken}
        refreshId={refreshId}
        setTokenId={setTokenId}
        resetState={resetState}
        />
        </>
    )
  }

export const useHealthMonitorApiCall = (sessionFirebaseToken,retryApiPost,tokenId) => {
  const usersuid = useRecoilValue(usersID);
  const usersToken = useRecoilValue(usersLoginToken);
  const firebaseid = useRecoilValue(firebaseId);
  const [logoutUserToken, setLogoutUserToken] = useRecoilState(usersLoginToken);
  const [sessionTitle, setSessionTitle] = useState("");
  const [sessionMessage, setSessionMessage] = useState("");
  const [sessionToken, setSessionToken] = useState("");
  const documentVisible = useRef(true);

  const resetState = () => {
    setSessionMessage('')
    setSessionTitle('')
    setSessionToken('')
  }
  /*const retryApiPost = (url,data,header)=>{
    return API.post(url,{...data,id:usersuid},{...header,headers:{...header.headers,uid:firebaseid}}).then((res)=>{
      if(res?.headers?.userid===String(usersuid)){
        return Promise.resolve(res)
      }else{
        throw {message:"Invalid Response"}
        //return Promise.reject(new Error({message:'invalid access'}))
      }
    }).catch(function(error){if([401,500,502,504,703,704].includes(error?.response?.status)){
      return new Promise(resolve=>{
        API.post("/userEndpoints/v1/renewIdToken",{
          "token": refreshId
        })
        .then((res)=>{
          setTokenId(res.data.idToken)
          if(!res.data.idToken){
           // setExpiredMsg(true)
            throw error
          }else{
            resolve(API.post(url,{...data,id:usersuid},{headers:{
              Authorization:res.data.idToken,
              uid:firebaseid
            }}).then((res)=>{
              if(res?.headers?.userid===String(usersuid)){
                return Promise.resolve(res)
              }else{
                throw {message:"Invalid Response"}
                //return Promise.reject(new Error({message:'invalid access'}))
              }
            }))
          }
        })
        .catch((error) =>{Promise.reject(error)})
      })
    }else{
      throw error
    }})
  }*/
  useEffect(()=>{
    if(logoutUserToken !== sessionFirebaseToken){
      const pageAccessedByReload = (
       (PerformanceNavigationTiming && PerformanceNavigationTiming.TYPE === 1) ||
         window.performance
           .getEntriesByType('navigation')
           .map((nav) => nav.type)
           .includes('reload')
     );
    
     const timerApi = ()=>{
       if(usersuid&&usersToken){
         retryApiPost("adminUserEndpoints/v1/sendAdminHealthMonitorEvent",{
             "id":usersuid,
             "token":usersToken,
             "screenName":'Hospital'
         },{
             headers: {
               'Authorization': tokenId,
             },
         }).then((res)=>{
             const response = res.data
             setSessionTitle(response.title)
             setSessionMessage(response.errorMessage);
             setSessionToken(response.token);
         }).catch(()=>{
 
         })
        }}
        const docVisibility=()=>{
         if (document.hidden) {
           documentVisible.current = false
         } else {
           documentVisible.current = true
             timerApi()
         }
        }

        document.addEventListener('visibilitychange', docVisibility);

       const interval = setInterval(()=>{
          if(documentVisible.current){
           timerApi()
         }
       }, 120*1000)
        
      // const interval = setInterval(timerApi, 120*1000)
       window.addEventListener('focus', timerApi)

       if (pageAccessedByReload == true) {
         timerApi()
       }
       return ()=>{
           if(interval){
               clearInterval(interval)
           }
           window.removeEventListener('focus', timerApi)
           document.removeEventListener('visibilitychange', docVisibility);
       }
      
    }
 },[logoutUserToken, sessionFirebaseToken])

 return [sessionTitle,sessionMessage,sessionToken,resetState]

}

export const HealthMonitorApiCall =({children, setUserId,setLogoutUserToken,
    logoutUserToken, sessionFirebaseToken, tokenId, setTokenId, refreshId})=>{
    const usersuid = useRecoilValue(usersID);
    const usersToken = useRecoilValue(usersLoginToken);
    const firebaseid = useRecoilValue(firebaseId);
    const [sessionTitle, setSessionTitle] = useState("");
    const [sessionMessage, setSessionMessage] = useState("");
    const [sessionToken, setSessionToken] = useState("");
    const documentVisible = useRef(true);

    const resetState = () => {
      setSessionMessage('')
      setSessionTitle('')
      setSessionToken('')
    }
    
      const retryApiPost = (url,data,header)=>{
        return API.post(url,{...data,id:usersuid},{...header,headers:{...header.headers,uid:firebaseid}}).then((res)=>{
          if(res?.headers?.userid===String(usersuid)){
            return Promise.resolve(res)
          }else{
            throw {message:"Invalid Response"}
            //return Promise.reject(new Error({message:'invalid access'}))
          }
        }).catch(function(error){if([401,500,502,504,703,704].includes(error?.response?.status)){
          return new Promise(resolve=>{
            API.post("/userEndpoints/v1/renewIdToken",{
              "token": refreshId
            })
            .then((res)=>{
              setTokenId(res.data.idToken)
              if(!res.data.idToken){
               // setExpiredMsg(true)
                throw error
              }else{
                resolve(API.post(url,{...data,id:usersuid},{headers:{
                  Authorization:res.data.idToken,
                  uid:firebaseid
                }}).then((res)=>{
                  if(res?.headers?.userid===String(usersuid)){
                    return Promise.resolve(res)
                  }else{
                    throw {message:"Invalid Response"}
                    //return Promise.reject(new Error({message:'invalid access'}))
                  }
                }))
              }
            })
            .catch((error) =>{Promise.reject(error)})
          })
        }else{
          throw error
        }})
      } 
   useEffect(()=>{
       if(logoutUserToken !== sessionFirebaseToken){
         const pageAccessedByReload = (
          (PerformanceNavigationTiming && PerformanceNavigationTiming.TYPE === 1) ||
            window.performance
              .getEntriesByType('navigation')
              .map((nav) => nav.type)
              .includes('reload')
        );
       
        const timerApi = ()=>{
          if(usersuid&&usersToken){
            retryApiPost("adminUserEndpoints/v1/sendAdminHealthMonitorEvent",{
                "id":usersuid,
                "token":usersToken,
                "screenName":'Hospital'
            },{
                headers: {
                  'Authorization': tokenId,
                },
            }).then((res)=>{
                const response = res.data
                setSessionTitle(response.title)
                setSessionMessage(response.errorMessage);
                setSessionToken(response.token);
            }).catch(()=>{
    
            })
           }}
           const docVisibility=()=>{
            if (document.hidden) {
              documentVisible.current = false
            } else {
              documentVisible.current = true
                timerApi()
            }
           }

           document.addEventListener('visibilitychange', docVisibility);

          const interval = setInterval(()=>{
             if(documentVisible.current){
              timerApi()
            }
          }, 120*1000)
           
         // const interval = setInterval(timerApi, 120*1000)
          window.addEventListener('focus', timerApi)

          if (pageAccessedByReload == true) {
            timerApi()
          }
          return ()=>{
              if(interval){
                  clearInterval(interval)
              }
              window.removeEventListener('focus', timerApi)
              document.removeEventListener('visibilitychange', docVisibility);
          }
         
       }
    },[logoutUserToken, sessionFirebaseToken])
    return(
        <>
        {children}
        <SessionPopupComponent 
        sessionTitle={sessionTitle} 
        sessionMessage={sessionMessage} 
        sessionToken={sessionToken}
        setUserId={setUserId}
        setLogoutUserToken={setLogoutUserToken}
        logoutUserToken={ logoutUserToken}
        refreshId={refreshId}
        setTokenId={setTokenId}
        resetState={resetState}
        />
        </>
    )
};
