import React, { useEffect, useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import oc_logo from "../../assets/images/omnicure_logo.svg";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import CloseIcon from "@material-ui/icons/Close";
import Alert from "@material-ui/lab/Alert";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import axios from 'axios';
import { Grid, Card, CardActionArea, Collapse, IconButton, AppBar, Toolbar, useMediaQuery } from '@material-ui/core';
import clsx from 'clsx';
import Avatar from '@material-ui/core/Avatar';
import TimelineIcon from '@material-ui/icons/Timeline';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Badge from '@material-ui/core/Badge';
import { UILevelPermission, permission, PermissionCollection, usersID, DelayNotificationTime, roles, firebaseId,AlertListNotification,unReadNotificationList,ShowNotification,unreadNotificationCount } from 'App';
import { useRecoilValue, useSetRecoilState, useRecoilState, useResetRecoilState } from 'recoil';
import {
  ACTIVE_CONSULT_SCREEN,
  ADMIN_APP,
  REPORT_APP,
  ADMIN_USER_SCREEN,
  APPROVAL_SCREEN,
  DASHBOARD_APP,
  DASHBOARD_SCREEN,
  HOSPITAL_SCREEN,
  LOGIN_SCREEN,
  PATIENT_SCREEN,
  PENDING_CONSULT_SCREEN,
  REPORT_SCREEN,
  STAFFING_SCREEN,
  SYSTEM_ALERT,
  WARDS_SCREEN,
  REPORT_SCREEN_HOSPITAL
} from "../../AppConstants";
import API from "../../api"
import ExpiredMessage from "../common/ExpiredMessage";
import LoginLoader from "../common/LoginLoader";
import { pluck, groupBy, assocPath } from 'ramda';
import {ReactComponent as OmnicureTextIcon} from 'assets/images/OmnicureTextIcon.svg';
import {ReactComponent as HomeIcon} from 'assets/images/Home.svg';
import {ReactComponent as SettingIcon} from 'assets/images/Setting.svg';
import {ReactComponent as TrendingIcon} from 'assets/images/trending_up.svg';
import {ReactComponent as AdminIcon} from 'assets/images/Admin.svg';
import {ReactComponent as DashboardIcon} from 'assets/images/Dashboard.svg';
import {ReactComponent as ReportsIcon} from 'assets/images/Reports.svg';
import onlineDot from 'assets/images/greenDot.svg';
import {useHealthMonitorApiCall} from 'components/common/HealthMonitor';
import SessionPopupComponent from "components/common/SessionPopup"

import createAuthRefreshInterceptor from "axios-auth-refresh";

const administrationAccessPrecedence = ['superAdmin', 'enterpriseAdmin', 'supportadminAccess']

const dashboardAccessPrecedence = ['superAdmin', 'dashboardSuperAdmin', 'dashboardUserAdmin']

const useAvatarStyles = makeStyles((theme) => ({
  root: {
    //marginRight: 10,
  },
  profileCircle: {
    color: "#fafafa",
    backgroundColor: "#B3BAC5",
    width: "40px",
    height: "40px",
    position:'relative',
    overflow:'visible',
    '&::after':{
      content:"''",
      height:12,
      width:12,
      background:`url(${onlineDot})`,
      position:'absolute',
      right:0,
      top:0
    }
  },
}))

const useStylesHtml = makeStyles((theme) => ({
  header: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 60,
    background: '#fff'
  },
  iconStyle: {
    fontSize: "2rem",
    color: "rgba(0, 0, 0, 0.54)"
    // margin: '5px 0px 5px 5px'
  },
  menuList: {
    padding: "0px 8px !important",
    '&:hover':{
        background:'#EFF1F5',
        fontWeight:'bold'
    }
},
menuContainer:{
    border: '1px solid #5E6C84',
    boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.15)',
    borderRadius: 8,
    fontFamily:'Poppins'
},
  card: {
    width: 260,
    height: 188,
    fontSize: 18,
    background: '#FFFFFF',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: 14
    // [theme.breakpoints.down('md')]: {
    //   width: '60%',
    //   margin: 12
    // },
    // ['@media (min-height:800px)']: {
    //   height: '25vh',
    //   width: '80%'
    // }
  },
  cardHover: {
    '&:hover': {
      boxShadow: '0 0 11px #9B51E0',
      fontWeight: 'bold'
    }
  },
  cardActionArea: {
    width: '100%',
    height: '100%',
    padding: 10,
    fontSize: 20,
    lineHeight: '36px',
    fontFamily:'Poppins',
    color:'#172B4D',
    rowGap:17,
    '&:hover': {
      fontWeight: 'bold',
      fontSize: 18
    },
    '&:disabled': {
      backgroundColor: '#ebebeb',
      color: 'grey'
    }
  },
  alertContainer: {
    position: 'absolute',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    top: 80
  },
  toolBar: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft:'40px !important',
    paddingRight:'40px !important',
    boxShadow: '0px 6px 3px rgba(0, 0, 0, 0.1)'
  },
  // menuList: {
  //   padding: "0px 8px !important",
  // },
  menuItem: {
    margin: "65px 0 0 0",
  },
}))

const NavigationComponent = ({
  userId,
  setUserId,
  app,
  setApp,
  setUserType,
  userType,
  setUserDetails,
  history,
  userPermission,
  setUserPermission,
  tokenId,
  setTokenId,
  refreshId,
  setRefreshId,
  adminPages,
  dashboardPages,
  setAppInternalState,
  setAdminAppAccess,
  setDashboardAppAccess,
  setReportAppAccess,
  setFirebaseId,
  setUsersUid,
  logoutUserToken,
  setLogoutUserToken,sessionFirebaseToken
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [avatoranchorEl, setAvatarAnchorEl] = useState(null);
  const avatarStyles = useAvatarStyles();
  const [open, setOpen] = useState(false)
  const userEmail = sessionStorage.getItem('userId');
  const userDetailsJSON = sessionStorage.getItem('userDetails')
  const [alertMSG, setAlertMSG] = useState('')
  const userDetails = JSON.parse(Boolean(userDetailsJSON) || userDetailsJSON !== 'null' ? userDetailsJSON : {})
  const [expiredMsg, setExpiredMsg] = useState(false);
  const [expiredLogin, setexpiredLogin] = useState(false);
  const [loading, setLoading] = useState(false);
  const [homeCallBack, setHomeCallBack] = useState("")
  const adminAccess = useRecoilValue(UILevelPermission("adminAccess"))
  const dashboardAccess = useRecoilValue(UILevelPermission("dashboardAccess"))
  const webPortalAccess = useRecoilValue(UILevelPermission("webPortalAccess"))
  const setPermissionArray = useSetRecoilState(permission)
  const setPermissionPagesArray = useSetRecoilState(PermissionCollection)
  const setNotificationDelay = useSetRecoilState(DelayNotificationTime)
  const setRoles = useSetRecoilState(roles)
  const usersUid = useRecoilValue(usersID)
  const firebaseuid = useRecoilValue(firebaseId)
  const resetDelayNotificationTime = useResetRecoilState(DelayNotificationTime)
  const resetAlertListNotificationTime = useResetRecoilState(AlertListNotification)
  const resetUnreadNotificationList = useResetRecoilState(unReadNotificationList)
  const resetShowNotification = useResetRecoilState(ShowNotification)
  const [deniedUser, setDeniedUser]= useState(false);
  const horizontalView = useMediaQuery(`(max-width:800px)`)
  // const signOut = () => {
  //   //sessionStorage.removeItem('userId','userDetails','app','userId')
  //   setUserId(null);
  //   setUserDetails(null);
  //   setApp(null);
  //   setUsersUid(null)
  //   setFirebaseId(null)
  //   setUserPermission(null)
  //   //setUserAccessId(null)
  //   setRefreshId(null)
  //   setTokenId(null)
  //   history.replace("/");
  // };
  const handleMenuClose = () => {
    setAnchorEl(null);
  }
  const changePasswordScreen = () => {
   // console.log("dsbh")
    history.push("/app/changePassword");
  }
  const handleAvatarMenuClick = (event) => {
    setAvatarAnchorEl(event.currentTarget);
  };
  const handleAvatarMenuClose = () => {
    setAvatarAnchorEl(null);
  }
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  }

  const HTMLClasses = useStylesHtml();
  const retryApiGet = (url, header) => {
    return API.get(url, assocPath(['headers', 'uid'], firebaseuid, header)).then((res) => {
      if (res?.headers?.userid === String(usersUid)) {
        return Promise.resolve(res)
      } else {
        throw { message: "Invalid Response" }
      }
    }).catch(function (error) {
      if ([401, 500, 502, 504, 703, 704].includes(error?.response?.status)) {
        return new Promise(resolve => {
          API.post("/userEndpoints/v1/renewIdToken", {
            "token": refreshId
          })
            .then((res) => {
              setTokenId(res.data.idToken)
              if (!res.data.idToken) {
                setExpiredMsg(true)
                if (expiredLogin) {
                  setUserId(null)
                  history.push("/")
                }
                throw error
              } else {
                resolve(API.get(url, {
                  ...header, headers: {
                    Authorization: res.data.idToken,
                    uid: firebaseuid
                  }
                }).then((res) => {
                  if (res?.headers?.userid === String(usersUid)) {
                    return Promise.resolve(res)
                  } else {
                    throw { message: "Invalid Response" }
                    //return Promise.reject(new Error({message:'invalid access'}))
                  }
                }))
              }
            })
            .catch((error) => { Promise.reject(error) })
        })
      } else {
        throw error
      }
    })
  }

  const retryApiPost = (url, data, header) => {
   // console.log('...res...', url, data, header)
    return API.post(url, { ...data, id: usersUid }, assocPath(['headers', 'uid'], firebaseuid, header)).then((res) => {
      if (res?.headers?.userid === String(usersUid)) {
        //console.log('...res...', res)
        return Promise.resolve(res)
      } else {
        throw { message: "Invalid Response" }
        //return Promise.reject(new Error({message:'invalid access'}))
      }
    }).catch(function (error) {
      console.log(error?.response?.status, error, "retrypost")
      if ([401, 500, 502, 504, 703, 704].includes(error?.response?.status)) {
        return new Promise(resolve => {
          API.post("/userEndpoints/v1/renewIdToken", {
            "token": refreshId
          })
            .then((res) => {
              setTokenId(res.data.idToken)
              if (!res.data.idToken) {
                setExpiredMsg(true)
                if (expiredLogin) {
                  setUserId(null)
                  history.push("/")
                }
                throw error
              } else {
                resolve(API.post(url, { ...data, id: usersUid }, {
                  headers: {
                    Authorization: res.data.idToken
                  }
                }).then((res) => {
                  if (res?.headers?.userid === String(usersUid)&&(res?.data?.id===Number(usersUid))) {
                    return Promise.resolve(res)
                  } else {
                    throw { message: "Invalid Response" }
                    //return Promise.reject(new Error({message:'invalid access'}))
                  }
                }))
              }
            })
            .catch((error) => { Promise.reject(error) })
        })
      } else {
        Promise.reject(error)
      }
    })
  }

  const signOut = () => {
    if ('caches' in window) {
      //console.log('=caches==', caches)
      caches.keys().then((names) => {
        //console.log('==names===', names)
        names.forEach(name => {
          caches.delete(name);
        })
      });
      window.location.reload(true);
      sessionStorage.clear();
      sessionStorage.clear();

    }
    //sessionStorage.removeItem('userId','userDetails','app','userId')
    
    retryApiPost("commonEndpoints/v1/logout",{
      "token":logoutUserToken,
      "id": usersUid
    },
    {
      headers: {
        'Authorization': tokenId
      }
    }).then((res)=>{
      setUserId(null);
      setUserDetails(null);
      setApp(null);
      setUsersUid(null)
      setFirebaseId(null)
      setUserPermission(null)
      //setUserAccessId(null)
      setRefreshId(null)
      setTokenId(null)
      resetDelayNotificationTime()
      resetAlertListNotificationTime()
      resetUnreadNotificationList()
      resetShowNotification()
      history.replace("/");
      setLogoutUserToken(null)
      // history.go(0);
    }).catch(()=>{

    })
  };

  // useEffect(()=>{
  //   const refreshAuthLogic = failedRequest =>
  //   API.post("/userEndpoints/v1/renewIdToken",{
  //     "token": refreshId
  //   })
  //   .then((res)=>{
  //     setTokenId(res.data.idToken)
  //     if(!res.data.idToken){
  //       setExpiredMsg(true)
  //       if(expiredLogin){
  //         setUserId(null)
  //         history.push("/")
  //         return Promise.reject();
  //       }
  //       return Promise.reject();
  //     }
  //     failedRequest.response.config.headers["Authorization"] = res.data.idToken;
  //   return Promise.resolve();
  //   })
  //   .catch((error) =>{
  //     console.log("refresh fail");
  //     return Promise.reject(error);
  //   })
  //   createAuthRefreshInterceptor(API, refreshAuthLogic,{statusCodes:[401,500,502,504,703,704]});
  // },[])

  useEffect(() => {
    if (!userId) {
      history.replace({ pathname: '/login' })
    }
  }, [userId])

  useEffect(() => {
    if (!open) {
      setAlertMSG('')
    }
  }, [open])

  useEffect(() => {
    //console.log(sessionStorage.getItem("app"),"check")
    const reloadUrl = sessionStorage.getItem("pagelocation");
    if (reloadUrl && reloadUrl === "/homepage") {
      app && setApp(null)
      sessionStorage.removeItem('app')
      setAppInternalState(null)
      setAdminAppAccess(false)
      setDashboardAppAccess(false)
    }
  }, [])

  // useEffect(() => {
  //   if (app === ADMIN_APP) {
  //     //history.replace({pathname:HOSPITAL_SCREEN})
  //   }
  //   else if (app === DASHBOARD_APP) {
  //     //history.replace({pathname:DASHBOARD_SCREEN})
  //   }
  // }, [app])

  const refreshToken = () => {
    API.post("/userEndpoints/v1/renewIdToken", {
      "token": refreshId
    })
      .then((res) => {
        setTokenId(res.data.idToken)
        if (!res.data.idToken) {
          setExpiredMsg(true)
          if (expiredLogin) {
            setUserId(null)
            history.push("/")
          }
        }
        if (homeCallBack && homeCallBack === 'Administration') {
          AdminNavHandler();
        } else if (homeCallBack && homeCallBack === 'Dashboard') {
          DashboardNavHandler();
        }
      })
      .catch((error) => { })
  }
  useEffect(() => {
    if (expiredLogin) {
      setUserId(null)
      history.push("/")
    }
  }, [expiredLogin])

  const AdminNavHandler = () => {
    setHomeCallBack("Administration")
    setOpen(false)
    setLoading(true)
    retryApiPost("/commonEndpoints/v1/userValidation", {
      page: 'Administration',
    }, {
      headers: {
        'Authorization': tokenId,
        'userId': usersUid,
      }
    })
      .then((res) => {
        console.log('res====',res)
        setLoading(false)
        const accessAdmin = res?.data?.status
       // console.log('accessAdmin====', accessAdmin)
        if (accessAdmin === true&&res?.data?.id===Number(usersUid)) {
          setApp(ADMIN_APP)
          setNotificationDelay(res.data.adminNotificationTime)
          setAppInternalState(ADMIN_APP)
          setAdminAppAccess(true)
          const groupByRoles = groupBy(({ rollName }) => rollName, res?.data?.permissionList)
          setRoles(Object.keys(groupByRoles))
          setPermissionPagesArray(res?.data?.permissionList)
          // const permissionList = pluck('name',res?.data?.userRole)
          // setPermissionArray(permissionList)
          // const rolePagesArray = res?.data?.userRole?.map(({name,permissions})=>(permissions?{[name]:pluck('name',permissions)}:{}))
          // const rolePagesObject = rolePagesArray?.reduce((acc,curVal)=>({...acc,...curVal}))
          // setPermissionPagesArray(rolePagesObject)
          // adminPages.some(({accessible,route})=>{
          //   if(accessible){
          //     history.replace({pathname:route})
          //     return false
          //   }return true
          // })

        } else {
          setOpen(true)
          setAlertMSG(res?.data?.errorMessage)
          console.log(res?.status, '...res')
        }

      })/* .then((res) => {
        console.log(res.data, '...res')
      }) */
      .catch((error) => {
        console.log(error, '....res')
        setLoading(false)
        if (error && (error.response && error.response.status === 703) || (error.response && error.response.status === 704) ||error.response?.status === 504||error.response?.status === 500||error.response?.status === 502) {
          //refreshToken();
        } 
        /*else if (error && error.response && error.response.status === 504) {
          //refreshToken();
        } else if (error && error.response && error.response.status === 500) {
          //refreshToken();
        } else if (error && error.response && error.response.status === 502) {
          //refreshToken();
        }*/
      })
  }
  const ReportsNavHandler = () => {
    retryApiPost("/commonEndpoints/v1/userValidation", {
      page: 'Reports',
    }, {
      headers: {
        'Authorization': tokenId,
        'userId': usersUid,
      }
    })
      .then((res) => {
        console.log('res====',res)

        setLoading(false)
        const accessDash = res.data.status
        if (accessDash === true&&res?.data?.id===Number(usersUid)) {
          setApp(REPORT_APP)
          setAppInternalState(REPORT_APP)
          setReportAppAccess(true)
          const groupByRoles = groupBy(({ rollName }) => rollName, res?.data?.permissionList)
          setRoles(Object.keys(groupByRoles))
          setPermissionPagesArray(res?.data?.permissionList)
          // history.replace({pathname:REPORT_SCREEN_HOSPITAL})
        } else {
          setOpen(true)
          setAlertMSG(res?.data?.errorMessage)
        }

      }).then((res) => { 
      })
      .catch((error) => {
        setLoading(false)
        if (error && (error.response && error.response.status === 703) || (error.response && error.response.status === 704)||(error && error.response && error.response.status === 504)||(error && error.response && error.response.status === 500)||(error && error.response && error.response.status === 502)) {
          //refreshToken();
        } 
        /*else if (error && error.response && error.response.status === 504) {
          //refreshToken();
        } else if (error && error.response && error.response.status === 500) {
          //refreshToken();
        } else if (error && error.response && error.response.status === 502) {
          //refreshToken();
        }*/
      })


  }

  const DashboardNavHandler = () => {
    setHomeCallBack("Dashboard")
    setOpen(false)
    setLoading(true)
    retryApiPost("/commonEndpoints/v1/userValidation", {
      page: 'Operational Dashboard',
    }, {
      headers: {
        'Authorization': tokenId,
        'userId': usersUid,
      }
    })
      .then((res) => {
        setLoading(false)
        const accessDash = res.data.status
        if (accessDash === true&&res?.data?.id===Number(usersUid)) {
          setApp(DASHBOARD_APP)
          //setApp(ADMIN_APP)
          setAppInternalState(DASHBOARD_APP)
          setDashboardAppAccess(true)
          const groupByRoles = groupBy(({ rollName }) => rollName, res?.data?.permissionList)
          setRoles(Object.keys(groupByRoles))
          setPermissionPagesArray(res?.data?.permissionList)
          // const permissionList = pluck('name',res?.data?.userRole)
          // setPermissionArray(permissionList)
          // const rolePagesArray = res?.data?.userRole?.map(({name,permissions})=>(permissions?{[name]:pluck('name',permissions)}:{}))
          // const rolePagesObject = rolePagesArray?.reduce((acc,curVal)=>({...acc,...curVal}))
          // setPermissionPagesArray(rolePagesObject)
          //history.replace({pathname:DASHBOARD_SCREEN})
          // dashboardPages.some(({accessible,route})=>{
          //   if(accessible){
          //     history.replace({pathname:route})
          //     return true
          //   }return false
          // })

        } else {
          setOpen(true)
          setAlertMSG(res?.data?.errorMessage)
        }

      }).then((res) => {
       })
      .catch((error) => {
        console.log(error, '...error')
        setLoading(false)
        if (error && (error.response && error.response.status === 703) || (error.response && error.response.status === 704) ||(error && error.response && error.response.status === 504)||(error && error.response && error.response.status === 500)||(error && error.response && error.response.status === 502)) {
          //refreshToken();
        } 
        /*else if (error && error.response && error.response.status === 504) {
          //refreshToken();
        } else if (error && error.response && error.response.status === 500) {
          //refreshToken();
        } else if (error && error.response && error.response.status === 502) {
          //refreshToken();
        }*/
      })

  }
  const changeTimelineScreen = () => {
    history.replace("/timeline");
  }

  const [sessionTitle,sessionMessage,sessionToken,resetState]= useHealthMonitorApiCall(sessionFirebaseToken,retryApiPost,tokenId)

  return (<><div style={{ minHeight: 'calc(100vh - 64px)', display: 'grid', gridTemplateRows: '1fr' }}>
    <AppBar><Toolbar className={HTMLClasses.toolBar}>
      <div style={{display:'flex'}}><OmnicureTextIcon /></div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <IconButton className={HTMLClasses.iconStyle} color="disabled" disabled>
          <HomeIcon />
        </IconButton>
        <IconButton aria-controls="simple-menu"
          className={HTMLClasses.iconStyle} onClick={changeTimelineScreen}>
            <TrendingIcon />
          </IconButton>
        <IconButton
          aria-controls="simple-menu"
          aria-haspopup="true"
          className={HTMLClasses.iconStyle}
          onClick={handleMenuClick}
        >
          <SettingIcon />
        </IconButton>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          style={{ margin: "55px 0 0 0" }}
          classes={{paper:HTMLClasses.menuContainer}}
        >
          <MenuItem className={HTMLClasses.menuList}>
            <a onClick={changePasswordScreen}>Change Password</a>
          </MenuItem>
        </Menu>
        <IconButton style={{position:'relative'}}>
        <Avatar classes={avatarStyles} className={avatarStyles.profileCircle} onClick={handleAvatarMenuClick}>{`${userDetails?.fname?.replace(/[\s'.-]/g, "")?.[0]}${userDetails?.lname?.replace(/[\s'.-]/g, "")?.[0]}`}</Avatar>
        </IconButton>
        <Menu
          id="simple-menu"
          anchorEl={avatoranchorEl}
          keepMounted
          open={Boolean(avatoranchorEl)}
          onClose={handleAvatarMenuClose}
          style={{ margin: "55px 0 0 0" }}
          classes={{paper:HTMLClasses.menuContainer}}
        >
          <MenuItem className={HTMLClasses.menuList}>
            <a onClick={signOut}>Log out</a>
          </MenuItem>
        </Menu>
      </div>
    </Toolbar>
    </AppBar>
    <div style={{ paddingTop: 64, display: 'flex', flexDirection: 'column' }}>
      {/* <div className={HTMLClasses.alertContainer}> */}
      <Collapse in={open}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="primary"
              size="small"
              onClick={() => {
                setOpen(false);
              }}
            >
              <CloseIcon fontSize="inherit" color="primary" />
            </IconButton>
          }
        >
          {alertMSG}
        </Alert>
      </Collapse>
      {/* </div> */}
      <div style={{flexGrow:1,display:'flex',justifyContent:'center',alignItems:'center'}}>
      <div style={{ width:horizontalView?308:862,height:'max-content',backgroundColor:'#EFF1F5',borderRadius:14,padding:20 }}>
      <Grid container alignItems="center" spacing={3}>
        <Grid item lg={4} md={4} sm={12} xs={12} container justify="center">
          <Card className={clsx({ [HTMLClasses.card]: true, /*[HTMLClasses.cardHover]: true*/ })}><CardActionArea className={HTMLClasses.cardActionArea} onClick={AdminNavHandler} id="administration"><div><AdminIcon /><div></div><div>Administration</div></div></CardActionArea>
          </Card>
        </Grid>
        <Grid item lg={4} md={4} sm={12} xs={12} container justify="center">
          <Card className={clsx({ [HTMLClasses.card]: true, /*[HTMLClasses.cardHover]: true*/ })}><CardActionArea className={HTMLClasses.cardActionArea} onClick={DashboardNavHandler} id="dashboard"><div><DashboardIcon /><div></div><div>Operational Dashboard</div></div></CardActionArea></Card>
        </Grid>
        <Grid item lg={4} md={4} sm={12} xs={12} container justify="center">
          <Card className={clsx({ [HTMLClasses.card]: true, /*[HTMLClasses.cardHover]: true*/ })}><CardActionArea className={HTMLClasses.cardActionArea} onClick={ReportsNavHandler} id="reports"><div><ReportsIcon /><div></div><div>Reports</div></div></CardActionArea></Card>
        </Grid>
        {/* <Grid item lg={3} md={3} sm={12} xs={12} container justify="center">
          <Card className={clsx({ [HTMLClasses.card]: true, [HTMLClasses.cardHover]: false })} onClick={() => { setOpen(true); setAlertMSG("You dont have permission to access this") }}><CardActionArea className={HTMLClasses.cardActionArea} disabled>Web Portal</CardActionArea></Card>
        </Grid> */}
      </Grid>
      </div>
    </div></div>
    {loading && <LoginLoader />}
    {expiredMsg && <div><ExpiredMessage expiredMsg={expiredMsg} setexpiredLogin={setexpiredLogin} /></div>}
  </div>        <SessionPopupComponent 
        sessionTitle={sessionTitle} 
        sessionMessage={sessionMessage} 
        sessionToken={sessionToken}
        setUserId={setUserId}
        setLogoutUserToken={setLogoutUserToken}
        logoutUserToken={ logoutUserToken}
        refreshId={refreshId}
        setTokenId={setTokenId}
        resetState={resetState}
        /></>)
}

export default NavigationComponent;