import axios from "axios";
import { detect } from 'detect-browser';
import { DecryptionComponent, EncryptionComponent } from "components/common/EncryptionAndDecryption";
import { AES256_GCM_ENCRYPT, AES256_GCM_decrypt } from 'components/common/EncryptionAndDecryption_3';
const { REACT_APP_API } = process.env;
const browser = detect();

const adminDataURls = [
  '/hospitalEndpoints/v1/addHospitalWithUserGroup',
  '/adminUserEndpoints/v1/updateHospital',
  '/adminUserEndpoints/v1/updateHospitalStatus',
  // '/adminUserEndpoints/v1/updateHospitalStatus',
  '/hospitalEndpoints/v1/updateHospitalSimulate',
  '/uploadEndpoints/v1/userUploadCSV',
  '/adminUserEndpoints/v1/submitPendingChanges',
  // '/adminUserEndpoints/v1/updateUserGroup',
  '/adminUserEndpoints/v1/submitApprovedChanges',
  '/adminUserEndpoints/v1/reactivateUser',
  '/uploadEndpoints/v1/upload/csv/patient',
  'adminUserEndpoints/v1/updatePatient',
  '/adminUserEndpoints/v1/mergePatient',
  '/downloadEndpoints/v1/patient/chat/history/download',
  // '/adminUserEndpoints/v1/UpdateAdminUser',
  '/adminUserEndpoints/v1/postAlert',
  '/adminUserEndpoints/v1/getSystemAlerts',
  '/adminUserEndpoints/v1/updateConfigurations',
  '/commonEndpoints/v1/resetPassword'
]
const userGroupApi = [
  '/adminUserEndpoints/v1/updateUserGroup',
  '/adminUserEndpoints/v1/UpdateAdminUser',
  '/userEndpoints/v1/registerUser',
  '/commonEndpoints/v1/adminLogin',
  'commonEndpoints/v1/logout'
]
const API = axios.create({
  baseURL: '/api', //REACT_APP_API,
});
/*API.interceptors.request.use(function (config) {
  const {data,method} = config;
  console.log(config.url,"Config",data)
  if(method==='post'&&data&&!(data.hasOwnProperty('token'))){
    return {...config,data:{encryptedValue:EncryptionComponent(JSON.stringify(data))}}
  }
  return config;
}, function (error) {
  return Promise.reject(error);
});

API.interceptors.response.use(function (response) {
  if(response?.data?.encryptedValue){
   console.log(response.config.url,DecryptionComponent({data:response.data}),"Response_Config")
  return {...response,data:DecryptionComponent({data:response.data})};
  }
  return response
}, function (error) {
  return Promise.reject(error);
});*/

API.interceptors.request.use(function (config) {
  return new Promise((resolve, reject) => {
    let { data, method } = config;
    if (adminDataURls.includes(config.url)) {
      const user = {
        user: {
          id: Number(sessionStorage.getItem('usersuid')),
          osType: "Website",
          browserName: browser.name,
          browserVersion: navigator.appVersion,
        }
      }
      data = Object.assign(data, user)
    }

    if (userGroupApi.includes(config.url)) {
      const user = {
        osType: "Website",
        browserName: browser.name,
        browserVersion: navigator.appVersion,
      }

      data = Object.assign(data, user)
    }
    if(!process.env.REACT_APP_PRODUCTION_BUILD){
      console.log(config.url, "Config", data);
    }
    if (method === 'post' && data && !(data.hasOwnProperty('token'))) {
      const EncryptPromise = AES256_GCM_ENCRYPT(JSON.stringify(data))
      EncryptPromise.then(encryptedValue => {
        const newConfig = { ...config, data: { encryptedValue } }
        resolve(newConfig)
      }).catch(error => {
        reject(error)
      })
    } else {
      resolve(config)
    }
  })
}, function (error) {
  return Promise.reject(error)
},{runWhen({url}){
  return url !== '/userEndpoints/v1/versioninforesponse/Website'
}})

API.interceptors.response.use(function (response) {
  return new Promise((resolve, reject) => {
    if (response?.data?.encryptedValue) {
      AES256_GCM_decrypt({ data: response.data }).then(decryptedValue => {
        if(!process.env.REACT_APP_PRODUCTION_BUILD){
        console.log(response.config.url, JSON.parse(decryptedValue), "Response_Config")
        }
        const newResponse = { ...response, data: JSON.parse(decryptedValue) };
        resolve(newResponse)
      }).catch(error => {
        // console.log(error,"InterceptorsError")
        reject(error)
      })
    }
    else {
      resolve(response)
    }
  })
}, function (error) {
  // console.log(error,"Interceptors_Error")
  return Promise.reject(error);
})

export default API;
// baseURL: `https://dev-omnicure.appspot.com/_ah/api`
//  baseURL: `https://omnicure-qa.appspot.com/_ah/api`
//baseURL: `https://omnicure-demo.appspot.com/_ah/api`
// baseURL: `https://omnicure-staging.appspot.com/_ah/api`
//  baseURL: `https://omnicurepilot.appspot.com/_ah/api`
// baseURL: `https://omnicure.appspot.com/_ah/api`
// baseURL: `https://exttesting.appspot.com/_ah/api`
