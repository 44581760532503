import React, { useState,useRef,useCallback,useEffect,useReducer} from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { AppBar, Toolbar, Collapse, IconButton } from '@material-ui/core';
import oc_logo from "../../assets/images/omnicure_logo.svg";
import { makeStyles } from "@material-ui/core/styles";
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import Menu from "@material-ui/core/Menu";
import TimelineIcon from '@material-ui/icons/Timeline';
import MenuItem from "@material-ui/core/MenuItem";
import Avatar from '@material-ui/core/Avatar';
import API from "../.././api";
import {useHistory} from "react-router-dom";
import {assocPath} from 'ramda';
import {useRecoilValue,useSetRecoilState,useRecoilState,useResetRecoilState} from 'recoil';
import {usersID,firebaseId} from 'App';
import {DelayNotificationTime,AlertListNotification,unReadNotificationList,ShowNotification,unreadNotificationCount} from 'App';
import CustomLoader from 'components/common/CustomLoader';
import CloseIcon from "@material-ui/icons/Close";
import Alert from "@material-ui/lab/Alert";
import {ReactComponent as OmnicureTextIcon} from 'assets/images/OmnicureTextIcon.svg';
import {ReactComponent as HomeIcon} from 'assets/images/Home.svg';
import {ReactComponent as TrendingIcon} from 'assets/images/trending_up.svg';
import {ReactComponent as SettingIcon} from 'assets/images/Setting.svg';
import onlineDot from 'assets/images/greenDot.svg';
const useAvatarStyles = makeStyles((theme) => ({
    root: {
        //marginRight: 10,
    },
    profileCircle: {
        color: "#fafafa",
        backgroundColor: "#B3BAC5",
        width: "40px",
        height: "40px",
        position:'relative',
        overflow:'visible',
        '&::after':{
          content:"''",
          height:12,
          width:12,
          background:`url(${onlineDot})`,
          position:'absolute',
          right:0,
          top:0
        }
    }

}))

const useStylesHtml = makeStyles((theme) => ({
    header: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: 60,
        background: '#fff'
    },
    iconStyle: {
        fontSize: "2rem",
        //marginRight: "10px",
        color: "rgba(0, 0, 0, 0.54)"
        // margin: '5px 0px 5px 5px'
    },
    menuList: {
        padding: "0px 8px !important",
        '&:hover':{
            background:'#EFF1F5',
            fontWeight:'bold'
        }
    },
    menuContainer:{
        border: '1px solid #5E6C84',
        boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.15)',
        borderRadius: 8,
        fontFamily:'Poppins'
    },
    card: {
        width: '80%',
        height: 188,
        transition: 'box-shadow .3s',
        fontSize: 18,
        [theme.breakpoints.down('md')]: {
            width: '60%',
            margin: 12
        },
        ['@media (min-height:800px)']: {
            height: '25vh',
            width: '80%'
        }
    },
    cardHover: {
        '&:hover': {
            boxShadow: '0 0 11px #9B51E0',
            fontWeight: 'bold'
        }
    },
    cardActionArea: {
        width: '100%',
        height: '100%',
        padding: 10,
        fontSize: 18,
        '&:hover': {
            fontWeight: 'bold'
        },
        '&:disabled': {
            backgroundColor: '#ebebeb',
            color: 'grey'
        }
    },
    alertContainer: {
        position: 'absolute',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        top: 80
    },
    toolBar: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingLeft:'40px !important',
        paddingRight:'40px !important',
        boxShadow: '0px 6px 3px rgba(0, 0, 0, 0.1)'
    },
    // menuList: {
    //     padding: "0px 8px !important",
    // },
    menuItem: {
        margin: "65px 0 0 0",
    },
}))
const useLoadStyles = makeStyles({
    Loading: {
        height: "100vh",
        justifyContent: "center",
        display: "flex",
        alignItems: "flex-start",
        position: "absolute",
        width: "100vw",
        backgroundColor:'#FFFFFF'
    },
});


const TimelineComponent =({
    userId,
    setUserId,
    app,
    setApp,
    setUserType,
    userType,
    setUserDetails,
    userPermission,
    setUserPermission,
    tokenId,
    setTokenId,
    refreshId,
    setRefreshId,
    adminPages,
    dashboardPages,
    setAppInternalState,
    setAdminAppAccess,
    setDashboardAppAccess,
    setReportAppAccess,
    setFirebaseId,
    setUsersUid,
    logoutUserToken,
    setLogoutUserToken
  }) =>{
    const [loading, setLoading] = useState(true)
    const avatarStyles = useAvatarStyles();
    const HTMLClasses = useStylesHtml();
    const Loadclasses = useLoadStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const [avatoranchorEl,setAvatarAnchorEl] = useState(null);
    const [expiredMsg, setExpiredMsg] = useState(false);
    const [expiredLogin, setexpiredLogin] = useState(false);
    const [arrayList,setArrayList] = useReducer(setValues,{configurationList:[]})
    const userDetailsJSON = sessionStorage.getItem('userDetails')
    const IframeRef = useRef(null)
    const userDetails = JSON.parse((Boolean(userDetailsJSON)||userDetailsJSON!=='null')?userDetailsJSON:{})
    const usersUid = useRecoilValue(usersID)
    const firebaseuid=useRecoilValue(firebaseId)
    const resetDelayNotificationTime = useResetRecoilState(DelayNotificationTime)
    const resetAlertListNotificationTime = useResetRecoilState(AlertListNotification)
    const resetUnreadNotificationList = useResetRecoilState(unReadNotificationList)
    const resetShowNotification = useResetRecoilState(ShowNotification)
    const [open, setOpen] = useState(false)
    const [alertMSG, setAlertMSG] = useState('');
    const usersuid = useRecoilValue(usersID);
    function setValues(state,action){
        return {configurationList:action}
      }
    const signOut = () => {
        API.post("commonEndpoints/v1/logout",{
            "token":logoutUserToken,
            "id": usersuid,
          },{
            headers:{
                Authorization:tokenId
            }
          }).then((res)=>{
            setUserId(null);
            setUserDetails(null);
            setApp(null);
            setUsersUid(null)
            setFirebaseId(null)
            setUserPermission(null)
            //setUserAccessId(null)
            setRefreshId(null)
            setTokenId(null)
            resetDelayNotificationTime()
            resetAlertListNotificationTime()
            resetUnreadNotificationList()
            resetShowNotification()
            setLogoutUserToken(null)
            history.replace("/");
          }).catch(()=>{})
        //localStorage.removeItem('userId','userDetails','app','userId')
      };
    const onLoad = () => {
        setLoading(false)
    }
    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    }
    const handleMenuClose = () => {
        setAnchorEl(null);
    }
    const history = useHistory();
    const homeNavigation = useCallback(() => {
        history.replace("/homepage")
    }, [])
    const changePasswordScreen = () => {
        //console.log("dsbh")
        history.push("/app/changePassword");
    }
    const changeTimelineScreen = () => {
        history.replace("/timeline");
    }
    const handleAvatarMenuClick = (event) => {
        setAvatarAnchorEl(event.currentTarget);
    };
    const handleAvatarMenuClose = () => {
        setAvatarAnchorEl(null);
    }
    const retryApiGet = (url,header)=>{
        return API.get(url,assocPath(['headers','uid'],firebaseuid,header)).then((res)=>{
          if(res?.headers?.userid===String(usersUid)){
            return Promise.resolve(res)
          }else{
            throw {message:"Invalid Response"}
          }}).catch(function(error){if([401,500,502,504,703,704].includes(error?.response?.status)){
          return new Promise(resolve=>{
            API.post("/userEndpoints/v1/renewIdToken",{
              "token": refreshId
            })
            .then((res)=>{
              setTokenId(res.data.idToken)
              if(!res.data.idToken){
                setExpiredMsg(true)
                if(expiredLogin){
                  setUserId(null)
                  history.push("/")
                }
                throw error
              }else{
                resolve(API.get(url,{...header,headers:{
                  Authorization:res.data.idToken,
                  uid:firebaseuid
                }}).then((res)=>{
                  if(res?.headers?.userid===String(usersUid)){
                    return Promise.resolve(res)
                  }else{
                    throw {message:"Invalid Response"}
                    //return Promise.reject(new Error({message:'invalid access'}))
                  }
                }))
              }
            })
            .catch((error) =>{Promise.reject(error)})
          })
        }else{
          throw error
        }})
      }

    useEffect(() => {
        //src="https://omnicure1.statuspage.io/"
        setOpen(false);
        retryApiGet("/adminUserEndpoints/v1/GetAllConfigurations",{
            headers: {
              'Authorization': tokenId
            }
          })
          .then((res) => {
            setArrayList(res.data.configurationList)
            //console.log(res.data.configurationList)
            const iframedata = res.data.configurationList.find(element => element.name === "statusPage");
            IframeRef.current.src=iframedata.value
        }).catch((error)=>{
            if(error.response.status === 403){
                setOpen(true)
                setAlertMSG("User doesn't have permission.")
                setLoading(false)
            }
            console.log('error.,..', error)
            console.log('error.,..', error.response.status, error.response)
        })
    },[])
   
    return (

        <>
            <AppBar><Toolbar className={HTMLClasses.toolBar}>
            <div style={{display:'flex'}}><OmnicureTextIcon /></div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <IconButton className={HTMLClasses.iconStyle} onClick={homeNavigation}><HomeIcon /></IconButton>
                    <IconButton className={HTMLClasses.iconStyle} onClick={changeTimelineScreen}><TrendingIcon /></IconButton>
                    <IconButton
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        className={HTMLClasses.iconStyle}
                        onClick={handleMenuClick}
                    ><SettingIcon /></IconButton>
                    <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleMenuClose}
                        style={{ margin: "55px 0 0 0" }}
                        classes={{paper:HTMLClasses.menuContainer}}
                    >
                        <MenuItem className={HTMLClasses.menuList}>
                            <a onClick={changePasswordScreen}>Change Password</a>
                        </MenuItem>
                    </Menu>
                    <IconButton><Avatar classes={avatarStyles} className={avatarStyles.profileCircle} onClick={handleAvatarMenuClick}>{`${userDetails?.fname?.replace(/[\s'.-]/g, "")?.[0]}${userDetails?.lname?.replace(/[\s'.-]/g, "")?.[0]}`}</Avatar></IconButton>
                    <Menu
                        id="simple-menu"
                        anchorEl={avatoranchorEl}
                        keepMounted
                        open={Boolean(avatoranchorEl)}
                        onClose={handleAvatarMenuClose}
                        style={{ margin: "55px 0 0 0" }}
                        classes={{paper:HTMLClasses.menuContainer}}
                    >
                        <MenuItem className={HTMLClasses.menuList}>
                            <a onClick={signOut}>Log out</a>
                        </MenuItem>
                    </Menu>

                </div>
            </Toolbar>
            </AppBar>
            <div style={{position:'relative',marginTop:64,paddingTop:5}}>{loading && <div className={Loadclasses.Loading}><CustomLoader/> </div>}</div>
            <Collapse in={open}>
                <Alert
                action={
                    <IconButton
                    aria-label="close"
                    color="primary"
                    size="small"
                    onClick={() => {
                        setOpen(false);
                        history.push("/homepage")
                    }}
                    >
                    <CloseIcon fontSize="inherit" color="primary" />
                    </IconButton>
                }
                >
                {alertMSG}
                </Alert>
            </Collapse>
            <iframe ref={IframeRef} onLoad={onLoad} style={{ height: "100vh" }}></iframe>
        </>
    );
};

export default TimelineComponent;


