import axios from "axios";
import {memoize} from 'lodash';

const AESEncryptionKey = memoize(()=>axios
.get("/api/userEndpoints/v1/versioninforesponse/Website")
.then(({ data: { aesKey } }) => {
  return aesKey;
}))

var iv = crypto.getRandomValues(new Uint8Array(12));
//console.log(iv);
var secretKey;

/*window.crypto.subtle.generateKey({
            name: "AES-GCM",
            length: 256, //can be  128, 192, or 256
        },
        false, //whether the key is extractable (i.e. can be used in exportKey)
        ["encrypt", "decrypt"] //can "encrypt", "decrypt" or  "wrapKey", or "unwrapKey"
    ).then(function(key) {
        secretKey = key;
    }).catch(function(err) {
        console.error(err);
    });
  */
//const msgUint8 = new TextEncoder().encode("Wk+Uzyyn8991w/2V5OIqiQ==");

const secretKeyGeneration = AESEncryptionKey().then(aesKey=>new TextEncoder().encode(aesKey)).then(msgUint8=>{
    return window.crypto.subtle.digest('SHA-256', msgUint8).then(value => {
        return window.crypto.subtle.importKey('raw', value, 'AES-GCM', false, ["encrypt", "decrypt"]).then(function (key) {
            return secretKey = key;
        }).catch(function (err) {
            console.error(err);
        });
    })
})


export function AES256_GCM_ENCRYPT(params) {

    if (secretKey === null||secretKey ===undefined) {
        return secretKeyGeneration.then(secretKey=>crypto.subtle.encrypt({
                name: "aes-gcm",
                iv: iv,
                tagLength: 128 //can be 32, 64, 96, 104, 112, 120 or 128 (default)
            }, secretKey, asciiToUint8Array(params))).then(function (cipherText) {
                const finalValue = _appendBuffer(iv, cipherText);
                const Base64String = arrayBufferToBase64(finalValue);
                return Promise.resolve(Base64String)
            });
    }
    return crypto.subtle.encrypt({
        name: "aes-gcm",
        iv: iv,
        tagLength: 128 //can be 32, 64, 96, 104, 112, 120 or 128 (default)
    }, secretKey, asciiToUint8Array(params)).then(function (cipherText) {
        const finalValue = _appendBuffer(iv, cipherText)
        return arrayBufferToBase64(finalValue);
    }, failAndLog);

}

export function AES256_GCM_decrypt({ data: { encryptedValue } }) {

    if (secretKey == null) {
        //alert("Failed to Generate AES Keys Check The browser Comptabilty ")
        return;
    }

    const cipherText = encryptedValue;
    const cipherTextIV = cipherText.substring(0, 16);
    const cipherTextValue = cipherText.substring(16);
    const arrayBuffercipherTextIV = base64ToArrayBuffer(cipherTextIV)
    const arrayBuffercipherTextValue = base64ToArrayBuffer(cipherTextValue)
    return crypto.subtle.decrypt({
        name: "aes-gcm",
        iv: arrayBuffercipherTextIV,
        tagLength: 128 //can be 32, 64, 96, 104, 112, 120 or 128 (default)
    },
        secretKey,
        arrayBuffercipherTextValue
    ).then(
        function (plainText) {
            return bytesToASCIIString(plainText);
        },
        function (result) {
            return result;
        });
}

function bytesToHexString(bytes) {
    if (!bytes)
        return null;

    bytes = new Uint8Array(bytes);
    var hexBytes = [];

    for (var i = 0; i < bytes.length; ++i) {
        var byteString = bytes[i].toString(16);
        if (byteString.length < 2)
            byteString = "0" + byteString;
        hexBytes.push(byteString);
    }

    return hexBytes.join("");
}


function bytesToASCIIString(bytes) {
    return new TextDecoder().decode(new Uint8Array(bytes));
}

function hexStringToUint8Array(hexString) {
    if (hexString.length % 2 != 0)
        throw "Invalid hexString";
    var arrayBuffer = new Uint8Array(hexString.length / 2);

    for (var i = 0; i < hexString.length; i += 2) {
        var byteValue = parseInt(hexString.substr(i, 2), 16);
        if (Number.isNaN(byteValue))
            throw "Invalid hexString";
        arrayBuffer[i / 2] = byteValue;
    }

    return arrayBuffer;
}

function failAndLog(error) {
    console.log(error);
}

function asciiToUint8Array(str) {
    var chars = [];
    for (var i = 0; i < str.length; ++i)
        chars.push(str.charCodeAt(i));
    return new Uint8Array(chars);
}

function arrayBufferToBase64(buffer) {
    var binary = '';
    var bytes = new Uint8Array(buffer);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
}

function base64ToArrayBuffer(base64) {
    var binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
}

function _appendBuffer(buffer1, buffer2) {
    var tmp = new Uint8Array(buffer1.byteLength + buffer2.byteLength);
    tmp.set(new Uint8Array(buffer1), 0);
    tmp.set(new Uint8Array(buffer2), buffer1.byteLength);
    return tmp.buffer;
}


