import React from "react";
import {
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from "react-router-dom";

export default (props) => {
  const locationObject = useLocation();
  const historyObject = useHistory();
  const paramsObject = useParams();
  const routeObject = useRouteMatch();
  return <div />;
};
