import moment from 'moment';
import {not,or} from 'ramda';

export const randHex = function (len) {
  const crypto = window.crypto || window.msCrypto;
  const array = new Uint8Array(1);
  crypto.getRandomValues(array);
  const highestNum=Math.pow(2,8)-1;
  const randomNum=array[0]/highestNum;
  const maxlen = 8;
  const min = Math.pow(16, Math.min(len, maxlen) - 1);
  const max = Math.pow(16, Math.min(len, maxlen)) - 1;
  const n = Math.floor(randomNum * (max - min + 1)) + min;
  let r = n.toString(16);
  while (r.length < len) {
    r = r + randHex(len - maxlen);
  }
  return r;
};

export const durationCalculator = (serverTime,time) => {
  const durationObject = moment.duration(moment(serverTime).diff(moment(time)));
  const [consultDays,consultHours,consultMinutes] = [Math.floor(durationObject.as('days')),durationObject.get('hours'),durationObject.get('minutes')]
  const displayText = [consultDays,consultHours,consultMinutes].map((val,index)=>{
    if(val){
    if(index===0){
      return `${val} day${val>1?`s`:``}`
    }
    else if(index===1){
      return `${val} hour${val>1?`s`:``}`
    }
    else if(index===2){
      if(!consultDays){
      return `${val} minute${val>1?`s`:``}`
      }
      else return null
    }
  }else if(not(or(consultDays,consultHours))&&index===2){
    return '0 seconds'
  }
  return null
}).filter(Boolean).join(" ")
return displayText
}