import React, { useEffect, useState } from "react";
import {
  useHistory,
  useLocation,
  Link as NavigationLink,
} from "react-router-dom";
import {
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  Link,
  InputAdornment,
  Fade,
  Popper,
  Tabs,
  Tab,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  OutlinedInput,
  CircularProgress,
} from "@material-ui/core";
import API from "../../../api";
import {
  ACTIVE_CONSULT_SCREEN,
  ADMIN_APP,
  ADMIN_USER_SCREEN,
  APPROVAL_SCREEN,
  DASHBOARD_APP,
  DASHBOARD_SCREEN,
  HOSPITAL_SCREEN,
  LOGIN_SCREEN,
  PATIENT_SCREEN,
  PENDING_CONSULT_SCREEN,
  REPORT_SCREEN,
  STAFFING_SCREEN,
  SYSTEM_ALERT,
  WARDS_SCREEN,
} from "../../../AppConstants";
import Alert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Collapse from "@material-ui/core/Collapse";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import "./LoginScreen.css";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles, makeStyles, styled } from "@material-ui/core/styles";
//import  firebase from "firebase/app";
import "firebase/auth";
import "firebase/analytics"
import "firebase/firestore"
import axios from "axios"
import "firebase/functions";
import {Firebase} from "App";
import ExpiredMessage from "../../common/ExpiredMessage";
import LoginLoader from "../../common/LoginLoader";
import {permission,usersID,firebaseId,roles} from "App";
import {useSetRecoilState,useRecoilState} from "recoil";
import logo from "../../../../src/assets/images/Logo.png";
import InputLabel from '@material-ui/core/InputLabel';
import InputBase from '@material-ui/core/InputBase';
import { fontFamily } from "@material-ui/system";
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InfoIcon from '@material-ui/icons/Info';
import CheckIcon from '@material-ui/icons/Check';
import Typography from '@material-ui/core/Typography';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {uniq} from 'ramda';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
//import Colors  from "../../dashboard/color";
import CheckCircle from "@material-ui/icons/CheckCircle";
import {AES256_GCM_ENCRYPT} from "components/common/EncryptionAndDecryption_3";
import Password from "components/common/Password";
import EmailValidator from 'email-validator';
import {PassValidation,Lowercase,Uppercase} from "../../common/PasswordValidation";
import clsx from 'clsx';

//import Password from "components/common/Password";
/*  const firebaseConfig = {
   apiKey: "AIzaSyB0ciCKAxKu37vGUbl6gBNSb0fC5Ff44a0",
   authDomain: "dev-omnicure.firebaseapp.com",
   databaseURL: "https://dev-omnicure.firebaseio.com",
   projectId: "dev-omnicure",
   storageBucket: "dev-omnicure.appspot.com",
   messagingSenderId: "462063129427",
   appId: "1:462063129427:web:d1fb129ea379d30441c7a6",
   measurementId: "G-7CMWQ1D0HX"
 }; */
  // Initialize Firebase
// firebase.initializeApp(firebaseConfig);
// firebase.analytics();

const useStyles = makeStyles({
  root:{
    paddingRight: '0px !important',
    borderRadius: '8px !important'
  }
});
const theme = createMuiTheme({
    MuiTextField:{
      root:{
        border: '1px solid #5E6C84',
        borderRadius: '10px'
      }
    },
   /*  MuiTypography:{
      colorPrimary:{
        color: '#344563'
      }
    }, */
    MuiIconButton:{
      root:{
        color:'#687587'
      }
    },
    MuiTypography: {
      body1: {
        fontSize: '12px',
        color: '#172B4D',
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: '500',
      }
    },
    MuiLink:{
      root:{
        color:'#344563',
        /* textDecoration: Colors.lightBlue */
      },
      underlineHover:{
       /*  textDecoration: Colors.lightBlue */
      }
    }
})
const useDefaultButtonStyles = makeStyles({
  defaultButtonStyles: {
    height: 32,
    border: '2px solid #9B51E0',
    borderRadius: '8px',
    backgroundColor: '#FFF',
    color: '#9B51E0',
    boxShadow: 'none',
    fontSize: 14,
    fontWeight: 600
  },
  defaultButtonStylesHover: {
    height: 32,
    border: '2px solid #9B51E0',
    borderRadius: '8px',
    backgroundColor: '#FFF',
    color: '#9B51E0',
    boxShadow: 'none',
    fontSize: 14,
    fontWeight: 600,
    '&:hover': {
      backgroundColor: '#9B51E0',
      color: '#FFF',
      border: '2px solid #9B51E0',
    }
  },
  invertedButtonStyles: {
    height: 40,
    border: '2px solid #9B51E0',
    borderRadius: '8px',
    backgroundColor: '#FFF',
    color: '#9B51E0',
    boxShadow: 'none',
    fontSize: 14,
    fontWeight: 600,
  },
  invertedClass: {
    backgroundColor: '#9B51E0',
    color: '#FFF',
  },
  disabledButtonStyles: {
    border: '2px solid #8993A4 !important',
    backgroundColor: '#EFF1F5 !important',
    color: '#5E6C84 !important'
  },
  largerButton: {
    height: 40
  },
  widthBasis: {
    flexBasis: 191
  }
})
const approvalListtheme = createMuiTheme({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: "#9B51E0",
    },
    secondary: {
      // This is green.A700 as hex.
      main: "#172B4D",
    },
    success: {
      // This is green.A700 as hex.
      main: "#cb114c",
    },
  },
  typography: {
    fontFamily: 'Poppins'
  },
  overrides: {
    MuiMenu: {
      paper: {
        border: '1px solid #5E6C84',
        borderRadius: 8
      }
    },
    MuiDialog: {
      paper: {
        minWidth: '42vw',
        borderRadius: 10
      }
    },
    MuiDialogTitle: {
      root: {
        fontWeight: 700,
        fontSize: 18,
        lineHeight: '24px',
        borderBottom: '1px solid #EBECF0'
      }
    },
    MuiSelect: {
      icon: {
        top: 'initial',
        right: '10px'
      }
    },
    MuiDialogContentText: {
      root: {
        fontSize: 14,
        fontWeight: 400,
        lineHeight: '20px'
      }
    },
  }
})
const DialogButtonContainer = styled('div')({
  columnGap: '10px',
  display: 'grid',
  justifyContent: 'end',
  gridTemplateColumns: '100px 100px'
})
const DialogContainer = styled('div')({
  height: 150,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  padding: "0px 24px"
})
const ModalCaptionText = styled(Typography)({
  font: '400 14px/143% Poppins'
})

function LoginScreen({
  alert,
  userId,
  setUserId,
  app,
  setApp,
  setUserType,
  userType,
  setUserDetails,
  tokenId,
  setTokenId,
  refreshId,
  setRefreshId,
  /* setUserEmail,
  setUserPassword */
  userPermission,
  setUserPermission,
  /* setUserAccessId, */
  setFirebaseId,
  setUserSessionEmail,
  userSessionEmail,
  setLoginFooter,
  setProviderUserLogin,
  setProviderUserId,
  setProviderUserToken,
  setProviderUserEmail,
  setProviderUserPassword,
  setAcceptedProvider,
  setLogoutUserToken,
  setLoginUserEmail,
  setAuthToken,
  setPasswordExpired,
  setPasswordExpiredToken
}) {
  const history = useHistory();
  const location = useLocation();
  const [email, setEmail] = useState("");
  const [appName, setAppName] = useState("Select a function");
  const [password, setPassword] = useState("");
  const [loginAlert, setLoginAlert] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [firebaseToken, setFirebaseToken] = useState("")
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const [open, setOpen] = useState(true);
  const mobileScreen = useMediaQuery('(max-width:636px)');
  const [expiredMsg, setExpiredMsg] = useState(false);
  const [passwordExpiredMsg, setPasswordExpiredMsg] = useState(false);
  const [expiredLogin, setexpiredLogin] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMsgEmail, setErrorMsgEmail] = useState("");
  const [errorMsgPassword, setErrorMsgPassword] = useState("");
  const [errorEmail, setErrorEmail] = useState(false);
  const [errorPassword, setErrorPassword] = useState(false);
  const setRoles = useSetRecoilState(roles)
  const setUsersID = useSetRecoilState(usersID)
  const [emailValidation, setEmailValidation] = useState(false);
  const [passwordValidation, setPasswordValidation] = useState(false);
  const [lowerCase, setLowerCase] = useState(false)
  const [number, setNumber] = useState(false)
  const [upperCase, setUpperCase] = useState(false)
  const [specialChar, setSpecialChar] = useState(false)
  const [minimumChar, setMinimumChar] = useState(false)
  const [tooltip, setTooltip] = useState(false);
  const [rememberMe, setRememberMe] =  useState(false)
  const { from } = location.state || { from: null };
  const [firebase] = useRecoilState(Firebase)
  const setFirebaseUId = useSetRecoilState(firebaseId)
  const [passwordInfo, setPasswordInfo] = useState(false)
  const [buttonStatus, setButtonStatus] = useState(true)
  const [enableInfo, setEnableInfo] = useState(false)
  const [emailTicEnableDisable, setEmailTicEnableDisable] = useState(true);
  const [passTicEnableDisable, setPassTicEnableDisable] = useState(true);
  const { invertedButtonStyles,invertedClass } = useDefaultButtonStyles()
  const classes = useStyles();
  const [overridePopup, setOverridePopup] = useState(false);
  const [overrideMessage, setOverrideMessage] = useState("");
  const [override, setOverride] = useState(false);
  const [expiredStatus, setExpiredStatus] = useState(false);
  const ifPathPartOfApp = (path, app) => {
    if (!path) return false;
    let result;
    switch (app) {
      case ADMIN_APP:
        result =
          userType === "T"
            ? [PATIENT_SCREEN].includes(path)
            : [
                HOSPITAL_SCREEN,
                ADMIN_USER_SCREEN,
                APPROVAL_SCREEN,
                PATIENT_SCREEN,
                SYSTEM_ALERT,
              ].includes(path);
        break;
      case DASHBOARD_APP:
        result = [
          DASHBOARD_SCREEN,
          ACTIVE_CONSULT_SCREEN,
          REPORT_SCREEN,
          WARDS_SCREEN,
          PENDING_CONSULT_SCREEN,
          STAFFING_SCREEN,
        ].includes(path);
        break;
      default:
        result = false;
    }
    return result;
  };
  const fromLocation = ifPathPartOfApp(from, app)
    ? from
    : {
        pathname:
          app === ADMIN_APP
            ? userType === "T"
              ? PATIENT_SCREEN
              : HOSPITAL_SCREEN
            : DASHBOARD_SCREEN,
      };
  const handleTooltipOpen = () => {
    setTooltip(true);
  };
  const handleTooltipClose = () => {
    setTooltip(false);
  };
  const LightTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }))(Tooltip);
  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: '#ffffff',
      color: 'rgba(0, 0, 0, 0.87)',
     /*  maxWidth: 250, */
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
      minWidth: 200
    },
  }))(Tooltip);

  // useEffect(()=>{
  //   sessionStorage.clear()
  // },[])

  useEffect(()=>{
    setLoginFooter(true)
  },[])
  useEffect(() => {
    // if (!ifPathPartOfApp(fromLocation.pathname, app)) {
    //   setUserId(null);
    // } else {
      if (userId != null) {
        history.replace({pathname:'/homepage'});
      }
    // }
  }, [userId, app]);
  const handleDialogClose = () => {
    setOverridePopup(false)
    setOverride(false)
    setLoginAlert('')
  }
  const refreshToken = () => {
    API.post("/userEndpoints/v1/renewIdToken",{
      "token": refreshId
    })
    .then((res)=>{
      setTokenId(res.data.idToken)
      if(!res.data.idToken){
        setExpiredMsg(true)
        if(expiredLogin){
          setUserId(null)
          history.push("/")
        }
      }
    })
    .catch((error) =>{})
  }
  const login = () => {
    setLoading(true);
    setOverrideMessage('');
    let token = ''
    const userEmail = email.toLowerCase();
    setLoginAlert('')
    if(email === ''){
      setErrorMsgEmail(<span style={{fontSize:"12px", color:"red"}}>Enter Valid Email</span>)
      setErrorEmail(true)
    }else{
      setErrorMsgEmail("")
      setErrorEmail(false)
    }
    if(password === ''){
      setErrorMsgPassword(<span style={{fontSize:"12px", color:"red", margin:"0px"}}>Enter Valid Pasword</span>)
      setErrorPassword(false)
    }else{
      setErrorMsgPassword("")
      setErrorPassword(true)
    }
    if(rememberMe){
      setUserSessionEmail((oldValues)=> uniq([...oldValues,userEmail]))
    }
    //const pass = "/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/"
    if(EmailValidator.validate(email)){
      setErrorMsgEmail('')
      setErrorEmail(false)
      if(PassValidation(password)){
        setErrorMsgPassword('')
        setErrorPassword(false)
        let param = {
          email: userEmail,
          password: password
        }
        firebase.auth().signInWithEmailAndPassword(userEmail, password)
          .then(async (res) => {
            const firebaseUID = res?.user?.uid
            const EncryptedRefreshToken = await AES256_GCM_ENCRYPT(res.user.refreshToken)
            setRefreshId(EncryptedRefreshToken)
            firebase.auth().currentUser.getIdToken( true).then(async function(idToken) {
              // Send token to your backend via HTTPS
              // ...
              const EncryptedToken = await AES256_GCM_ENCRYPT(idToken)
              setTokenId(EncryptedToken)
              setAuthToken(EncryptedToken)
              setFirebaseToken(idToken)

              const params = {
                email: userEmail,
                password: password
              }
             // EncryptionComponent(JSON.stringify(params))
              //console.log( EncryptionComponent(JSON.stringify(params)))
              token= idToken;
              if(idToken){
                  API.post("/commonEndpoints/v1/adminLogin", {
                    ...params,
                    "isOverride": override
                      // send the app name too
                  },
                  {
                    headers: {
                      'Authorization': EncryptedToken,
                      uid:firebaseUID
                    },
                  })
                  .then((res,...rest) => {
                    if (res.data.status === true) {
                      setLoading(false);
                      res.data.adminLoginUser &&
                      res.data.adminLoginUser.userType &&
                      setUserType(res.data.adminLoginUser.userType);
                      const { fname, lname, firebaseUid } = res.data.adminLoginUser;
                      setUserId(email);
                      setFirebaseId(firebaseUid);
                      setUserDetails({ fname, lname });
                      res.data.adminLoginUser.roles && setUserPermission("active");
                      //setUserAccessId(res.data.user.id)
                      //setPermission(res.data.user.roles.map(({name})=>name))
                      //CHECK THIS COMMENT BEFORE DELETING ROLES
                      //setRoles(res.data.user.roles.map(({name})=>name))
                      setUsersID(res?.data?.adminLoginUser?.id)
                      setFirebaseUId(res?.data?.adminLoginUser?.firebaseUid)
                      setLoginFooter(false)
                      setLogoutUserToken(res?.data?.adminLoginUser?.token)
                      setLoginUserEmail(email)
                    } else {
                      setLoading(false);
                      setOpen(true);
                      setLoginAlert(res.data.errorMessage);
                      if(res.data.errorMessage === 'User already logged in.'){
                        setOverrideMessage(res.data.errorMessage);
                      }
                    }
                    if(res.data.errorId === 107){
                      setProviderUserLogin(true)
                      setAcceptedProvider(false)
                      history.push("/app/termsandconditions")
                      setProviderUserId(res.data.userId)
                      setProviderUserToken(res.data.token)
                      setProviderUserEmail(res.data.email)
                      setProviderUserPassword(password)
                    }else if(res.data.errorId === 108){
                      setAcceptedProvider(true)
                      history.push("/app/providerpassword")
                      setProviderUserLogin(true)
                      setProviderUserId(res.data.userId)
                      setProviderUserToken(res.data.token)
                      setProviderUserEmail(res.data.email)
                      setProviderUserPassword(password)
                    }
                    if(res.data.errorId === 110){
                      setExpiredStatus(true)
                      setOpen(false)
                      setLoginAlert('');
                      setPasswordExpiredToken(res.data.token)
                      setLoginUserEmail(email)
                    }
                  })
                  .catch( (error)=> {
                    setLoading(false);
                    if ((error.response && error.response.status === 703) ||(error.response && error.response.status === 704)) {
                      refreshToken();
                    } else if(error.response && error.response.status === 504){
                      refreshToken();
                      setLoginAlert('Server did not response please try again...')
                    } else if(error.response && error.response.status === 500){
                      refreshToken();
                      setLoginAlert('')
                    } else if(error.response && error.response.status === 502){
                      refreshToken();
                    }
                  });
                }
            }).catch((error) => {
              setLoading(false);
              // Handle error
            });
          })
          .catch((error)=> {
            if(error.code === 'auth/user-disabled'){
              API.post("userEndpoints/v1/adminLoginFailed", {
                ...param
              }).then((res)=>{
                setLoginAlert(res.data.errorMessage);
                setOpen(true)
              }).catch(()=>{})
            }
            setLoading(false);
            if (error && error.code === 'auth/user-not-found'||error.code === 'auth/wrong-password'||error.code === 'auth/too-many-requests') {
              setLoginAlert("Please enter valid email or password");
              setOpen(true)
            }
            //  else if(error && error.code === 'auth/wrong-password'){
            //   setLoginAlert("Please enter valid email or password");
            //   setOpen(true)
            // } 
            // else if(error && error.code === 'auth/too-many-requests'){
            //   setLoginAlert("Please enter valid email or password");
            //   setOpen(true)
            // }
          });
        } else{
          setLoading(false);
          /* setErrorMsgPassword(<span style={{fontSize:"10px", color:"red"}}>Minimum eight characters, at least one uppercase letter, <br/> one lowercase letter, one number and one special character</span>) */
        }
      } else{
        setLoading(false);
        setErrorMsgEmail(<span style={{fontSize:"12px", color:"red"}}>Enter Valid Email</span>)
        setErrorEmail(true)
      }
  };
  useEffect(()=>{
    if(override){
      login()
    }
  },[override])
  useEffect(()=>{
    if(overrideMessage !==''){
      setOverridePopup(true)
    }
  },[overrideMessage])
  const keyPress=(event)=>{
    const k = event ? event.which : window.event.keyCode;
    if(k===13){
      login()
    }
  }
  const forgetPassword = () =>{
    history.push("/app/forgotpassword")
  }
  const rememberCheck = (event) =>{
    if(event.target.checked){
      setRememberMe(true)
    } else{
      setRememberMe(false)
    }
  }
  const handleClose = () => {
    setExpiredStatus(false)
    setPasswordExpired(true)
    history.push("/app/forgotpassword")
  };
  return (
    <div style={{display:"grid"}}>
      {loginAlert !== "" && loginAlert !== 'User already logged in.' && (
        <div
          style={{ fontSize: "18px", color: "#fb1b1b", padding: "0 0 10px" }}
        >
          <Collapse in={open}>
            <Alert
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {loginAlert}
            </Alert>
          </Collapse>
        </div>
      )}
      <div
        style={{
          height: "auto",
          width: "100%",
          display: "initial",
          margin: "auto",
          alignItems: "center",
        }}
      >
        <div style={{padding:"0px 0 0 0"}}>
          <div 
            style={{display:"initial",
            flexDirection:"column",
            justifyContent:"center",
            alignItems:"center",
            padding:"0px"
          }}>
            <img src={logo} />
          </div>
          <div 
          style={{fontFamily:"Poppins",
          fontStyle:"normal",
          fontWeight:"normal",
          fontSize:"14px",
          lineHeight:"20px",
          width:"490px",
          margin:"auto",
          padding:"0px 0 30px 0"
          }}>
            <p>Welcome to Omnicure Command Center</p>
          </div>
        </div>
        <div
          style={{
            margin: "auto",
            backgroundColor: "white",
            /* justifyContent: "space-evenly", */
            display: "flex",
            flexDirection: "column",
            borderRadius:"10px",
            ...mobileScreen?{
              width:'90%',
              height:'70vh',
              padding:'0 15px'
            }:{
              /* padding: "24px", */
              width: "365px",
              minHeight: "420px"
            }
          }}
        >
          <h6
            style={{
             /*  marginBottom: "20px", */
              color: "grey",
              fontSize: "14px",
              fontFamily:"Poppins",
              fontStyle:"normal",
              fontWeight:"600",
              lineHeight:"20px",
              padding: "24px 24px 0 24px"
              /* borderBottom:"1px solid #EFF1F5" */
            }}
          >
            Login
            {/* Welcome to Omnicure Command Center */}
          </h6>
          <div style={{ borderBottom:"1px solid #EFF1F5", height:"0px", padding:"13px 0 0 0"}}></div>
          <ThemeProvider theme={theme}>
          <div style={{display:'grid',gridTemplateRows:'auto auto',rowGap:10, padding: "24px 24px 24px 24px"}}>
            <span style={{
              textAlign:"initial",
              fontFamily:"Poppins",
              fontStyle:"normal",
              fontWeight:"normal",
              fontSize:"12px",
              lineHeight:"16px"
              }}>
              Email address
            </span>
              <TextField
                placeholder="Email"
                size="small"
                margin="dense"
                error={errorEmail}
                helperText={errorMsgEmail}
                style={{borderRadius:"7px", height:"40px"}}
                value={email}
                onChange={(event) => {
                  setEmail(event.target.value)
                  setEmailValidation(false)
                  const value = event.target.value;
                  if(EmailValidator.validate(event.target.value)){
                    setEmailValidation(true)
                    setErrorEmail(false)
                    setErrorMsgEmail('')
                    setEmailTicEnableDisable(true)
                  }else{
                    setEmailTicEnableDisable(false)
                  }
                }}
                onBlur={()=>{
                  if(!emailValidation){
                    setErrorMsgEmail(<span style={{fontSize:"12px", color:"red"}}>Enter Valid Email</span>);
                    setButtonStatus(true)
                    setErrorEmail(true)
                  } else{
                    setErrorMsgEmail('')
                    setErrorEmail(false)
                  }
                }}
                variant="outlined"
                /* helperText={errorMsgEmail} */
                InputProps={{
                  classes:classes,
                  // <-- This is where the toggle button is added.
                  endAdornment: (
                    <InputAdornment position="end" style={{borderRadius:"12px !important"}}>
                      <IconButton>{emailValidation && email !=='' && <CheckIcon color="primary" />}</IconButton>
                    </InputAdornment>
                  ),
                }}
              /> 
            <span style={{
              textAlign:"initial",
              fontFamily:"Poppins",
              fontStyle:"normal",
              fontWeight:"normal",
              fontSize:"12px",
              lineHeight:"16px",
              marginTop:"10px"
              }}>
              Password
            </span>
            <TextField
              classes={classes}
              style={{ borderRadius:"7px", height:"40px"}}
              error={errorPassword}
              helperText={errorMsgPassword}
              value={password}
              placeholder="Password"
              arial-label="password"
              size="small"
              margin="dense"
              onKeyPress={keyPress}
              onChange={(event) => {
                setPassword(event.target.value)
                setPasswordValidation(false)
                setLowerCase(false)
                setUpperCase(false)
                setSpecialChar(false)
                setNumber(false)
                setMinimumChar(false)
                if(PassValidation(event.target.value)){
                  setPassTicEnableDisable(true)
                  setErrorPassword(false)
                  setErrorMsgPassword('')
                }else{
                  setPassTicEnableDisable(false)
                }
                if(PassValidation(event.target.value)){
                  setPasswordValidation(true)
                }
                if(event.target.value.match(/[a-z]/)){
                  setLowerCase(true)
                }
                if(event.target.value.match(/[A-Z]/)){
                  setUpperCase(true)
                }
                if(event.target.value.match(/[@$!%*?&]/)){
                  setSpecialChar(true)
                }
                if(event.target.value.match(/[0-9]/)){
                  setNumber(true)
                }
                if(event.target.value.length>=8){
                  setMinimumChar(true)
                }
                /* if(schemas.has().lowercase().validate(event.target.value)){
                  setLowerCase(true)
                  console.log('..lowercase')
                }
                if(schemas.has().uppercase().validate(event.target.value)){
                  setUpperCase(true)
                  console.log('..uppercase')
                }
                if(schemas.has().symbols().validate(event.target.value)){
                  setSpecialChar(true)
                }
                if(schemas.has().digits().validate(event.target.value)){
                  setNumber(true)
                }
                if(schemas.is().min(8).validate(event.target.value)){
                  setMinimumChar(true)
                } */
               
              }}
              onBlur={()=>{
                if(!passwordValidation){
                  setErrorMsgPassword(<span style={{fontSize:"12px", color:"red", margin:"0px"}}>Invalid pasword. Try Again</span>)
                  setButtonStatus(true)
                  setErrorPassword(true)
                  setEnableInfo(true)
                } else{
                  setErrorMsgPassword('')
                  setErrorPassword(false)
                  setEnableInfo(false)
                }
              }}
             /*  label="Password" */
             /* placeholder="Password" */
              type={showPassword ? "text" : "password"}
              autoComplete="current-password"
              variant="outlined"
              /* helperText={errorMsgPassword} */
              InputProps={{
                // <-- This is where the toggle button is added.
                classes:classes,
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      color="primary"
                    >
                      {showPassword ? <Visibility color="primary" /> : <VisibilityOff color="primary" />}
                    </IconButton>
                    {passTicEnableDisable && password !=='' &&<IconButton> <CheckIcon color="primary" /></IconButton>}
                    {/* {errorMsgPassword && <ClickAwayListener onClickAway={handleTooltipClose}> */}
                      {enableInfo && !passTicEnableDisable && <div>
      
                        <HtmlTooltip
                          onMouseOut={handleTooltipClose}
                            open={tooltip}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            title={
                            <React.Fragment>
                              <div
                              style={{
                                backgroundColor: "#172B4D",
                                color: "#FFFFFF",
                                borderRadius: "8px",
                                width: "200px",
                                margin: theme.spacing(-1),
                                boxShadow: theme.shadows[5],
                                fontFamily: "Poppins",
                                fontStyle: "normal",
                                fontWeight: "normal",
                                fontSize: "12px",
                                lineHeight: "16px"
                              }}
                              >
                              <table style={{ width: "100%", padding:"8px"}}>
                              <tbody>
                                <tr>
                                  <td style={{ width: "90%" }}>Password required</td>
                                </tr>
                                <tr>
                                  <td style={{ width: "90%" }}>Minimum 8 char</td>
                                  <td style={{ width: "10%" }} align="center">
                                    {minimumChar && (
                                      <CheckCircle style={{ color: "#9b51e0", fontSize: "12px" }} />
                                    )}
                                  </td>
                                </tr>
                                <tr>
                                  <td style={{ width: "90%" }}>Atleast 1 lower case</td>
                                  <td style={{ width: "10%" }} align="center">
                                    {lowerCase && (
                                      <CheckCircle style={{ color: "#9b51e0", fontSize: "12px" }} />
                                    )}
                                  </td>
                                </tr>
                                <tr>
                                  <td style={{ width: "90%" }}>Atleast 1 upper case</td>
                                  <td style={{ width: "10%" }} align="center">
                                    {upperCase && (
                                      <CheckCircle style={{ color: "#9b51e0", fontSize: "12px" }} />
                                    )}
                                  </td>
                                </tr>
                                <tr>
                                  <td style={{ width: "90%" }}>Atleast 1 number</td>
                                  <td style={{ width: "10%" }} align="center">
                                    {number && (
                                      <CheckCircle style={{ color: "#9b51e0", fontSize: "12px" }} />
                                    )}
                                  </td>
                                </tr>
                                <tr>
                                  <td style={{ width: "80%" }}>Atleast 1 special char</td>
                                  <td style={{ width: "10%" }} align="center">
                                    {specialChar && (
                                      <CheckCircle style={{ color: "#9b51e0", fontSize: "12px" }} />
                                    )}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                              </div>
                            </React.Fragment>
                          }
                        >
                          <IconButton>
                            <InfoIcon color="primary" onMouseOver={handleTooltipOpen} />
                          </IconButton>
                        </HtmlTooltip>
                      </div>}
                    {/* </ClickAwayListener>} */}
                    {/* <IconButton>
                      {passwordValidation && <CheckIcon color="primary" />}
                    </IconButton> */}
                  </InputAdornment>
                ),
              }}
            /> 
            {/* <span style={{
              textAlign:"initial",
              fontFamily:"Poppins",
              fontStyle:"normal",
              fontWeight:"normal",
              fontSize:"12px",
              lineHeight:"16px"
              }}>
              {errorMsgPassword}
            </span> */}
          
            <div>
           {/*  <FormControl 
              style={{
                fontFamily: "Poppins",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "12px",
                lineHeight: "16px",
                float:"left"
                }}
              >
              <FormGroup aria-label="position">
                <FormControlLabel
                  value="end"
                  control={<Checkbox color="primary"  onChange={rememberCheck}/>}
                  label="Remember Me"
                  labelPlacement="end"
                />
              </FormGroup>
            </FormControl> */}
            <Link 
            onClick={forgetPassword} 
            style={{
              fontFamily: "Poppins",
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "12px",
              lineHeight: "16px",
              float:"initial",
              marginTop:"10px",
              color: "#172B4D",
              cursor:"pointer",
              textDecoration:"underline"
              }}>
              Forgot Password ?
            </Link>
            </div>
          </div>
          </ThemeProvider>  
          <Button
            color="primary"
            variant="contained"
            onClick={login}
            id="signInButton"
            disabled={emailTicEnableDisable && passTicEnableDisable? false : true}
            style={{
              margin:"0px 24px",
              fontFamily: "Poppins",
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "0.875rem",
              lineHeight: "1.75",
              display: "flex",
              alignItems: "center",
              textAlign: "center",
              color: "#FFFFFF",
              padding:"8px 20px",
              borderRadius:"8px",
            }}
          >
            Login
          </Button>
        <ThemeProvider theme={theme}>
          <div style={{ 
              fontFamily: "Poppins",
              fontStyle: "normal",
              fontWeight: "normal",
              fontSize: "12px",
              lineHeight: "16px",
              padding:"20px 20px",
              color: "#344563"
              }}>
            <span>View our  
              <Link style={{textDecoration: "underline"}} href="https://www.omnicuremd.com/privacy" target="_blank"> Privacy Policy</Link> &  
              <Link style={{textDecoration: "underline"}} href="https://www.omnicuremd.com/terms" target="_blank"> Terms of Usage</Link>
            </span>
          </div>
        </ThemeProvider>
          {loading && <LoginLoader />}
          {/* <div className="hrline">or</div>
                        <NavigationLink to="/app/sign-up"><Button style={{width: 'fit-content',alignSelf:'center',marginBottom:'20px'}} color="primary" variant="contained">Sign Up</Button></NavigationLink>
                        <NavigationLink to="/app/forgetPassword"><Link component="button" underline="hover" style={{fontSize:'14px',color:'#808080'}}>Forgot Password ?</Link></NavigationLink> */}
        </div>
      </div>
      <div style={{ minWidth: "auto" }}>
        <ThemeProvider theme={approvalListtheme} >
          <Dialog open={overridePopup} scroll={"paper"}>
            <DialogTitle style={{ display: 'flex', justifyContent: 'space-between' }} disableTypography>
              <span>Continue ?</span>
              <span className={classes.closeIcon} onClick={handleDialogClose} style={{ position: 'relative', top: 7, left: 16 }}></span>
            </DialogTitle>
            <DialogContent>
               <>
                  <DialogContentText variant='caption'>User already logged in Please click Yes to override.</DialogContentText>
               </>
            </DialogContent>
            <DialogActions>
                  <Button color="default"className={clsx(invertedButtonStyles)} onClick={() => { handleDialogClose() }} >No</Button>
                  <Button color="primary" className={clsx(invertedButtonStyles, invertedClass)}  variant="contained" onClick={() => {
                    setOverride(true)
                    setOverridePopup(false)
                  }}>Yes</Button>
            </DialogActions>
          </Dialog>
        </ThemeProvider>
      </div>
      <div>
        <Dialog
          open={expiredStatus}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Password Expired"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
          {' Your passsword has expired. please change it to access your account.'}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary" autoFocus>
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      {expiredMsg && <div><ExpiredMessage expiredMsg={expiredMsg} setexpiredLogin={setexpiredLogin}/></div>}
    </div>
  );
}

export default LoginScreen;
