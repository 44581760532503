import React,{useState,useEffect} from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import { useRecoilState, useRecoilValue,useSetRecoilState } from 'recoil';
import {Firebase,FirebaseApp,ShowNotification,AlertListNotification,unReadNotificationList} from 'App';
import "firebase/database";
import CloseIcon from '@material-ui/icons/Close';  
import IconButton from '@material-ui/core/IconButton';
import {isEmpty,map} from 'ramda';
import { useSnackbar } from 'notistack';
import moment from 'moment';

const NotificationManagement = () => {
    const [firebase] = useRecoilState(Firebase);
    const [firebaseApp] = useRecoilState(FirebaseApp);
    const setAlertsList = useSetRecoilState(AlertListNotification)
    const setUnreadAlertsList = useSetRecoilState(unReadNotificationList)
    const [alertsDictionary,setAlertsDictionary] = useRecoilState(ShowNotification);
    const [notificationOpen,setNotificationOpen] = useState(false)
    const [notificationMessage,setNotificationMessage] = useState('')
    const [initialRender,setInitialRender] = useState(true);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar(); 

    useEffect(()=>{
      if(!isEmpty(alertsDictionary)){
        /*setAlertsList(alertsList=>map((val,key)=>{
        if(val?.Patient&&alertsDictionary?.Patient){
          const {id:listId} = JSON.parse(val?.Patient)
          const {id:notificationId} = JSON.parse(alertsDictionary?.Patient)
          if(listId===notificationId){
            const {inviteTime} = JSON.parse(val?.Patient);
            const diffminutes = moment().diff(inviteTime,'minutes')
            let diffminutesmultipleoften = parseInt(diffminutes/10)*10
            return {...val,message:`${val?.message.replace(
              /[\w\d\.:-]*(?=\[UTC\])\[UTC\]/g,
              function() {
                return moment(arguments[0].replace('[UTC]','')).format('DD MMM YYYY hh:mm a')
              }
            ).replace(/[0-9]+$/,function(){
              return moment(Number(arguments[0])).format('DD MMM YYYY hh:mm a')
            })} - ${diffminutesmultipleoften?diffminutesmultipleoften:'10'} minutes`}
          }
          return {...val}
        }
        return {...val}
        },alertsList))*/
        /*setUnreadAlertsList(unreadAlertsList=>map((val,key)=>{
          if(val?.Patient){
            const {id:listId} = JSON.parse(val?.Patient)
            if(alertsDictionary?.Patient){
            const {id:notificationId} = JSON.parse(alertsDictionary?.Patient)
            if(listId===notificationId){
              const {inviteTime} = JSON.parse(val?.Patient);
              const diffminutes = moment().diff(inviteTime,'minutes')
              let diffminutesmultipleoften = parseInt(diffminutes/10)*10
              return {...val,message:`${val?.message.replace(
                /[\w\d\.:-]*(?=\[UTC\])\[UTC\]/g,
                function() {
                  return moment(arguments[0].replace('[UTC]','')).format('DD MMM YYYY hh:mm a')
                }
              ).replace(/[0-9]+$/,function(){
                return moment(Number(arguments[0])).format('DD MMM YYYY hh:mm a')
              })} - ${diffminutesmultipleoften?diffminutesmultipleoften:'10'} minutes`}
            }
            return {...val}
          }}
          return {...val}
          },unreadAlertsList))*/
        // setNotificationOpen(true)
        // setNotificationMessage(alertsDictionary?.message)
        if(alertsDictionary?.Patient){
        const {inviteTime} = JSON.parse(alertsDictionary?.Patient);
        const {serverTime} = alertsDictionary
        const diffminutes = moment().diff(inviteTime,'minutes')
        let diffminutesmultipleoften = parseInt(diffminutes/10)*10
        let message = alertsDictionary?.message?.replace(
          /[\w\d\.:-]*(?=\[UTC\])\[UTC\]/g,
          function() {
            return moment(arguments[0].replace('[UTC]','')).format('MMM DD YYYY, hh:mm A')
          }
        ).replace(/[0-9]+$/,function(){
          return moment(Number(arguments[0])).format('MMM DD YYYY, hh:mm A')
        });
        if(message.search(/minutes$/)!==-1){
          message = message.replace(/\d+(?= minutes$)/,diffminutes)
         // console.log(alertsDictionary,"AlertsDictionary")
        }
        else{
          message = message.concat(` - ${diffminutes} minutes`)
        }
        enqueueSnackbar(message,{
          action:(key) => (
            <IconButton size="small" aria-label="close" color="inherit" onClick={()=>{
              closeSnackbar(key)
              setAlertsDictionary({})}}><CloseIcon fontSize="small" /></IconButton>
        ),
          onClose(){
            setAlertsDictionary({})
          },
          anchorOrigin:{
            vertical: 'top',
            horizontal: 'right',
        }
        });

      }else{
        enqueueSnackbar(alertsDictionary?.message,{
          action:(key) => (
            <IconButton size="small" aria-label="close" color="inherit" onClick={()=>{
              closeSnackbar(key)
              setAlertsDictionary({})}}><CloseIcon fontSize="small" /></IconButton>
        ),
          onClose(){
            setAlertsDictionary({})
          },
          anchorOrigin:{
            vertical: 'top',
            horizontal: 'right',
        }
        });
      }}
      return ()=>{
        //setNotificationOpen(false)
      }
    },[alertsDictionary])
    // useEffect(()=>{
    //   try{
    //   const dataBase = firebaseApp.database('https://dev-omnicure-f9914.firebaseio.com/')
    //   dataBase.ref('alertsList').on('value',(snapshot)=>{
    //     const {AlertsCheck,AlertsMessage} = snapshot.val();
    //     if(AlertsCheck){
    //         setNotificationOpen(true);
    //         setNotificationMessage(AlertsMessage)
    //     }
    //     console.log(snapshot.val(),snapshot,"SnapShot")
    // })}
    // catch(error){
    
    // }
    // },[])
    return (
        <div>
     {/* <Snackbar
        anchorOrigin={{ vertical:'top', horizontal:'right' }}
        open={notificationOpen}
        autoHideDuration={10000}	
        onClose={()=>{
            setNotificationOpen(false)
            setAlertsDictionary({})
            // const dataBase = firebaseApp.database('https://dev-omnicure-f9914.firebaseio.com/')
            // dataBase.ref().set({AlertsMessage:'',AlertsCheck:false})
        }}
        message={notificationMessage}
        key={"Notification"}
        action={<IconButton size="small" aria-label="close" color="inherit" onClick={()=>{
            setNotificationOpen(false)
            setAlertsDictionary({})
            // const updatedNotificationList = notificationList.map(listValues=>{
            //   if(listValues.id===alertsDictionary.id){
            //     return {...alertsDictionary,read:true}
            //   }
            //   return listValues
            // })
            // const dataBase = firebaseApp.database('https://dev-omnicure-f9914.firebaseio.com/')
            // dataBase.ref('alertsList').set(updatedNotificationList)
        }}>
        <CloseIcon fontSize="small" />
      </IconButton>}
      />*/}
    </div>
    )
}

export default NotificationManagement;