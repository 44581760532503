import React, { useState, useEffect, useRef } from "react";
import { TextField, Button, InputLabel, MenuItem, Select, Input, Chip, ListItemText, Checkbox,  OutlinedInput, FormHelperText,Tabs,
  Tab, } from "@material-ui/core";
import "./AddHospitalScreen.css";
import API from "../../../api";
import Alert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Collapse from "@material-ui/core/Collapse";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import AlertList from "../Lists/AlertList";
import { useHistory } from "react-router-dom";
import ExpiredMessage from "../../common/ExpiredMessage";
//import {usersID,userIDBoolean} from "App";
import {useRecoilValue} from 'recoil';
import HyperlinkTextInput from "components/common/ContentEditable";
import { Firebase } from "App";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import axios from 'axios';
import { FormControl } from "@material-ui/core";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {F, uniq} from 'ramda';
import sanitizeHtml from "sanitize-html";
import HTML from "html-parse-stringify";
import {permission,usersID} from "App";
import linkifyHtml from 'linkifyjs/html';
import { parse } from 'node-html-parser';
import { Email } from "@material-ui/icons";
import { axisTypeLinear } from "react-charts/dist/react-charts.development";

import {ThemeProvider} from '@material-ui/styles';
import {createMuiTheme} from '@material-ui/core';
import {ReactComponent as DownArrowDropdown} from 'assets/icons/dropdownarrow.svg';
import {ReactComponent as DownArrowDropdownDisabled} from 'assets/icons/dropdownarrow_disabled.svg';

import {firebaseId} from 'App';
import {assocPath,pluck} from 'ramda';
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  titleHead:{
  fontFamily: "Poppins",
  fontSize: "18px",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "36px",
  letterSpacing: "-0.20000000298023224px",
  textAlign: "left",
  },
  subTitleHead:{
  fontFamily: "Poppins",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "20px",
  letterSpacing: "-0.20000000298023224px",
  textAlign: "left"
  },
  textFieldCss:{
    fontFamily:"Poppins",
    fontSize:"14px",
    color:"#172B4D",
    lineHeight: "16px",
    textAlign:"initial",

  }
}));


const useModalStyles = makeStyles({
  inputWrapper:{display:'flex',flexDirection:'column',rowGap:'10px',width:'100%'},
  singleLineTwoInputWrapper:({screenMediaQuery})=>({...(screenMediaQuery)?{ width:"100%" }:{flexBasis:'40%'},alignSelf:'baseline'}),
  dropdownPlaceholder:{color:'#5E6C84 !important',fontSize:14,transform: 'translate(14px, 12px) scale(1)'},
  dropdownLabel:{color:'#172B4D',
  fontSize:12,
  lineHeight:'16px',
  textAlign:"initial",

},
  closeIcon:{width:26,height:26,'&::before':{
    position:'absolute',
    content: "' '",
    height: '16.08px',
    width: '0.89px',
    backgroundColor: '#172B4D',
    transform: 'rotate(45deg)'
  },'&::after':{
    position:'absolute',
    content: "' '",
    height: '16.08px',
    width: '0.89px',
    backgroundColor: '#172B4D',
    transform: 'rotate(-45deg)'
  }},
  dropdownMenuBorder:{
    border:'1px solid #5E6C84',
  },
  selectPlaceholder:{
    '& input::-webkit-input-placeholder':{
      opacity:'initial',
      color:'#5E6C84',
      fontSize:14
    }
  }
})

const modalScreenTheme = createMuiTheme({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: "#9B51E0",
    },
    secondary: {
      // This is green.A700 as hex.
      main: "#172B4D",
    },
    success: {
      // This is green.A700 as hex.
      main: "#cb114c",
    },
  },
  typography:{
    fontFamily:'Poppins'
  },
  overrides : {
    MuiDialog :{
      root:{
        fontFamily:'Poppins'
      },
      paper:{
      minWidth:'42vw',
      borderRadius:10
    }},
    MuiInputLabel:{
      root:{
        color:'#172B4D',
        fontSize:12,
        lineHeight:'16px'
      },
      shrink:{
        visibility:'hidden'
      }
    },
    MuiSelect:{icon:{
      top:'initial',
      right:'10px'
    }},
    MuiFilledInput:{
      root:{
        height:40,
        borderRadius:'8px !important',
        border:'1px solid rgb(94, 108, 132)',
        borderColor:'rgb(94, 108, 132)'
      },
      input:{
        padding:0,
        paddingLeft:14
      }
    },
    MuiOutlinedInput:{
      root:{
        height: 40,
        fontSize: 14,
        fontColor: 'rgb(23, 43, 77)',
        "& > input::placeholder":{
          fontStyle:'italic'
        }
      },
      notchedOutline:{
        borderRadius:8,
        border:'1px solid rgb(94, 108, 132)',
        borderColor:'rgb(94, 108, 132)'
      }
    },
    MuiFormControlLabel:{label:{fontSize:14}},
    MuiDialogTitle:{
      root:{
        fontWeight:700,
        fontSize:18,
        lineHeight:'24px',
        borderBottom: '1px solid #EBECF0'
      }
    },
    MuiButton:{
      root:{
        padding: '8px 20px',
        height:40,
        backgroundColor:'#FFF !important',
        color:'#9B51E0 !important',
        border: '2px solid #9B51E0',
        borderRadius:8
      },
      contained:{
        boxShadow:'none'
      },
      containedPrimary:{
        border:'none',
        color:'#FFF !important',
        backgroundColor:'#9B51E0 !important'
      }
    },
    MuiMenu:{
      paper:{
        border:'1px solid #5E6C84',
        borderRadius: 8
      }
    }
  }
});

const NOTIFICATION = "Notification";
const EMAIL = "Email";
const SMS = "sms";

const SystemAlertScreen = (props) => {
  const classes = useStyles();
  const [clickState,setClickState] = useState();
  const [message, setMessage] = useState("");
  const [title, setTitle] = useState("");
  const [loading, setLoading] = useState(false);
  const [systemAlert, setSystemAlert] = useState("");
  const [open, setOpen] = useState(true);
  const [titleError, setTitleError] = useState(false);
  const [messageError, setMessageError] = useState(false);
  const screenMediaQuery = useMediaQuery(`(max-width:635px)`);
  const [alertList, showAlertList] = useState(false);
  const [emailalertList, showEmailAlertList] = useState(false);
  const [smsalertList, showSmsAlertList] = useState(false);
  const [expiredMsg, setExpiredMsg] = useState(false);
  const [expiredLogin, setexpiredLogin] = useState(false);
  const [mainList, setMainList] = useState([]);
  const [subList, setSubList] = useState([]);
  const [groupData, setGroupData] = useState([])
  const [filterGroup, setFilterGroup] = useState([])
  const [userGroupResponse, setUserGroupResponse] = useState([])
  const [providerGroupResponse, setProviderGroupResponse] = useState([])
  const [allGroupType, setAllGroupType] = useState("")
  const [html, setHtml] = useState("");
  const history = useHistory();
  const firebaseretry = useRecoilValue(Firebase);
  const [titleShow, setTitleShow] = useState(false);
  const [messageShow, setMessageShow] = useState(false);
  const hyperlinkComponentRef = useRef(null);
  const [dropFirstErrorMsg, setDropFirstErrorMsg] = useState("");
  const [dropSecondErrorMsg, setDropSecondErrorMsg] = useState("");
  const [dropFirstError, setDropFirstError] = useState(false);
  const [dropSecondError, setDropSecondError] = useState(false);
  const [allUserGroup, setAllUserGroup] = useState([]);
  const [allProvider, setAllProvider] = useState([]);
  const LoginusersID = useRecoilValue(usersID)
  const permissionLevel = useRecoilValue(permission)
  const firebaseuid=useRecoilValue(firebaseId)
  const usersuid = useRecoilValue(usersID)
  const [namesubList, setNameSubList] = useState([]);
  const [namegroupData,setNameGroupData]=useState([]);
  const [dropThirdErrorMsg, setDropThirdErrorMsg] = useState("");
  const [dropThirdError, setDropThirdError] = useState(false);
  const [userThirdDropdownResponse, setuserThirdDropdownResponse] = useState([])
  const [thirdDropdownSelectedIds,setThirdDropdownSelectedIds]=useState([]);
  const [secoundDropdownDisabled,setSecoundDropdownDisabled] = useState(true);
  const [thirdDropdownDisabled,setThirdDropdownDisabled] = useState(true);

// Email state management

  const [emailmainList, setEmailMainList] = useState([]);
  const [emailgroupData, setEmailGroupData] = useState([])
  const [emailuserGroupResponse, setEmailUserGroupResponse] = useState([])
  const [emailproviderGroupResponse, setEmailProviderGroupResponse] = useState([])
  const [emaildropFirstError, setEmailDropFirstError] = useState(false);
  const [emaildropFirstErrorMsg, setEmailDropFirstErrorMsg] = useState("");
  const [emailsubList, setEmailSubList] = useState([]);
  const [emailallGroupType, setEmailAllGroupType] = useState("")
  const [emailexpiredMsg, setEmailExpiredMsg] = useState(false);
  const [emailexpiredLogin, setEmailexpiredLogin] = useState(false);
  const [emailsystemAlert, setEmailSystemAlert] = useState("");
  const [emailloading, setEmailLoading] = useState(false);
  const [emailmessage, setEmailMessage] = useState("");
  const [emaildropSecondError, setEmailDropSecondError] = useState(false);
  const [emaildropSecondErrorMsg, setEmailDropSecondErrorMsg] = useState("");
  const [emailfilterGroup, setEmailFilterGroup] = useState([])
  const emailhyperlinkComponentRef = useRef(null);
  const [emailmessageError, setEmailMessageError] = useState(false);
  const [emailmessageShow, setEmailMessageShow] = useState(false);
  const [emailnamesubList, setEmailNameSubList] = useState([]);
  const [emailnamegroupData,setEmailNameGroupData]=useState([]);
  const [emaildropThirdErrorMsg, setEmailDropThirdErrorMsg] = useState("");
  const [emaildropThirdError, setEmailDropThirdError] = useState(false);
  const [emailuserThirdDropdownResponse, setuserEmailThirdDropdownResponse] = useState([])
  const [emailthirdDropdownSelectedIds,setEmailThirdDropdownSelectedIds]=useState([]);
  const [emailSecoundDropdownDisabled,setEmailSecoundDropdownDisabled] = useState(true);
  const [emailThirdDropdownDisabled,setEmailThirdDropdownDisabled] = useState(true);

  // sms state management 

  const [smsmainList, setSmsMainList] = useState([]);
  const [smsgroupData, setSmsGroupData] = useState([])
  const [smsuserGroupResponse, setSmsUserGroupResponse] = useState([])
  const [smsproviderGroupResponse, setSmsProviderGroupResponse] = useState([])
  const [smsdropFirstError, setSmsDropFirstError] = useState(false);
  const [smsdropFirstErrorMsg, setSmsDropFirstErrorMsg] = useState("");
  const [smssubList, setSmsSubList] = useState([]);
  const [smsallGroupType, setSmsAllGroupType] = useState("")
  const [smsexpiredMsg, setSmsExpiredMsg] = useState(false);
  const [smsexpiredLogin, setSmsexpiredLogin] = useState(false);
  const [smssystemAlert, setSmsSystemAlert] = useState("");
  const [smsloading, setSmsLoading] = useState(false);
  const [smsmessage, setSmsMessage] = useState("");
  const [smsdropSecondError, setSmsDropSecondError] = useState(false);
  const [smsdropSecondErrorMsg, setSmsDropSecondErrorMsg] = useState("");
  const [smsfilterGroup, setSmsFilterGroup] = useState([])
  const smshyperlinkComponentRef = useRef(null);
  const [smsmessageError, setSmsMessageError] = useState(false);
  const [smsmessageShow, setSmsMessageShow] = useState(false);
  const [smsnamesubList, setSmsNameSubList] = useState([]);
  const [smsnamegroupData,setSmsNameGroupData]=useState([]);
  const [smsdropThirdErrorMsg, setSmsDropThirdErrorMsg] = useState("");
  const [smsdropThirdError, setSmsDropThirdError] = useState(false);
  const [smsuserThirdDropdownResponse, setuserSmsThirdDropdownResponse] = useState([])
  const [smsthirdDropdownSelectedIds,setSmsThirdDropdownSelectedIds]=useState([]);
  const [smsSecoundDropdownDisabled,setSmsSecoundDropdownDisabled] = useState(true);
  const [smsThirdDropdownDisabled,setSmsThirdDropdownDisabled] = useState(true);
  const [statusFilter, setStatusFilter] = useState(NOTIFICATION);
  const [subject, setsubject] = useState("");
  const [subjectShow, setsubjectShow] = useState(false);
  const [subjectError, setsubjectError] = useState(false);


  const [smseditorMessage,setSmsEditorMessage] = useState();
  const [emaileditorMessage,setEmailEditorMessage] = useState();
  const [notifyeditorMessage,setNotifyEditorMessage] = useState();

  const [sendAlertClicked,setSendAlertClicked] = useState(false);
  const [sendEmailClicked,setSendEmailClicked] = useState(false);
  const [sendSmsClicked,setSendSmsClicked] = useState(false);

const FilterTabs = () => {
  return (
    <Tabs
      value={statusFilter}
      indicatorColor="primary"
      textColor="primary"
      onChange={handleOnChange}
      variant={screenMediaQuery ? "fullWidth" : "standard"}
    >
      <Tab value={NOTIFICATION} id="NotificationTab" label="Notification" />
      <Tab value={EMAIL} id="EmailTab" label="Email" />
      <Tab value={SMS} id="SMS" label="sms" />
    </Tabs>
  );
};

const handleOnChange = (event, newValue) => {
  setOpen(false);
  setStatusFilter(newValue);  
};

// const screenMediaQuery = useMediaQuery("(max-width:635px)");
const {inputWrapper,singleLineTwoInputWrapper,dropdownPlaceholder,dropdownLabel,closeIcon,dropdownMenuBorder,selectPlaceholder}= useModalStyles({screenMediaQuery})


  const SelectRef = useRef();
  const mutationSelectRef = useRef();

  const retryApiGet = (url, header) => {
    return API.get(url, assocPath(['headers','uid'],firebaseuid,header)).then((res)=>{
      if(res?.headers?.userid===String(usersuid)){
        return Promise.resolve(res)
      }else{
        throw {message:"Invalid Response"}
        //return Promise.reject(new Error({message:'invalid access'}))
      }
    }).catch(function (error) {
      if ([401, 500, 502, 504, 703, 704].includes(error?.response?.status)) {
        return new Promise((resolve) => {
          API.post("/userEndpoints/v1/renewIdToken", {
            token: props.refreshId,
          })
            .then((res) => {
              props.setTokenId(res.data.idToken);
              if (!res.data.idToken) {
                setExpiredMsg(true);
                if (expiredLogin) {
                  props.setUserId(null);
                  history.push("/");
                }
                throw error;
              } else {
                resolve(
                  API.get(url, {
                    headers: {
                      Authorization: res.data.idToken,
                      uid:firebaseuid
                    }
                  }).then((res)=>{
                    if(res?.headers?.userid===String(usersuid)){
                      return Promise.resolve(res)
                    }else{
                      throw {message:"Invalid Response"}
                      //return Promise.reject(new Error({message:'invalid access'}))
                    }
                  })
                );
              }
            })
            .catch((error) => {
              Promise.reject(error);
            });
        });
      }
      else{
        throw error
      }
    });
  };
  const retryApiPost = (url, data, header) => {
    return API.post(url, {...data,id:usersuid}, assocPath(['headers','uid'],firebaseuid,header)).then((res)=>{
      if(res?.headers?.userid===String(usersuid)){
        return Promise.resolve(res)
      }else{
        throw {message:"Invalid Response"}
        //return Promise.reject(new Error({message:'invalid access'}))
      }
    }).catch(function (error) {
      if ([401, 500, 502, 504, 703, 704].includes(error?.response?.status)) {
        return new Promise((resolve) => {
          API.post("/userEndpoints/v1/renewIdToken", {
            token: props.refreshId,
          })
            .then((res) => {
              props.setTokenId(res.data.idToken);
              if (!res.data.idToken) {
                setExpiredMsg(true);
                if (expiredLogin) {
                  props.setUserId(null);
                  history.push("/");
                }
                throw error;
              } else {
                resolve(
                  API.post(url, {...data,id:usersuid}, {
                    headers: {
                      Authorization: res.data.idToken,
                      uid:firebaseuid
                    }
                  }).then((res)=>{
                    if(res?.headers?.userid===String(usersuid)){
                      return Promise.resolve(res)
                    }else{
                      throw {message:"Invalid Response"}
                      //return Promise.reject(new Error({message:'invalid access'}))
                    }
                  })
                );
              }
            })
            .catch((error) => {
              Promise.reject(error);
            });
        });
      }else{
        throw error
      }
    });
  };

  // useEffect(() => () => {
  //   titleError && setTitleError(false);
  //   messageError && setMessageError(false);
  // });
  const refreshToken = () => {
    API.post("/userEndpoints/v1/renewIdToken", {
      token: props.refreshId,
    })
      .then((res) => {
        props.setTokenId(res.data.idToken);
        if (!res.data.idToken) {
          setExpiredMsg(true);
          if (expiredLogin) {
            props.setUserId(null);
            history.push("/");
          }
        }
      })
      .catch((error) => {});
  };
  // useEffect(()=>{
  //   const refreshAuthLogic = failedRequest =>
  //   API.post("/userEndpoints/v1/renewIdToken",{
  //     "token": props.refreshId
  //   })
  //   .then((res)=>{
  //     props.setTokenId(res.data.idToken)
  //     if(!res.data.idToken){
  //       setExpiredMsg(true)
  //       if(expiredLogin){
  //         props.setUserId(null)
  //         history.push("/")
  //         return Promise.reject();
  //       }
  //       return Promise.reject();
  //     }
  //     failedRequest.response.config.headers["Authorization"] = res.data.idToken;
  //   return Promise.resolve();
  //   })
  //   .catch((error) =>{
  //     console.log("refresh fail");
  //     return Promise.reject(error);
  //   })
  //   createAuthRefreshInterceptor(API, refreshAuthLogic,{statusCodes:[401,500,502,504,703,704]});
  // },[])
  useEffect(()=>()=>mutationSelectRef?.current?.disconnect && mutationSelectRef.current.disconnect(),[])
  useEffect(() => {
    if (expiredLogin) {
      props.setUserId(null);
      history.push("/");
    }
  }, [expiredLogin]);
  let HyperlinkTextInputErrorMessage;
  if (messageError) {
    HyperlinkTextInputErrorMessage = "Please Enter Message";
  } else if (messageShow) {
    if (
      document.getElementsByClassName("editable")?.[0]?.innerHTML ===
      "<p><br></p>"
    ) {
      HyperlinkTextInputErrorMessage = "200 Characters left";
    } else if (document.getElementsByClassName("editable")?.[0]?.innerText) {
      HyperlinkTextInputErrorMessage = `${
        200 - document.getElementsByClassName("editable")?.[0]?.innerText.length
      } characters left`;
    } else {
      HyperlinkTextInputErrorMessage = "200 Characters left";
    }  } else {
      HyperlinkTextInputErrorMessage = "";
    }



    useEffect(() => {
      if (emailexpiredLogin) {
        props.setUserId(null);
        history.push("/");
      }
    }, [emailexpiredLogin]);


    let emailHyperlinkTextInputErrorMessage;
    if (emailmessageError) {
      emailHyperlinkTextInputErrorMessage = "Please Enter Message";
    } else if (emailmessageShow) {
      if (
        document.getElementsByClassName("editable")?.[0]?.innerHTML ===
        "<p><br></p>"
      ) {
        emailHyperlinkTextInputErrorMessage = "400 Characters left";
      } else if (document.getElementsByClassName("editable")?.[0]?.innerText) {
        emailHyperlinkTextInputErrorMessage = `${
          400 - document.getElementsByClassName("editable")?.[0]?.innerText.length
        } characters left`;
      } else {
        emailHyperlinkTextInputErrorMessage = "400 Characters left";
      }  } else {
        emailHyperlinkTextInputErrorMessage = "";
      }



      useEffect(() => {
        if (smsexpiredLogin) {
          props.setUserId(null);
          history.push("/");
        }
      }, [smsexpiredLogin]);
      let smsHyperlinkTextInputErrorMessage;
      if (smsmessageError) {
        smsHyperlinkTextInputErrorMessage = "Please Enter Message";
      } else if (smsmessageShow) {
        if (
          document.getElementsByClassName("editable")?.[0]?.innerHTML ===
          "<p><br></p>"
        ) {
          smsHyperlinkTextInputErrorMessage = "200 Characters left";
        } else if (document.getElementsByClassName("editable")?.[0]?.innerText) {
          smsHyperlinkTextInputErrorMessage = `${
            200 - document.getElementsByClassName("editable")?.[0]?.innerText.length
          } characters left`;
        } else {
          smsHyperlinkTextInputErrorMessage = "200 Characters left";
        }  } else {
          smsHyperlinkTextInputErrorMessage = "";
        }



  const alertData = (event)=>{
    setDropSecondError(false)
    setDropSecondErrorMsg("")
    setGroupData(event.target.value)
    setFilterGroup([])
    setNameGroupData([])
    let userGroupId = [];
    let providerId = [];
    let userGroupArray=[];
    setThirdDropdownDisabled(false)
    if(sendAlertClicked){
      if (namegroupData.length < 1) {
        setDropThirdError(true)
        setDropThirdErrorMsg(<span style={{ color: "#f44336" }}>Please select a values</span>)
      }
    }

    if(userGroupResponse && userGroupResponse.length>=1){
      const userList = userGroupResponse.filter((userGrouplist)=>{return event.target.value.includes(userGrouplist.id)})
      userList.forEach((value)=>{
        const list = {
          id: value.id
        }
        userGroupId.push(list)
        userGroupArray.push(value.id)
      })
      setFilterGroup(userGroupId)
      setAllGroupType("userGroups")
    }else if(providerGroupResponse && providerGroupResponse.length>=1){
      const providerList = providerGroupResponse.filter((userList)=>{return event.target.value.includes(userList.userSubType)})
      providerList.forEach((value)=>{
        const list = {
          id: value.id
        }
        providerId.push(list)
      })
      setFilterGroup(providerId)
      setAllGroupType("providers")
    }else{
      setAllGroupType("all")
     // setGroupData("all")
    }


    let restVar = {};
    restVar.type = mainList;
    if(String(mainList) === "usergroups" || String(mainList) === "all"){
        restVar.type = "userGroups";
        restVar.userGroupIds = userGroupArray
    }
    if(String(mainList) === "providers"){
      restVar.selectedGroup = event.target.value
    }

    retryApiPost(
      "/adminUserEndpoints/v1/getUsers",restVar,
      {
        headers: {
          Authorization: props.tokenId,
        }
      }
    ).then((res) => {
      let returnData = [];
        if(res.data.status){
          setuserThirdDropdownResponse(res.data.userList);
          res.data.userList.sort((a,b)=>a.id - b.id);
          res.data.userList.map((item)=>{
            returnData.push(item.name)
          });
          // let newNameGroupData = namegroupData.map((item)=>{
          //   if(returnData.indexOf(item) > -1){
          //     return item
          //   }else{
          //     return [];
          //   }
          // })
          // if(typeof(newNameGroupData) !== "undefined"){
          //   setNameGroupData(newNameGroupData);
          // }
          setNameSubList(res.data.userList)
        }else{
          setNameSubList([])
        }
      })
      .catch((error) => {
       // console.log(error)
      })

  }

  const alertEmailData = (event)=>{
    setEmailDropSecondError(false)
    setEmailDropSecondErrorMsg("")
    setEmailGroupData(event.target.value)

    setEmailFilterGroup([])
    setEmailNameGroupData([])

    let userGroupId = []
    let providerId = []
    let emailuserGroupArray=[];

    setEmailThirdDropdownDisabled(false)
    if(sendEmailClicked){
    if (emailnamegroupData.length < 1) {
      
      setEmailDropThirdError(true)
      setEmailDropThirdErrorMsg(<span style={{ color: "#f44336" }}>Please select a values</span>)
    }
  }


    if(emailuserGroupResponse && emailuserGroupResponse.length>=1){
      const userList = emailuserGroupResponse.filter((userGrouplist)=>{return event.target.value.includes(userGrouplist.id)})
      userList.forEach((value)=>{
        //console.log('...', userList)
        const list = {
          id: value.id
        }
        userGroupId.push(list)
        emailuserGroupArray.push(value.id)

      })
      setEmailFilterGroup(userGroupId)
      setEmailAllGroupType("userGroups")
    }else if(emailproviderGroupResponse && emailproviderGroupResponse.length>=1){
      const providerList = emailproviderGroupResponse.filter((userList)=>{return event.target.value.includes(userList.userSubType)})
      providerList.forEach((value)=>{
        const list = {
          id: value.id
        }
        providerId.push(list)
      })
      setEmailFilterGroup(providerId)
      setEmailAllGroupType("providers")
    }else{
      setEmailAllGroupType("all")
     // setGroupData("all")
    }

    let restVar = {};
    restVar.type = emailmainList;
    if(String(emailmainList) === "usergroups" || String(emailmainList) === "all"){
        restVar.type = "userGroups";
        restVar.userGroupIds = emailuserGroupArray
    }
    if(String(emailmainList) === "providers"){
      restVar.selectedGroup = event.target.value
    }

    retryApiPost(
      "/adminUserEndpoints/v1/getUsers",restVar,
      {
        headers: {
          Authorization: props.tokenId,
        }
      }
    ).then((res) => {
      let returnData = [];
        if(res.data.status){
          setuserEmailThirdDropdownResponse(res.data.userList);
          res.data.userList.sort((a,b)=>a.id - b.id);
          res.data.userList.map((item)=>{
            returnData.push(item.name)
          });
          // let newNameGroupData = emailnamegroupData.map((item)=>{
          //   if(returnData.indexOf(item) > -1){
          //     return item
          //   }else{
          //     return [];
          //   }
          // })
          // if(typeof(newNameGroupData) !== "undefined"){
          //   setEmailNameGroupData(newNameGroupData);
          // }
          setEmailNameSubList(res.data.userList)
        }else{
          setEmailNameSubList([])
        }
      })
      .catch((error) => {
       // console.log(error)
      })
  }



  const alertSmsData = (event)=>{
    setSmsDropSecondError(false)
    setSmsDropSecondErrorMsg("")
    setSmsGroupData(event.target.value)
    setSmsFilterGroup([])
    setSmsNameGroupData([])

    let userGroupId = []
    let providerId = []
    let smsuserGroupArray=[];

    setSmsThirdDropdownDisabled(false)
    if(sendSmsClicked){
    if (smsnamegroupData.length < 1) {
      setSmsDropThirdError(true)
      setSmsDropThirdErrorMsg(<span style={{ color: "#f44336" }}>Please select a values</span>)
    }
  }

    if(smsuserGroupResponse && smsuserGroupResponse.length>=1){
      const userList = smsuserGroupResponse.filter((userGrouplist)=>{return event.target.value.includes(userGrouplist.id)})
      userList.forEach((value)=>{
        const list = {
          id: value.id
        }
        userGroupId.push(list)
        smsuserGroupArray.push(value.id)

      })
      setSmsFilterGroup(userGroupId)
      setSmsAllGroupType("userGroups")
    }else if(smsproviderGroupResponse && smsproviderGroupResponse.length>=1){
      const providerList = smsproviderGroupResponse.filter((userList)=>{return event.target.value.includes(userList.userSubType)})
      providerList.forEach((value)=>{
        const list = {
          id: value.id
        }
        providerId.push(list)
      })
      setSmsFilterGroup(providerId)
      setSmsAllGroupType("providers")
    }else{
      setSmsAllGroupType("all")
    }

    let restVar = {};
    restVar.type = smsmainList;
    if(String(smsmainList) === "usergroups" || String(smsmainList) === "all"){
        restVar.type = "userGroups";
        restVar.userGroupIds = smsuserGroupArray
    }
    if(String(smsmainList) === "providers"){
      restVar.selectedGroup = event.target.value
    }

    retryApiPost(
      "/adminUserEndpoints/v1/getUsers",restVar,
      {
        headers: {
          Authorization: props.tokenId,
        }
      }
    ).then((res) => {
      let returnData = [];
        if(res.data.status){
          setuserSmsThirdDropdownResponse(res.data.userList);
          res.data.userList.sort((a,b)=>a.id - b.id);
          res.data.userList.map((item)=>{
            returnData.push(item.name)
          });
          // let newNameGroupData = smsnamegroupData.map((item)=>{
          //   if(returnData.indexOf(item) > -1){
          //     return item
          //   }else{
          //     return [];
          //   }
          // })
          // if(typeof(newNameGroupData) !== "undefined"){
          //   setSmsNameGroupData(newNameGroupData);
          // }
          setSmsNameSubList(res.data.userList)
        }else{
          setSmsNameSubList([])
        }
      })
      .catch((error) => {
       // console.log(error)
      })
  }



  const alertNameData = (event)=>{
    let nameGroupIds=[];
    let currentSelectedValue = event.target.value;
    setDropThirdError(false);
    setDropThirdErrorMsg("");
    if( event.currentTarget.innerText == "Select All"){
      if(event.currentTarget.ariaSelected){
        setNameGroupData([]);
      }else{
        setNameGroupData(pluck('id',namesubList));
        currentSelectedValue=pluck('id',namesubList);
      }
    }else{
      setNameGroupData(currentSelectedValue);
    }
    if(userThirdDropdownResponse && userThirdDropdownResponse.length>=1){
      const userList = userThirdDropdownResponse.filter((userGrouplist)=>{return currentSelectedValue.includes(userGrouplist.id)})
      userList.forEach((value)=>{
        if(value.id != 1){
          nameGroupIds.push(value.id)
        }
      })
      setThirdDropdownSelectedIds(nameGroupIds);
    }

  }



  const alertEmailNameData = (event) => {
    let nameGroupIds = [];
    let currentSelectedValue = event.target.value;
    setEmailDropThirdError(false);
    setEmailDropThirdErrorMsg("");
    if (event.currentTarget.innerText == "Select All") {
      if (event.currentTarget.ariaSelected) {
        setEmailNameGroupData([]);
      } else {
        setEmailNameGroupData(pluck('id',emailnamesubList));
        currentSelectedValue = pluck('id',emailnamesubList);
      }
    } else {
      setEmailNameGroupData(currentSelectedValue);
    }
    if (emailuserThirdDropdownResponse && emailuserThirdDropdownResponse.length >= 1) {
      const userList = emailuserThirdDropdownResponse.filter((userGrouplist) => { return currentSelectedValue.includes(userGrouplist.id) })
      userList.forEach((value) => {
        if(value.id != 1){
          nameGroupIds.push(value.id)
        }
      })
      setEmailThirdDropdownSelectedIds(nameGroupIds);
    }

  }



  const alertSmsNameData = (event)=>{
    let nameGroupIds=[];
    let currentSelectedValue = event.target.value;
    setSmsDropThirdError(false);
    setSmsDropThirdErrorMsg("");
    if( event.currentTarget.innerText == "Select All"){
      if(event.currentTarget.ariaSelected){
        setSmsNameGroupData([]);
      }else{
        setSmsNameGroupData(pluck('id',smsnamesubList));
        currentSelectedValue=pluck('id',smsnamesubList);
      }
    }else{
      setSmsNameGroupData(currentSelectedValue);
    }
    if(smsuserThirdDropdownResponse && smsuserThirdDropdownResponse.length>=1){
      const userList = smsuserThirdDropdownResponse.filter((userGrouplist)=>{return currentSelectedValue.includes(userGrouplist.id)})
      userList.forEach((value)=>{
        if(value.id != 1){
          nameGroupIds.push(value.id)
        }
      })
      setSmsThirdDropdownSelectedIds(nameGroupIds);
    }

  }


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  getContentAnchorEl:()=>null,
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
    id:'dropDown_two',
  },
};

useEffect(()=>{
  setTitleError('')
},[title])

useEffect(()=>{
  setsubjectError('')
},[subject])

useEffect(()=>{
  setMessageError('')
},[message])

useEffect(()=>{
  setEmailMessageError('')
},[emailmessage])

useEffect(()=>{
  setSmsMessageError('')
},[smsmessage])

const getMessagOnchange =(value)=>{
    if(statusFilter ===  NOTIFICATION){
      setNotifyEditorMessage(value)
    }
    if(statusFilter ===  EMAIL){
      setEmailEditorMessage(value)
    }
    if(statusFilter ===  SMS){
      setSmsEditorMessage(value)
    }
  }

useEffect(()=>{
  setDropFirstError(false)
  setDropFirstErrorMsg("")
},[mainList])

// useEffect(()=>{
//   setDropSecondError(false)
//   setDropSecondErrorMsg("")
// },[groupData])

// useEffect(()=>{
//   //Select_two_Ref&&Select_two_Ref.current&&Select_two_Ref.current.updatePosition&&Select_two_Ref.current.updatePosition({placement:'left'})
//   const {y}=SelectRef?.current?.getBoundingClientRect()
//   if(y){if(document.getElementById('dropDown_two')?.style?.top){document.getElementById('dropDown_two').style.top= `${y}px`;document.getElementById('dropDown_two').style.transformOrigin='150px 0'}}
// },[groupData])

  return (
    <ThemeProvider theme={modalScreenTheme}>
    <div>
      <div className={classes.titleHead}>System Alert</div>
      {FilterTabs()}
      {statusFilter === NOTIFICATION && (
      <div>
        {systemAlert !== "" && (
          <div style={{ fontSize: "18px", color: "#fb1b1b", padding: "0 0 10px" }}>
            <Collapse in={open}>
              <Alert
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setOpen(false);
                      setMessage("");
                      setTitle("");
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                {systemAlert}
              </Alert>
            </Collapse>
          </div>
        )}
        <div
          style={{
            margin: "auto",
            padding: `15px ${screenMediaQuery ? "15px" : "35px"} 25px ${screenMediaQuery ? "15px" : "35px"
              }`,
            display: "flex",
            flexDirection: "column",
            backgroundColor: "white",
            /*  maxWidth: '700px', */
          }}
        >
          {/* <div className={classes.subTitleHead}> Notification</div> */}
          <div
            style={{
              display: "flex",
              backgroundColor: "white",
              ...(!screenMediaQuery && { minWidth: "400px" }),
              opacity: 2,
              flexDirection: "column",
            }}
          >

            <div className={inputWrapper}>
            <InputLabel className={classes.textFieldCss} style={{marginLeft:10}}>Title</InputLabel>
            <TextField
              className={selectPlaceholder}
              style={{
                width:'98%',
                margin:'10px'
            }}
              variant="outlined"
              value={title}
              error={titleError || title.length >= 50}
              helperText={
                titleError
                  ? title.length > 50 ? `${50 - title.length} characters left` : "Please Enter Title"
                  : titleShow
                    ? `${50 - title.length} characters left`
                    : ""
              }
              FormHelperTextProps={{ style: { ...(!titleError || title.length) && { textAlign: 'right' } } }}
              onFocus={() => {
                setTitleShow(true);
              }}
              onBlur={() => {
                setTitleShow && setTitleShow(false);
              }}
              onChange={(event) => {
                setTitle(event.target.value);
              }}
              placeholder="Title"

            />
          </div>



            {/*<TextField
            style={{ margin: "10px" }}
            error={messageError}
            helperText={messageError && "Please Enter Message"}
            multiline
            rows={4}
            variant="outlined"
            value={message}
            onChange={(event) => setMessage(event.target.value)}
            label="Message"
          />*/}
          <div>
            </div>

            <div className={inputWrapper}>
            <InputLabel className={classes.textFieldCss} style={{marginLeft:10}}>Message</InputLabel>
            <HyperlinkTextInput
              ref={hyperlinkComponentRef}
              onChange={(value) => setHtml(value)}
              error={messageError}
              helperText={HyperlinkTextInputErrorMessage}
              onFocus={() => {
                setMessageShow(true);
              }}
              onBlur={() => {
                setMessageShow && setMessageShow(false);
              }}
              updateParent={(messageValue) => {
                setMessage(messageValue)
              }}
              getMessage={(value)=>getMessagOnchange(value)}
              editorMessage = {notifyeditorMessage}

            />
            </div>
            <div style={{ margin: "10px", display: "flex", columnGap: "10px", justifyContent: "initial" }}>



            <div className={`${inputWrapper}`}>
              <div className={classes.textFieldCss}>Send to</div>

              <FormControl variant="outlined" >
                <InputLabel id="demo-simple-select-outlined-label" className={dropdownPlaceholder} >Send to</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={mainList}
                  error={dropFirstError}
                  IconComponent={DownArrowDropdown}
                  onChange={(event) => {
                    const data = []
                    const totalUser = []
                    const value = event.target.value;
                    setMainList(event.target.value)
                    setGroupData([])
                    setUserGroupResponse()
                    setProviderGroupResponse()
                    setDropFirstError(false)
                    setDropFirstErrorMsg("")
                    setSubList([])
                    setNameSubList([])
                    setNameGroupData([])
                    if(sendAlertClicked){
                      if (groupData.length < 1) {
                        setDropSecondError(true)
                        setDropSecondErrorMsg(<span style={{ color: "#f44336" }}>Please select a values</span>)
                      }
                    }
                    if (String(value) === "all") {
                      setSubList(["All"])
                      setNameSubList(["All"])
                      setNameGroupData(['All'])
                      setGroupData(['All'])
                      setAllGroupType("all")
                      setSecoundDropdownDisabled(true)
                      setThirdDropdownDisabled(true)
                      setThirdDropdownSelectedIds([]);
                      setDropSecondError(false)
                      setDropSecondErrorMsg("")
                      setDropThirdError(false)
                      setDropThirdErrorMsg("")

                      let allUserId = []
                      retryApiPost("adminUserEndpoints/v1/getAllUserGroups", {id:null},{
                        headers: {
                          'Authorization': props.tokenId,
                        }
                      }).then((res) => {
                        res.data.userGroupList.map((value) => {
                          const list = {
                            id: value.id
                          }
                          allUserId.push(list)
                        })
                        setFilterGroup(allUserId)
                      }).catch((error) => { })
                    } else if (String(value) === "usergroups") {
                      retryApiPost("adminUserEndpoints/v1/getAllUserGroups",{id:null}, {
                        headers: {
                          'Authorization': props.tokenId,
                        }
                      }).then((res) => {
                        setUserGroupResponse(res.data.userGroupList)
                        res.data.userGroupList.map((list) => {
                          data.push(list.descriptionName)
                        })
                        setSubList(res.data.userGroupList||[])
                        setSecoundDropdownDisabled(false)

                      }).catch((error) => { })
                    } else if (String(value) === "providers") {
                      retryApiPost("adminUserEndpoints/v1/getAllUserList",{id:null}, {
                        headers: {
                          'Authorization': props.tokenId,
                        }
                      }).then((res) => {
                        setProviderGroupResponse(res.data.userList)
                        res.data.userList.map((list) => {
                          data.push(list.userSubType)
                        })
                        setSubList(uniq([...data]))
                        setSecoundDropdownDisabled(false)

                      }).catch((error) => { })
                    }
                  }}
                  labelWidth={0}
                >
                  <MenuItem value={"all"}>All</MenuItem>
                  <MenuItem value={"usergroups"}>User Groups</MenuItem>
                  <MenuItem value={"providers"}>Providers</MenuItem>
                </Select>
                <FormHelperText>{dropFirstErrorMsg}</FormHelperText>
              </FormControl>
              </div>

            <div className={`${inputWrapper}`}>
              <div className={classes.textFieldCss}>Send to</div>

              <FormControl variant="outlined" className="" >
                <InputLabel id="demo-simple-select-outlined-label" className={dropdownPlaceholder} >Send to</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-mutiple-checkbox"
                  error={dropSecondError}
                  value={groupData}
                  multiple
                  input={<OutlinedInput labelWidth={0} />}
                  renderValue={(selected) => selected.length === 1 ? `${String(mainList)===`usergroups`?subList?.find(({id})=>id===Number(selected?.[0]))?.descriptionName:selected?.[0]}` : 'View selected'}
                  MenuProps={MenuProps}
                  onChange={alertData}
                  ref={SelectRef}
                  disabled ={secoundDropdownDisabled ? true : false}
                  IconComponent={secoundDropdownDisabled ? DownArrowDropdownDisabled : DownArrowDropdown}
                  labelWidth={0}
                >
                  {String(mainList)==='usergroups'?subList?.map(({id,descriptionName})=>(
                    <MenuItem key={id} value={id}>
                    <Checkbox checked={groupData.indexOf(id) > -1} />
                    <ListItemText primary={descriptionName} />
                  </MenuItem>
                  )) 
                  :
                  subList && subList.map((list) => (<MenuItem key={list} value={list}>
                    {list && list !== 'All' && <Checkbox checked={groupData.indexOf(list) > -1} />}
                    {list && list === 'All' && <Checkbox checked={groupData.indexOf(list) > -1} />}
                    <ListItemText primary={list} />
                  </MenuItem>))}
                </Select>
                <FormHelperText>{dropSecondErrorMsg}</FormHelperText>
              </FormControl>
            </div>

            <div className={`${inputWrapper}`}>
              <div className={classes.textFieldCss}>Send to</div>

              <FormControl variant="outlined" >
                <InputLabel id="demo-simple-select-outlined-label" className={dropdownPlaceholder} >Send to</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-mutiple-checkbox"
                  error={dropThirdError}
                  value={namegroupData}
                  multiple
                  input={<OutlinedInput labelWidth={0} />}
                  renderValue={(selected) => selected.length === 1 ? `${String(mainList)==='all'?selected?.[0]:namesubList.find(({id})=>id===Number(selected?.[0]))?.name}` : 'View selected'}
                  MenuProps={MenuProps}
                  onChange={alertNameData}
                  labelWidth={0}
                  disabled ={thirdDropdownDisabled ? true : false}
                  IconComponent={thirdDropdownDisabled ? DownArrowDropdownDisabled : DownArrowDropdown}

                >
                  {
                    String(mainList)==='all'?namesubList && namesubList.map((list) => (<MenuItem key={list} value={list}>
                      {list && list !== 'All' && <Checkbox checked={namegroupData.indexOf(list) > -1} />}
                      {list && list === 'All' && <Checkbox checked={namegroupData.indexOf(list) > -1} />}
                      <ListItemText primary={list} />
                    </MenuItem>)):namesubList.map(({name,id}) => (<MenuItem key={id} value={id}>
                  <Checkbox checked={namegroupData.indexOf(id) > -1} />
                    <ListItemText primary={name} />
                  </MenuItem>))
                  }
                </Select>
                <FormHelperText>{dropThirdErrorMsg}</FormHelperText>
              </FormControl>

              </div>

            </div>
          </div>
          <div
            style={{ display: "flex", columnGap: 10, justifyContent: "center", margin: "10px" }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setSendAlertClicked(true)
                const sanitizedHTML = sanitizeHtml(
                  message,
                  {
                    allowedTags: [],
                    allowedAttributes: {}
                  }
                );

                //const htmlObject = HTML.parse(sanitizedHTML);
                //setSystemAlert("hi")
                if (mainList.length < 1) {
                  setDropFirstErrorMsg(<span style={{ color: "#f44336" }}>Please select a values</span>)
                  setDropFirstError(true)
                }

                if (!secoundDropdownDisabled) {
                  if (groupData.length < 1) {
                    setDropSecondErrorMsg(<span style={{ color: "#f44336" }}>Please select a values</span>)
                    setDropSecondError(true)
                  }
                }
               
                if (!thirdDropdownDisabled) {
                  if (namegroupData.length < 1) {
                    setDropThirdErrorMsg(<span style={{ color: "#f44336" }}>Please select a values</span>)
                    setDropThirdError(true)
                  }
                }

                if ((/^[\s|\n]*$/.test(title) || title.length > 50) && (!hyperlinkComponentRef.current.handleSpecialChar() || hyperlinkComponentRef.current.handleSpecialChar() > 200)) {
                  //open||setOpen(true)
                  //setSystemAlert("Please enter title and message")
                  //setMessage('');
                  //setTitle('');
                  setTitleError(true);
                  setMessageError(true);
                } else if (/^[\s|\n]*$/.test(title) || title.length > 50) {
                  //open||setOpen(true)
                  //setSystemAlert("Please enter title")
                  //setTitle('');
                  setTitleError(true);
                
                } else if (!hyperlinkComponentRef.current.handleSpecialChar() || (hyperlinkComponentRef.current.handleSpecialChar() > 200)) {
                  //open||setOpen(true)
                  //setSystemAlert("Please enter message")
                  //setMessage('');
                  setMessageError(true);
                } else {
                  if (groupData.length && mainList.length && namegroupData.length) {
                    setLoading(true);
                    const linkifyMessage = linkifyHtml(message, { target: '_blank' });
                    const addTargetBlank = parse(linkifyMessage);
                    addTargetBlank.querySelectorAll('a').forEach(individualAnchor => {
                      if (!individualAnchor.hasAttribute('target')) {
                        individualAnchor.setAttribute('target', '_blank');
                      }
                    });
                    retryApiPost(
                      "/adminUserEndpoints/v1/postAlert",
                      {
                        alertTitle: title,
                        alertMsg: addTargetBlank.toString(),
                        type: allGroupType,
                        userGroupList: allGroupType === 'userGroups' ? filterGroup : allGroupType === 'all' ? filterGroup : [],
                        providerList: allGroupType === 'providers' ? filterGroup : allGroupType === 'all' ? allProvider : [],
                        msgType:"Notification",
                        selectedUserId: thirdDropdownSelectedIds ? thirdDropdownSelectedIds:[],
                        id: LoginusersID
                        // adminId: ''
                      },
                      {
                        headers: {
                          Authorization: props.tokenId,
                        }
                      }
                    )
                      .then((res) => {
                        setLoading(false);
                        setSendAlertClicked(false)
                        setSystemAlert("");
                        setGroupData([])
                        setMainList([])
                        setNameSubList([])
                        setNameGroupData([])
                        setSecoundDropdownDisabled(true)
                        setThirdDropdownDisabled(true)
                        if (res.data.status) {
                          setMessage("");
                          setTitle("");
                          //document.querySelector('div.editable').innerText=''
                          hyperlinkComponentRef.current.onReset();
                          //hyperlinkComponentRef.current.editor.setContent("");
                          //document.querySelector('div.editable').style.border = "1px solid rgba(0, 0, 0, 0.23)";
                        } else {
                          // alert(res)
                          // setSystemAlert(res)
                        }
                      })
                      .catch((error) => {
                        setLoading(false);
                        if (
                          (error &&
                            error.response &&
                            error.response.status === 703) ||
                          (error.response && error.response.status === 704)
                        ) {
                          //refreshToken();
                        } else if (
                          error &&
                          error.response &&
                          error.response.status === 504
                        ) {
                          //refreshToken();
                          setSystemAlert(
                            "Server did not response please try again..."
                          );
                        } else if (
                          error &&
                          error.response &&
                          error.response.status === 500
                        ) {
                          //refreshToken();
                          setSystemAlert("");
                        } else if (
                          error &&
                          error.response &&
                          error.response.status === 502
                        ) {
                          //refreshToken();
                        }
                        // alert(JSON.stringify(reason))
                        // setSystemAlert(JSON.stringify(reason))
                      });
                  }
                }
              }}
              disableElevation
              disabled={loading}
            >
              Send Alert
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                showAlertList(true);
              }}
            >
              View Alerts
            </Button>
          </div>
        </div>
        {expiredMsg && (
          <div>
            <ExpiredMessage
              expiredMsg={expiredMsg}
              setexpiredLogin={setexpiredLogin}
            />
          </div>
        )}
        {alertList && (
          <div style={{ width: "100%", overflow: "auto" }}>
            <AlertList
              loading={loading}
              tokenId={props.tokenId}
              refreshId={props.refreshId}
              setRefreshId={props.setRefreshId}
              setTokenId={props.setTokenId}
              setUserId={props.setUserId}
              setExpiredMsg={setExpiredMsg}
              LoginusersID={LoginusersID}
              msgType="Notification"
              tblTile="Notification List"
            />
          </div>
        )}
      </div>
      )}
      
      {statusFilter === EMAIL && (
        <div>
        {emailsystemAlert !== "" && (
          <div style={{ fontSize: "18px", color: "#fb1b1b", padding: "0 0 10px" }}>
            <Collapse in={open}>
              <Alert
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setOpen(false);
                      setMessage("");
                      setTitle("");
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                {emailsystemAlert}
              </Alert>
            </Collapse>
          </div>
        )}
        <div
          style={{
            margin: "auto",
            padding: `15px ${screenMediaQuery ? "15px" : "35px"} 25px ${screenMediaQuery ? "15px" : "35px"
              }`,
            display: "flex",
            flexDirection: "column",
            backgroundColor: "white",
            /*  maxWidth: '700px', */
          }}
        >
          {/* <div className={classes.subTitleHead}> Email</div> */}
          <div
            style={{
              display: "flex",
              backgroundColor: "white",
              ...(!screenMediaQuery && { minWidth: "400px" }),
              opacity: 2,
              flexDirection: "column",
            }}
          >


            <div className={inputWrapper}>
            <InputLabel className={classes.textFieldCss} style={{marginLeft:10}}>Subject</InputLabel>
            <TextField
            className={selectPlaceholder}
              style={{ margin: "10px" }}
              variant="outlined"
              value={subject}
              error={subjectError || subject.length >= 100}
              helperText={
                subjectError
                  ? subject.length >100 ? `${100 - subject.length} characters left` : "Please Enter subject"
                  : subjectShow
                    ? `${100 - subject.length} characters left`
                    : ""
              }
              FormHelperTextProps={{ style: { ...(!subjectError || subject.length) && { textAlign: 'right' } } }}
              onFocus={() => {
                setsubjectShow(true);
              }}
              onBlur={() => {
                setsubjectShow && setsubjectShow(false);
              }}
              onChange={(event) => {
                setsubject(event.target.value);
              }}
              placeholder="Subject"
            />
           </div>

           <div className={inputWrapper}>
            <InputLabel className={classes.textFieldCss} style={{marginLeft:10}}>Message</InputLabel>

            <HyperlinkTextInput
              ref={emailhyperlinkComponentRef}
              onChange={(value) => setHtml(value)}
              error={emailmessageError}
              helperText={emailHyperlinkTextInputErrorMessage}
              onFocus={() => {
                setEmailMessageShow(true);
              }}
              onBlur={() => {
                setEmailMessageShow && setEmailMessageShow(false);
              }}
              updateParent={(messageValue) => {
                setEmailMessage(messageValue)
              }}
              contentFixedLength={400}
              getMessage={(value)=>getMessagOnchange(value)} 
              editorMessage = {emaileditorMessage}
            />
            </div>
            <div style={{ margin: "10px", display: "flex", columnGap: "10px", justifyContent: "initial" }}>
          
            <div className={`${inputWrapper}`}>
              <div className={classes.textFieldCss}>Send to</div>
              
              <FormControl variant="outlined" >
                <InputLabel id="email-demo-simple-select-outlined-label" className={dropdownPlaceholder}>Send to</InputLabel>
                <Select
                  labelId="email-demo-simple-select-outlined-label"
                  id="email-demo-simple-select-outlined"
                  value={emailmainList}
                  error={emaildropFirstError}
                  IconComponent={DownArrowDropdown}
                  onChange={(event) => {
                    const data = []
                    const totalUser = []
                    const value = event.target.value;
                    setEmailMainList(event.target.value)
                    setEmailGroupData([])
                    setEmailUserGroupResponse()
                    setEmailProviderGroupResponse()
                    setEmailDropFirstError(false)
                    setEmailDropFirstErrorMsg("")
                    setEmailSubList([])
                    setEmailNameSubList([])
                    setEmailNameGroupData([])
                    if(sendEmailClicked){
                      if (emailgroupData.length < 1) {
                        setEmailDropSecondError(true)
                        setEmailDropSecondErrorMsg(<span style={{ color: "#f44336" }}>Please select a values</span>)
                      }
                    }
                    
                    if (String(value) === "all") {
                      setEmailSubList(["All"])
                      setEmailNameSubList(["All"])
                      setEmailNameGroupData(['All'])
                      setEmailGroupData(['All'])
                      setEmailAllGroupType("all")
                      setEmailThirdDropdownSelectedIds([]);
                      setEmailDropSecondError(false)
                      setEmailDropSecondErrorMsg("")
                      setEmailDropThirdError(false)
                      setEmailDropThirdErrorMsg("")

                      setEmailThirdDropdownDisabled(true)
                      setEmailSecoundDropdownDisabled(true)

                      let allUserId = []
                      retryApiPost("adminUserEndpoints/v1/getAllUserGroups",{id:null}, {
                        headers: {
                          'Authorization': props.tokenId,
                        }
                      }).then((res) => {
                        res.data.userGroupList.map((value) => {
                          const list = {
                            id: value.id
                          }
                          allUserId.push(list)
                        })
                        setEmailFilterGroup(allUserId)
                      }).catch((error) => { })
                    } else if (String(value) === "usergroups") {
                     
                      retryApiPost("adminUserEndpoints/v1/getAllUserGroups",{id:null}, {
                        headers: {
                          'Authorization': props.tokenId,
                        }
                      }).then((res) => {
                        setEmailUserGroupResponse(res.data.userGroupList)
                        res.data.userGroupList.map((list) => {
                          data.push(list.descriptionName)
                        })
                        setEmailSubList(res.data.userGroupList||[])
                        setEmailSecoundDropdownDisabled(false)

                      }).catch((error) => { })
                    } else if (String(value) === "providers") {
                      retryApiPost("adminUserEndpoints/v1/getAllUserList",{id:null}, {
                        headers: {
                          'Authorization': props.tokenId,
                        }
                      }).then((res) => {
                        setEmailProviderGroupResponse(res.data.userList)
                        res.data.userList.map((list) => {
                          data.push(list.userSubType)
                        })
                        setEmailSubList(uniq([...data]))
                        setEmailSecoundDropdownDisabled(false)

                      }).catch((error) => { })
                    }
                  }}
                  labelWidth={0}

                >
                  <MenuItem value={"all"}>All</MenuItem>
                  <MenuItem value={"usergroups"}>User Groups</MenuItem>
                  <MenuItem value={"providers"}>Providers</MenuItem>
                </Select>
                <FormHelperText>{emaildropFirstErrorMsg}</FormHelperText>
              </FormControl>
              </div>


              <div className={`${inputWrapper}`}>
              <div className={classes.textFieldCss}>Send to</div>

              <FormControl variant="outlined">
                <InputLabel id="demo-simple-select-outlined-label" className={dropdownPlaceholder}>Send to</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-mutiple-checkbox"
                  error={emaildropSecondError}
                  value={emailgroupData}
                  multiple
                  input={<OutlinedInput labelWidth={0} />}
                  renderValue={(selected) => selected.length === 1 ? `${String(emailmainList)===`usergroups`?emailsubList?.find(({id})=>id===Number(selected?.[0]))?.descriptionName:selected?.[0]}` : 'View selected'}
                  MenuProps={MenuProps}
                  onChange={alertEmailData}
                  disabled ={emailSecoundDropdownDisabled ? true : false}
                  IconComponent={emailSecoundDropdownDisabled ? DownArrowDropdownDisabled : DownArrowDropdown}
                  labelWidth={0}
                >
                  {String(emailmainList)==='usergroups'?emailsubList && emailsubList.map(({id,descriptionName})=><MenuItem key={id} value={id}>
                  <Checkbox checked={emailgroupData.indexOf(id) > -1} />
                    <ListItemText primary={descriptionName} />
                  </MenuItem>)
                  :emailsubList && emailsubList.map((list) => (<MenuItem key={list} value={list}>
                    {list && list !== 'All' && <Checkbox checked={emailgroupData.indexOf(list) > -1} />}
                    {list && list === 'All' && <Checkbox checked={emailgroupData.indexOf(list) > -1} />}
                    <ListItemText primary={list} />
                  </MenuItem>))}
                </Select>
                <FormHelperText>{emaildropSecondErrorMsg}</FormHelperText>
              </FormControl>
              </div>


              <div className={`${inputWrapper}`}>
              <div className={classes.textFieldCss}>Send to</div>

              <FormControl variant="outlined" >
                <InputLabel id="demo-simple-select-outlined-label" className={dropdownPlaceholder}>Send to</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-mutiple-checkbox"
                  error={emaildropThirdError}
                  value={emailnamegroupData}
                  multiple
                  input={<OutlinedInput labelWidth={0} />}
                  renderValue={(selected) => selected.length === 1 ? `${String(emailmainList)==='all'?selected?.[0]:emailnamesubList.find(({id})=>id===Number(selected?.[0]))?.name}` : 'View selected'}
                  MenuProps={MenuProps}
                  onChange={alertEmailNameData}
                  disabled ={emailThirdDropdownDisabled ? true : false}
                  IconComponent={emailThirdDropdownDisabled ? DownArrowDropdownDisabled : DownArrowDropdown}
                  labelWidth={0}

                >
                  {String(emailmainList)==='all'?emailnamesubList && emailnamesubList.map((list) => (<MenuItem key={list} value={list}>
                    {list && list !== 'All' && <Checkbox checked={emailnamegroupData.indexOf(list) > -1} />}
                    {list && list === 'All' && <Checkbox checked={emailnamegroupData.indexOf(list) > -1} />}
                    <ListItemText primary={list} />
                  </MenuItem>)):emailnamesubList && emailnamesubList.map(({id,name}) => (<MenuItem key={id} value={id}>
                    <Checkbox checked={emailnamegroupData.indexOf(id) > -1} />
                    <ListItemText primary={name} />
                  </MenuItem>))}
                </Select>
                <FormHelperText>{emaildropThirdErrorMsg}</FormHelperText>
              </FormControl>
              </div>

            </div>
          </div>
          <div
            style={{ display: "flex", columnGap: 10, justifyContent: "center", margin: "10px" }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                
                setSendEmailClicked(true)
                const sanitizedHTML = sanitizeHtml(
                  emailmessage,
                  {
                    allowedTags: [],
                    allowedAttributes: {}
                  }
                );
                //const htmlObject = HTML.parse(sanitizedHTML);
                //setSystemAlert("hi")
                if (emailmainList.length < 1) {
                  setEmailDropFirstErrorMsg(<span style={{ color: "#f44336" }}>Please select a values</span>)
                  setEmailDropFirstError(true)
                }

                if (!emailSecoundDropdownDisabled) {
                  if (emailgroupData.length < 1) {
                    setEmailDropSecondErrorMsg(<span style={{ color: "#f44336" }}>Please select a values</span>)
                    setEmailDropSecondError(true)
                  }
                }
                if (!emailThirdDropdownDisabled) {
                  
                  if (emailnamegroupData.length < 1) {
                    
                    setEmailDropThirdErrorMsg(<span style={{ color: "#f44336" }}>Please select a values</span>)
                    setEmailDropThirdError(true)
                  }
                }


                if ((/^[\s|\n]*$/.test(subject) || subject.length > 100) && (!emailhyperlinkComponentRef.current.handleSpecialChar() || emailhyperlinkComponentRef.current.handleSpecialChar() > 400)) {
                  //open||setOpen(true)
                  //setSystemAlert("Please enter subject and message")
                  //setMessage('');
                  //setsubject('');
                  setsubjectError(true);
                  setEmailMessageError(true);
                } else if (/^[\s|\n]*$/.test(subject) || subject.length > 100) {
                  //open||setOpen(true)
                  //setSystemAlert("Please enter subject")
                  //setsubject('');
                  setsubjectError(true);
                } else if (!emailhyperlinkComponentRef.current.handleSpecialChar() || (emailhyperlinkComponentRef.current.handleSpecialChar() > 400)) {
                  //open||setOpen(true)
                  //setSystemAlert("Please enter message")
                  //setMessage('');
                  setEmailMessageError(true);
                } else {
                  if (emailgroupData.length && emailmainList.length && emailnamegroupData.length) {
                    setEmailLoading(true);
                    const linkifyMessage = linkifyHtml(emailmessage, { target: '_blank' });
                    const addTargetBlank = parse(linkifyMessage);
                    addTargetBlank.querySelectorAll('a').forEach(individualAnchor => {
                      if (!individualAnchor.hasAttribute('target')) {
                        individualAnchor.setAttribute('target', '_blank');
                      }
                    });
                    retryApiPost(
                      "/adminUserEndpoints/v1/postAlert",
                      {
                        alertTitle: subject,
                        alertMsg: addTargetBlank.toString(),
                        type: emailallGroupType,
                        userGroupList: emailallGroupType === 'userGroups' ? emailfilterGroup : emailallGroupType === 'all' ? emailfilterGroup : [],
                        providerList: emailallGroupType === 'providers' ? emailfilterGroup : emailallGroupType === 'all' ? allProvider : [],
                        msgType:"Email",
                        selectedUserId: emailthirdDropdownSelectedIds ? emailthirdDropdownSelectedIds:[],
                        id: LoginusersID
                        // adminId: ''
                      },
                      {
                        headers: {
                          Authorization: props.tokenId,
                        }
                      }
                    ).then((res) => {
                      
                        setEmailLoading(false);
                        setSendEmailClicked(false)
                        setEmailSystemAlert("");
                        setEmailGroupData([])
                        setEmailMainList([])
                        setEmailNameSubList([])
                        setEmailNameGroupData([])
                        setEmailThirdDropdownDisabled(true)
                        setEmailSecoundDropdownDisabled(true)
                        if (res.data.status) {
                          setEmailMessage("");
                          setsubject("");
                          //document.querySelector('div.editable').innerText=''
                          emailhyperlinkComponentRef.current.onReset();
                          //hyperlinkComponentRef.current.editor.setContent("");
                          //document.querySelector('div.editable').style.border = "1px solid rgba(0, 0, 0, 0.23)";
                        } else {
                          // alert(res)
                          // setSystemAlert(res)
                        }
                      })
                      .catch((error) => {
                        setEmailLoading(false);
                        if (
                          (error &&
                            error.response &&
                            error.response.status === 703) ||
                          (error.response && error.response.status === 704)
                        ) {
                          //refreshToken();
                        } else if (
                          error &&
                          error.response &&
                          error.response.status === 504
                        ) {
                          //refreshToken();
                          setEmailSystemAlert(
                            "Server did not response please try again..."
                          );
                        } else if (
                          error &&
                          error.response &&
                          error.response.status === 500
                        ) {
                          //refreshToken();
                          setEmailSystemAlert("");
                        } else if (
                          error &&
                          error.response &&
                          error.response.status === 502
                        ) {
                          //refreshToken();
                        }
                        // alert(JSON.stringify(reason))
                        // setSystemAlert(JSON.stringify(reason))
                      });
                  }
                }
              }}
              disableElevation
              disabled={emailloading}
            >
        
              Send Email
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                showEmailAlertList(true);
              }}
            >
              View Emails
            </Button>
          </div>
        </div>
        {emailexpiredMsg && (
          <div>
            <ExpiredMessage
              expiredMsg={emailexpiredMsg}
              setexpiredLogin={setEmailexpiredLogin}
            />
          </div>
        )}

        {emailalertList && (
          <div style={{ width: "100%", overflow: "auto" }}>
            <AlertList
              loading={emailloading}
              tokenId={props.tokenId}
              refreshId={props.refreshId}
              setRefreshId={props.setRefreshId}
              setTokenId={props.setTokenId}
              setUserId={props.setUserId}
              setExpiredMsg={setEmailExpiredMsg}
              LoginusersID={LoginusersID}
              msgType="Email"
              tblTile="Email List"
            />
          </div>
        )}
      </div>
      )}

      {statusFilter === SMS && (
        <div>
        {smssystemAlert !== "" && (
          <div style={{ fontSize: "18px", color: "#fb1b1b", padding: "0 0 10px" }}>
            <Collapse in={open}>
              <Alert
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setOpen(false);
                      setMessage("");
                      setTitle("");
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                {smssystemAlert}
              </Alert>
            </Collapse>
          </div>
        )}
        <div
          style={{
            margin: "auto",
            padding: `15px ${screenMediaQuery ? "15px" : "35px"} 25px ${screenMediaQuery ? "15px" : "35px"
              }`,
            display: "flex",
            flexDirection: "column",
            backgroundColor: "white",
            /*  maxWidth: '700px', */
          }}
        >
          {/* <div className={classes.subTitleHead}> SMS</div> */}
          <div
            style={{
              display: "flex",
              backgroundColor: "white",
              ...(!screenMediaQuery && { minWidth: "400px" }),
              opacity: 2,
              flexDirection: "column",
            }}
          >
           

           <div className={inputWrapper}>
            <InputLabel className={classes.textFieldCss} style={{marginLeft:10}}>Message</InputLabel>
            <HyperlinkTextInput
              ref={smshyperlinkComponentRef}
              onChange={(value) => setHtml(value)}
              error={smsmessageError}
              helperText={smsHyperlinkTextInputErrorMessage}
              onFocus={() => {
                setSmsMessageShow(true);
              }}
              onBlur={() => {
                setSmsMessageShow && setSmsMessageShow(false);
              }}
              updateParent={(messageValue) => {
                setSmsMessage(messageValue)
              }}
              contentFixedLength={120}
              getMessage={(value)=>getMessagOnchange(value)} 
              editorMessage = {smseditorMessage}


            />

</div>

            <div style={{ margin: "10px", display: "flex", columnGap: "10px", justifyContent: "initial" }}>
             
            <div className={`${inputWrapper}`}>
              <div className={classes.textFieldCss}>Send to</div>
             
              <FormControl variant="outlined" >
                <InputLabel id="sms-demo-simple-select-outlined-label" className={dropdownPlaceholder}>Send to</InputLabel>
                <Select
                  labelId="sms-demo-simple-select-outlined-label"
                  id="sms-demo-simple-select-outlined"
                  value={smsmainList}
                  error={smsdropFirstError}
                  IconComponent={DownArrowDropdown}

                  onChange={(event) => {
                    const data = []
                    const totalUser = []
                    const value = event.target.value;
                    setSmsMainList(event.target.value)
                    setSmsGroupData([])
                    setSmsUserGroupResponse()
                    setSmsProviderGroupResponse()
                    setSmsDropFirstError(false)
                    setSmsDropFirstErrorMsg("")
                    setSmsSubList([])
                    setSmsNameSubList([])
                    setSmsNameGroupData([])
                    if(sendSmsClicked){
                    if (smsgroupData.length < 1) {
                      setSmsDropSecondError(true)
                      setSmsDropSecondErrorMsg(<span style={{ color: "#f44336" }}>Please select a values</span>)
                    }
                    
                  }

                    if (String(value) === "all") {
                      setSmsSubList(["All"])
                      setSmsNameSubList(["All"])
                      setSmsNameGroupData(['All'])
                      setSmsGroupData(['All'])
                      setSmsAllGroupType("all")
                      setSmsThirdDropdownSelectedIds([]);

                      setSmsDropSecondError(false)
                      setSmsDropSecondErrorMsg("")
                      setSmsDropThirdError(false)
                      setSmsDropThirdErrorMsg("")

                      setSmsSecoundDropdownDisabled(true)
                      setSmsThirdDropdownDisabled(true)


                      let allUserId = []
                      retryApiPost("adminUserEndpoints/v1/getAllUserGroups",{id:null}, {
                        headers: {
                          'Authorization': props.tokenId,
                        }
                      }).then((res) => {
                        res.data.userGroupList.map((value) => {
                          const list = {
                            id: value.id
                          }
                          allUserId.push(list)
                        })
                        setSmsFilterGroup(allUserId)
                      }).catch((error) => { })
                    } else if (String(value) === "usergroups") {
                      retryApiPost("adminUserEndpoints/v1/getAllUserGroups",{id:null}, {
                        headers: {
                          'Authorization': props.tokenId,
                        }
                      }).then((res) => {
                        setSmsUserGroupResponse(res.data.userGroupList)
                        res.data.userGroupList.map((list) => {
                          data.push(list.descriptionName)
                        })
                        setSmsSubList(res.data.userGroupList||[])
                        setSmsSecoundDropdownDisabled(false)

                      }).catch((error) => { })
                    } else if (String(value) === "providers") {
                      retryApiPost("adminUserEndpoints/v1/getAllUserList",{id:null}, {
                        headers: {
                          'Authorization': props.tokenId,
                        }
                      }).then((res) => {
                        setSmsProviderGroupResponse(res.data.userList)
                        res.data.userList.map((list) => {
                          data.push(list.userSubType)
                        })
                        setSmsSubList(uniq([...data]))
                        setSmsSecoundDropdownDisabled(false)

                      }).catch((error) => { })
                    }
                  }}
                  labelWidth={0}
                >
                  <MenuItem value={"all"}>All</MenuItem>
                  <MenuItem value={"usergroups"}>User Groups</MenuItem>
                  <MenuItem value={"providers"}>Providers</MenuItem>
                </Select>
                <FormHelperText>{smsdropFirstErrorMsg}</FormHelperText>
              </FormControl>
                  </div>


                  <div className={`${inputWrapper}`}>
              <div className={classes.textFieldCss}>Send to</div>
              <FormControl variant="outlined" >
                <InputLabel id="demo-simple-select-outlined-label" className={dropdownPlaceholder}>Send to</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-mutiple-checkbox"
                  error={smsdropSecondError}
                  value={smsgroupData}
                  multiple
                  input={<OutlinedInput labelWidth={0} />}
                  renderValue={(selected) => selected.length === 1 ? `${String(smsmainList)===`usergroups`?smssubList?.find(({id})=>id===Number(selected?.[0]))?.descriptionName:selected?.[0]}` : 'View selected'}
                  MenuProps={MenuProps}
                  onChange={alertSmsData}
                  disabled ={smsSecoundDropdownDisabled ? true : false}
                  labelWidth={0}
                  IconComponent={smsSecoundDropdownDisabled ? DownArrowDropdownDisabled : DownArrowDropdown}

               >
                  {String(smsmainList)==='usergroups'?smssubList && smssubList.map(({id,descriptionName}) => (<MenuItem key={id} value={id}>
                    <Checkbox checked={smsgroupData.indexOf(id) > -1} />
                    <ListItemText primary={descriptionName} />
                  </MenuItem>)):smssubList && smssubList.map((list) => (<MenuItem key={list} value={list}>
                    {list && list !== 'All' && <Checkbox checked={smsgroupData.indexOf(list) > -1} />}
                    {list && list === 'All' && <Checkbox checked={smsgroupData.indexOf(list) > -1} />}
                    <ListItemText primary={list} />
                  </MenuItem>))}
                </Select>
                <FormHelperText>{smsdropSecondErrorMsg}</FormHelperText>
              </FormControl>
              </div>


              <div className={`${inputWrapper}`}>
              <div className={classes.textFieldCss}>Send to</div>

              <FormControl variant="outlined" >
                <InputLabel id="demo-simple-select-outlined-label" className={dropdownPlaceholder}>Send to</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-mutiple-checkbox"
                  error={smsdropThirdError}
                  value={smsnamegroupData}
                  multiple
                  input={<OutlinedInput labelWidth={0} />}
                  renderValue={(selected) => selected.length === 1 ? `${String(smsmainList)==='all'?selected?.[0]:smsnamesubList.find(({id})=>id===Number(selected?.[0]))?.name}` : 'View selected'}
                  MenuProps={MenuProps}
                  onChange={alertSmsNameData}
                  label="Send to"
                  labelWidth={0}
                  disabled ={smsThirdDropdownDisabled ? true : false}
                  IconComponent={smsThirdDropdownDisabled ? DownArrowDropdownDisabled : DownArrowDropdown}
                >
                  {String(smsmainList)==='all'?smsnamesubList && smsnamesubList.map((list) => (<MenuItem key={list} value={list}>
                    {list && list !== 'All' && <Checkbox checked={smsnamegroupData.indexOf(list) > -1} />}
                    {list && list === 'All' && <Checkbox checked={smsnamegroupData.indexOf(list) > -1} />}
                    <ListItemText primary={list} />
                  </MenuItem>)):smsnamesubList && smsnamesubList.map(({name,id}) => (<MenuItem key={id} value={id}>
                 <Checkbox checked={smsnamegroupData.indexOf(id) > -1} />
                    <ListItemText primary={name} />
                  </MenuItem>))}
                </Select>
                <FormHelperText>{smsdropThirdErrorMsg}</FormHelperText>
              </FormControl>
              </div>

            </div>
          </div>
          <div
            style={{ display: "flex", columnGap: 10, justifyContent: "center", margin: "10px" }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setSendSmsClicked(true)
                const sanitizedHTML = sanitizeHtml(
                  smsmessage,
                  {
                    allowedTags: [],
                    allowedAttributes: {}
                  }
                );
                if (smsmainList.length < 1) {
                  setSmsDropFirstErrorMsg(<span style={{ color: "#f44336" }}>Please select a values</span>)
                  setSmsDropFirstError(true)
                }
                
                if (!smsSecoundDropdownDisabled) {
                  if (smsgroupData.length < 1) {
                    setSmsDropSecondErrorMsg(<span style={{ color: "#f44336" }}>Please select a values</span>)
                    setSmsDropSecondError(true)
                  }
                }

                if (!smsThirdDropdownDisabled) {
                  if (smsnamegroupData.length < 1) {
                    setSmsDropThirdErrorMsg(<span style={{ color: "#f44336" }}>Please select a values</span>)
                    setSmsDropThirdError(true)
                  }
                }

                 // smshyperlinkComponentRef.current.handleSpecialChar()

                if ((/^[\s|\n]*$/.test(subject) || subject.length > 50) && (!smshyperlinkComponentRef.current.handleSpecialChar() || smshyperlinkComponentRef.current.handleSpecialChar() > 200)) {
                  setSmsMessageError(true);
                }else if (!smshyperlinkComponentRef.current.handleSpecialChar() || (smshyperlinkComponentRef.current.handleSpecialChar() > 120)) {
                  setSmsMessageError(true);
                } else {
                  if (smsgroupData.length && smsmainList.length && smsnamegroupData.length) {
                    setSmsLoading(true);
                    const linkifyMessage = linkifyHtml(smsmessage, { target: '_blank' });
                    const addTargetBlank = parse(linkifyMessage);
                    addTargetBlank.querySelectorAll('a').forEach(individualAnchor => {
                      if (!individualAnchor.hasAttribute('target')) {
                        individualAnchor.setAttribute('target', '_blank');
                      }
                    });
                    retryApiPost(
                      "/adminUserEndpoints/v1/postAlert",
                      {
                        alertTitle: subject,
                        // alertMsg: addTargetBlank.toString(),
                        alertMsg:sanitizedHTML,
                        type: smsallGroupType,
                        userGroupList: smsallGroupType === 'userGroups' ? smsfilterGroup : smsallGroupType === 'all' ? smsfilterGroup : [],
                        providerList: smsallGroupType === 'providers' ? smsfilterGroup : smsallGroupType === 'all' ? allProvider : [],
                        msgType:"Sms",
                        selectedUserId: smsthirdDropdownSelectedIds ? smsthirdDropdownSelectedIds:[],
                        id: LoginusersID
                      },
                      {
                        headers: {
                          Authorization: props.tokenId,
                        }
                      }
                    ).then((res) => {
                      
                        setSmsLoading(false);
                        setSendSmsClicked(false)
                        setSmsSystemAlert("");
                        setSmsGroupData([])
                        setSmsMainList([])
                        setSmsNameSubList([])
                        setSmsNameGroupData([])
                        setSmsSecoundDropdownDisabled(true)
                        setSmsThirdDropdownDisabled(true)
                        if (res.data.status) {
                          setSmsMessage("");
                          setsubject("");
                          smshyperlinkComponentRef.current.onReset();
                        } 
                      })
                      .catch((error) => {
                        
                        if (
                          (error &&
                            error.response &&
                            error.response.status === 703) ||
                          (error.response && error.response.status === 704)
                        ) {
                          //refreshToken();
                        } else if (
                          error &&
                          error.response &&
                          error.response.status === 504
                        ) {
                          //refreshToken();
                          setSmsSystemAlert(
                            "Server did not response please try again..."
                          );
                        } else if (
                          error &&
                          error.response &&
                          error.response.status === 500
                        ) {
                          //refreshToken();
                          setSmsSystemAlert("");
                        } else if (
                          error &&
                          error.response &&
                          error.response.status === 502
                        ) {
                          //refreshToken();
                        }
                      });
                  }
                }
              }}
              disableElevation
              disabled={smsloading}
            >
        
              Send SMS
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                showSmsAlertList(true);
              }}
            >
              View SMS
            </Button>
          </div>
        </div>
        {smsexpiredMsg && (
          <div>
            <ExpiredMessage
              expiredMsg={smsexpiredMsg}
              setexpiredLogin={setSmsexpiredLogin}
            />
          </div>
        )}

        {smsalertList && (
          <div style={{ width: "100%", overflow: "auto" }}>
            <AlertList
              loading={smsloading}
              tokenId={props.tokenId}
              refreshId={props.refreshId}
              setRefreshId={props.setRefreshId}
              setTokenId={props.setTokenId}
              setUserId={props.setUserId}
              setExpiredMsg={setSmsExpiredMsg}
              LoginusersID={LoginusersID}
              msgType="Sms"
              tblTile="SMS List"
            />
          </div>
        )}
      </div>
      )}

    </div>
    </ThemeProvider>


  );
};
export default SystemAlertScreen;