import React, {useState} from "react";
let passwordValidator = require('password-validator');
export const PassValidation=(res)=>{
    let schema = new passwordValidator();
    schema.is().min(8).is().max(16).has().uppercase().has().lowercase().has().digits().has().symbols();
    const value = schema.validate(res)
    return value
}
export const Lowercase=(res)=>{
    var value = new passwordValidator();
    value.has().lowercase(8);
    const lower = value.validate(res)
    return lower
}
export const Uppercase=(res)=>{
    var value = new passwordValidator();
    value.has().uppercase();
    const upper = value.validate(res)
    return upper
}
export const Symbols=(res)=>{
    var value = new passwordValidator();
    value.has().symbols();
    const special = value.validate(res)
    return special
}
export const SpecialNumber=(res)=>{
    var value = new passwordValidator();
    value.not().symbols().not().digits();
    const replace = value.validate(res)
    return replace
}
//export default PassValidation;