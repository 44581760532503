import React, { useEffect, useState } from "react";
import {useHistory, useLocation,  Link as NavigationLink} from "react-router-dom";
import {TextField, Button, Select, MenuItem, FormControl, Link, InputAdornment, InputLabel} from "@material-ui/core";
import API from "../../../api";
import Alert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Collapse from "@material-ui/core/Collapse";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import "./LoginScreen.css";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import ExpiredMessage from "../../common/ExpiredMessage";
import LoginLoader from "../../common/LoginLoader";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import axios from 'axios';
import logo from "../../../../src/assets/images/Logo.png";
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/Info';
import CheckIcon from '@material-ui/icons/Check';
import Colors  from "../../dashboard/color";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import CheckCircle from "@material-ui/icons/CheckCircle";
import ElipsOne from "../../../assets/images/Ellipse 1.png";
import ElipsTwo from "../../../assets/images/Ellipse 2.png";
import ElipsThree from "../../../assets/images/Ellipse 3.png";
import Vector from "../../../assets/images/Vector.png";
import {firebaseId,usersID,usersLoginToken} from 'App';
import {useRecoilValue,useSetRecoilState} from 'recoil';
import {assocPath} from 'ramda';
import OtpInput from "react-otp-input";
import moment from 'moment';
import EmailValidator from 'email-validator';
import {PassValidation,Lowercase,Uppercase} from "../../common/PasswordValidation";
const useStyles = makeStyles({
  root:{
    paddingRight: '0px !important',
    borderRadius: '8px !important'
  }
});
const theme = createMuiTheme({
    MuiTextField:{
      root:{
        border: '1px solid #5E6C84',
        borderRadius: '10px'
      }
    },
   /*  MuiTypography:{
      colorPrimary:{
        color: '#344563'
      }
    }, */
    MuiIconButton:{
      root:{
        color:'#9B51E0'
      }
    },
    MuiTypography: {
      body1: {
        fontSize: '12px',
        color: '#172B4D',
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: '500',
      }
    },
    MuiLink:{
      root:{
        color:'#344563',
        textDecoration: Colors.lightBlue
      },
      underlineHover:{
        textDecoration: Colors.lightBlue
      }
    },
    overrides:{
      MuiRadio: {
        root:{
          color:"#9B51E0",
        },
        colorSecondary: {
          '&$checked': {
            color: '#9B51E0',
          },
        },
      },
      MuiIconButton:{
        root:{
          padding: "12px 5px"
        }
      }
    }
})
const ForgotPasswordScreen = (props) =>{
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [errorPhoneNumber, setErrorPhoneNumber] = useState(false);
  const [errorMsgPhoneNumber, setErrorMsgPhoneNumber] = useState("")
  const [phoneNumberValidation, setPhoneNumberValidation] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [otp, setOtp] = useState("");
  const [generateOtpStatus, setGenerateOtpStatus] = useState(true)
  const [submitOtpStatus, setSubmitOtpStatus] = useState(false)
  const [changePasswordStatus, setChangePasswordStatus] = useState(false)
  const [successMsg, setSuccessMsg] = useState(false)
  const [open, setOpen] = useState(true);
  const [loginAlert, setLoginAlert] = useState("");
  const mobileScreen = useMediaQuery('(max-width:636px)');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [errorMsgEmail, setErrorMsgEmail] = useState("")
  const [errorMsgOtp, setErrorMsgOtp] = useState("")
  const [errorMsg, setErrorMsg] = useState("")
  const history = useHistory();
  const [expiredMsg, setExpiredMsg] = useState(false);
  const [expiredLogin, setexpiredLogin] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const handleClickConfimShowPassword = () => setShowConfirmPassword(!showConfirmPassword);
  const handleMouseDownConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);
  const [errorEmail, setErrorEmail] = useState(false);
  const [emailValidation, setEmailValidation] = useState(false);
  const classes = useStyles();
  const [value, setValue] = useState("email");
  const [passwordValidation, setPasswordValidation] = useState(false);
  const [confirmPasswordValidation, setConfirmPasswordValidation] = useState(false);
  const [lowerCase, setLowerCase] = useState(false);
  const [number, setNumber] = useState(false);
  const [upperCase, setUpperCase] = useState(false);
  const [specialChar, setSpecialChar] = useState(false);
  const [minimumChar, setMinimumChar] = useState(false);
  const [confirmLowerCase, setConfirmLowerCase] = useState(false);
  const [confirmNumber, setConfirmNumber] = useState(false);
  const [confirmUpperCase, setConfirmUpperCase] = useState(false);
  const [confirmSpecialChar, setConfirmSpecialChar] = useState(false);
  const [confirmMinimumChar, setConfirmMinimumChar] = useState(false);
  const [enableInfo, setEnableInfo] = useState(false);
  const [confirmEnableInfo, setConfirmEnableInfo] = useState(false);
  const [infoTicEnableDisable, setInfoTicEnableDisable] = useState(false);
  const [confirmInfoTicEnableDisable, setConfirmInfoTicEnableDisable] = useState(false);
  const [errorPassword, setErrorPassword] = useState(false);
  const [errorMsgPassword, setErrorMsgPassword] = useState("");
  const [errorConfirmPassword, setErrorConfirmPassword] = useState(false);
  const [errorMsgConfirmPassword, setErrorMsgConfirmPassword] = useState("");
  const [tooltipNew, setTooltipNew] = useState(false);
  const [tooltipConfirm, setTooltipConfirm] = useState(false);
  const [radioButton, setRadioButton] = useState(true);
  const [code, setCode] = useState(+1);
  const [buttonStatus, setButtonStatus] = useState(true);
  const [secondsValue,setSecondsValue] = useState(25);
  const firebaseuid=useRecoilValue(firebaseId);
  const usersuid = useRecoilValue(usersID);
  const [retryOTP,setRetryOTP] = useState(true);
  const [passwordToken, setPasswordToken] = useState("");
  const [verifyOtp, setVerifyOtp] = useState(false);
  const [userId,setUserId] = useState();
  const [backspaceDelete, setBackspaceDelete] = useState(false);
  const [otpStatus, setOtpStatus] = useState(false);
  const [adminId, setAdminId]=useState('')
  const setLogoutUserToken = useSetRecoilState(usersLoginToken)  
  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: '#ffffff',
      color: 'rgba(0, 0, 0, 0.87)',
     /*  maxWidth: 250, */
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
      minWidth: 200
    },
  }))(Tooltip);
  
  const handleTooltipClose = () => {
    setTooltipNew(false)
    setTooltipConfirm(false)
  };
  const retryApiGet = (url,header)=>{
    return API.get(url,header).catch(function(error){if([401,500,502,504,703,704].includes(error?.response?.status)){
      return new Promise(resolve=>{
        API.post("/userEndpoints/v1/renewIdToken",{
          "token": props.refreshId
        })
        .then((res)=>{
          props.setTokenId(res.data.idToken)
          if(!res.data.idToken){
            setExpiredMsg(true)
            if(expiredLogin){
              props.setUserId(null)
              history.push("/")
            }
            throw error
          }else{
            resolve(API.get(url,{headers:{
              Authorization:res.data.idToken
            }}))
          }
        })
        .catch((error) =>{Promise.reject(error)})
      })
    }})
  }
  
  const retryApiPost = (url,data,header)=>{
    return API.post(url,{...data,id:usersuid},assocPath(['headers','uid'],firebaseuid,header)).then((res)=>{
      if(res?.headers?.userid===String(usersuid)||url.startsWith('/userEndpoints')){
        return Promise.resolve(res)
      }else{
        throw {message:"Invalid Response"}
        //return Promise.reject(new Error({message:'invalid access'}))
      }
    }).catch(function(error){if([401,500,502,504,703,704].includes(error?.response?.status)){
      return new Promise(resolve=>{
        API.post("/userEndpoints/v1/renewIdToken",{
          "token": props.refreshId
        })
        .then((res)=>{
          props.setTokenId(res.data.idToken)
          if(!res.data.idToken){
            setExpiredMsg(true)
            if(expiredLogin){
              props.setUserId(null)
              history.push("/")
            }
            throw error
          }else{
            resolve(API.post(url,{...data,id:usersuid},{headers:{
              Authorization:res.data.idToken,
              uid:firebaseuid
            }}).then((res)=>{
              if(res?.headers?.userid===String(usersuid)||url.startsWith('/userEndpoints')){
                return Promise.resolve(res)
              }else{
                throw {message:"Invalid Response"}
                //return Promise.reject(new Error({message:'invalid access'}))
              }
            }))
          }
        })
        .catch((error) =>{Promise.reject(error)})
      })
    }else{
      throw error
    }})
  }

  const refreshToken = () => {
    API.post("/userEndpoints/v1/renewIdToken",{
      "token": props.refreshId
    })
    .then((res)=>{
      props.setTokenId(res.data.idToken)
     if(!res.data.idToken){
        setExpiredMsg(true)
        if(expiredLogin){
          props.setUserId(null)
          history.push("/")
        }
      }
    })
    .catch((error) =>{})
  }
  useEffect(() =>{
    if(expiredLogin){
      props.setUserId(null)
      history.push("/")
    }
  },[expiredLogin])

  useEffect(()=>{
    if(submitOtpStatus){
    const timerID = window.setInterval(()=>{
      setSecondsValue(secondsVal=>{if(secondsVal){return secondsVal-1}clearInterval(timerID);return secondsVal})
    },1000)
    return ()=>{
      clearInterval(timerID)
    }}
  },[submitOtpStatus, secondsValue])

  useEffect(()=>{
    if(!retryOTP && secondsValue<1){
      setRetryOTP(true)
    }
  })
  useEffect(()=>{
    if(props.loginUserEmail&&props.passwordExpired){
      setEmail(props.loginUserEmail)
    }
  },[props.loginUserEmail,props.passwordExpired])
// useEffect(()=>{
//   const refreshAuthLogic = failedRequest =>
//   API.post("/userEndpoints/v1/renewIdToken",{   
//     "token": props.refreshId
//   })
//   .then((res)=>{
//     props.setTokenId(res.data.idToken)
//     if(!res.data.idToken){
//        setExpiredMsg(true)
//        if(expiredLogin){
//          props.setUserId(null)
//          history.push("/")
//          return Promise.reject();
//        }
//      }        
//     failedRequest.response.config.headers["Authorization"] = res.data.idToken;
//   return Promise.resolve();
//   })
//   .catch((error) =>{
//     console.log("refresh fail");
//     return Promise.reject(error);
//   })
//   createAuthRefreshInterceptor(API, refreshAuthLogic,{statusCodes:[401,500,502,504,703,704]});
// },[])

  const generateotp = () => {
    setOpen(false);
    //const mail ="^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$"
    if((email !== '' && EmailValidator.validate(email)) || phoneNumber){
      setLoading(true);
      setErrorMsgEmail("")
      const number = phoneNumber.replace(/-/g,"");
      retryApiPost("/userEndpoints/v1/forgot/password",{
        "email": email,
        "countryCode": number?'+' + code : null,
        "phoneNumber": number?number : null
      },null)
      .then((res) => {
        setLoading(false);
        if(res.data && res.data.status !== false){
          setGenerateOtpStatus(false)
          setSubmitOtpStatus(true)
          setButtonStatus(true)
        }else {
          // setErrorMsgPhoneNumber(<span style={{fontSize:"10px", color:"red"}}>{res.data.errorMessage}</span>
          setLoginAlert(res.data.errorMessage)
          setOpen(true);
        }
      })
      .catch((error) =>{
        setLoading(false);
      /*  refreshToken(); */
      if ((error.response && error.response.status === 703) ||(error.response && error.response.status === 704)) {
        //refreshToken();
      } else if(error.response && error.response.status === 504){
        //refreshToken();
        setLoginAlert('Server did not response please try again...')
      } else if(error.response && error.response.status === 500){
        //refreshToken();
        setLoginAlert('')
      } else if(error.response && error.response.status === 502){
        //refreshToken();
      }
      });
    } else{
      setErrorMsgEmail(<span style={{fontSize:"10px", color:"red"}}>Enter Valid Email</span>)
    }
  }
  const retryOTPFunc = () => {
    setLoading(true);
    if(email){
      retryApiPost("/userEndpoints/v1/registrationEmailOTP",{email},null).then(res=>{
        setLoading(false)
      }).catch((error)=>{
        setLoading(false)
      })
    }
    else if(phoneNumber){
      const number=phoneNumber.replace(/-/g,"")
      retryApiPost("/userEndpoints/v1/registrationPhoneOTP",{
        "code": number?'+' + code : null,
        "phoneNumber": number?number : null
      },null).then(res=>{
        setLoading(false)
      }).catch((error)=>{
        setLoading(false)
      })
    }
  }
  const submitotp = () => {
    setOpen(false);
    setVerifyOtp(true)
   // const mail ="^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$"
      setErrorMsgEmail("")
      setErrorMsgOtp("")
      setLoginAlert("")
      const number = phoneNumber.replace(/-/g,"");
    if(otp !== '' && otp.match(/^[0-9]\d{0,3}$/)){
      setErrorMsgOtp("")
      if(email !== '' && EmailValidator.validate(email)){
        setLoading(true);
        retryApiPost("/userEndpoints/v1/forgot/password/otp/verify",{
          "email": email,
          "otp":otp,
        },null)
        .then((res) => {
          setLoading(false);
          setOtp("")
          setUserId(res?.data?.user?.id)
          res.data.user && setPasswordToken(res.data.user.token)
          if(res.data.status && res.data.status !== false){
            setSubmitOtpStatus(false)
            setChangePasswordStatus(true)
            setButtonStatus(true)
          } else{
            setErrorMsgOtp(<span style={{fontSize:"10px", color:"red"}}>{res.data.errorMessage}</span>)
            setLoginAlert(res.data.errorMessage)
            setOpen(true);
          }
        })
        .catch((error) => {
          setLoading(false);
          setOtp("")
          if (error&&(error.response && error.response.status === 703) ||(error.response && error.response.status === 704)) {
            //refreshToken();
          } else if(error&&error.response && error.response.status === 504){
            //refreshToken();
            setLoginAlert('Server did not response please try again')
          } else if(error&&error.response && error.response.status === 500){
            //refreshToken();
            setLoginAlert('')
          } else if(error&&error.response && error.response.status === 502){
            //refreshToken();
          }
        /*  refreshToken(); */
        })
      } else{
        setErrorMsgEmail(<span style={{fontSize:"10px", color:"red"}}>Enter Valid Email</span>)
      }

      if(phoneNumber !== ''){
        setLoading(true);
        retryApiPost("/userEndpoints/v1/forgot/password/otp/verify",{
          "otp":otp,
          "countryCode": '+' + code,
          "phoneNumber": number
        },null)
        .then((res) => {
          setAdminId(res.data.user.id)
          setLoading(false);
          res.data.user && setPasswordToken(res.data.user.token)
          setUserId(res?.data?.user?.id)
          if(res.data.status && res.data.status !== false){
            setSubmitOtpStatus(false)
            setChangePasswordStatus(true)
            setButtonStatus(true)
          } else{
            setErrorMsgOtp(<span style={{fontSize:"10px", color:"red"}}>{res.data.errorMessage}</span>)
            setLoginAlert(res.data.errorMessage)
            setOpen(true);
          }
        })
        .catch((error) => {
          setLoading(false);
          if (error&&(error.response && error.response.status === 703) ||(error.response && error.response.status === 704)) {
            //refreshToken();
          } else if(error&&error.response && error.response.status === 504){
            //refreshToken();
            setLoginAlert('Server did not response please try again')
          } else if(error&&error.response && error.response.status === 500){
            //refreshToken();
            setLoginAlert('')
          } else if(error&&error.response && error.response.status === 502){
            //refreshToken();
          }
        /*  refreshToken(); */
        })
      } else{
        setErrorMsgEmail(<span style={{fontSize:"10px", color:"red"}}>Enter Valid phoneNumber</span>)
      }
    } else{
      setErrorMsgOtp(<span style={{fontSize:"10px", color:"red"}}>Enter Valid Otp and maximum four digits</span>)
    }
  }
  const changePassword = () => {
    setErrorMsgEmail("")
    setErrorMsg("")
      //setErrorMsgEmail("")
      if(newPassword === confirmPassword){
        setLoading(true);
        setErrorMsg("")
        setSuccessMsg(false)
        retryApiPost("/userEndpoints/v1/set/new/password",{
          ...email&&{email},
          ...phoneNumber&&{phoneNumber:phoneNumber.replace(/-/g,""),countryCode: '+' + code},
          id:adminId,
          "passwordNew": newPassword,
          "token": props.passwordExpiredToken?props.passwordExpiredToken : passwordToken
        },null)
        // console.log(adminId,"id")
        .then((res)=>{
          if(res?.data?.status){
            setChangePasswordStatus(false)
            setSuccessMsg(true)
            setLoading(false);
            setButtonStatus(true)
            props.setPasswordExpired(false)
            if(passwordToken === null || passwordToken === ''){
              history.push("/")
            }
          }else{
            setLoginAlert(res?.data?.errorMessage)
            setOpen(true)
          }
        })
        .catch((error)=>{
          setLoading(false);
          /* refreshToken(); */
          if (error&&(error.response && error.response.status === 703) ||(error.response && error.response.status === 704)) {
            //refreshToken();
          } else if(error&&error.response && error.response.status === 504){
            //refreshToken();
            setLoginAlert('Server did not response please try again...')
          } else if(error&&error.response && error.response.status === 500){
            //refreshToken();
            setLoginAlert('')
          } else if(error&&error.response && error.response.status === 502){
            //refreshToken();
          }
        })
      }else {
        /* setErrorMsg(<span style={{fontSize:"10px", color:"red"}}>Minimum eight characters, at least one uppercase letter, <br/> one lowercase letter, one number and one special character</span>) */
      }
  }
  const login = () =>{
    setSuccessMsg(false)
    history.push("/login")
    setLogoutUserToken(null)
    props.setPasswordExpired(false)
  }
  const handleChange = (event) => {
    setValue(event.target.value);
    if(event.target.value === 'email' && emailValidation){
      setButtonStatus(false)
    }else if(event.target.value === 'number' && phoneNumberValidation){
      setButtonStatus(false)
    }else {
      setButtonStatus(true)
    }
    
    if(event.target.value === 'email'){
      setRadioButton(true)
    }else{
      setRadioButton(false)
    }
  };
  const countryCode = (event) =>{
    setCode(event.target.value)
  }
  const Emailmasked = (name) => {

    const atIndex = name.indexOf('@');
    
    const dotIndex = name.indexOf('.', atIndex);
    
    const nameMaskRange = [0, Math.max(name.substring(0, atIndex).length - 2)];
    
    const domainMaskRange = [Math.min(atIndex + 3, dotIndex), dotIndex];
    
    return name.split('').map((val, i) => {
    if (i < atIndex) {
    
      if (i >= nameMaskRange[0] && i < nameMaskRange[1]) {
      
      return '*';
      
      }
      
      return val;
    
    } else if (i > atIndex) {
    
      if (i > domainMaskRange[0] && i < domainMaskRange[1]) {
      
      return '*';
      
      }
      
      return val;
    
    } 
    return val;
    
    }).join('');
   
    };

    const phonemasked = (number) => {
      return number.split('').map((val,i)=>[1,2,4,5,6,8,9,10].includes(i)?'*':val).join('')
    }
    const keyPress=(event)=>{
      const k = event ? event.which : window.event.keyCode;
      if(event.key === 'v'){
        setBackspaceDelete(true)
      }else{
        setBackspaceDelete(false)
      }
    }
  return (
    <div style={{display:"grid", height:"100vh"}}>
      {loginAlert !== "" && (
        <div
          style={{ fontSize: "18px", color: "#fb1b1b", padding: "0 0 10px" }}
        >
          <Collapse in={open}>
            <Alert
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {loginAlert}
            </Alert>
          </Collapse>
        </div>
      )}
      <div
        style={{
          height: "auto",
          width: "100%",
          display: "initial",
          margin: "auto",
          alignItems: "center",
        }}
      >
        <div style={{padding:"0px 0 0 0"}}>
          <div 
            style={{display:"initial",
            flexDirection:"column",
            justifyContent:"center",
            alignItems:"center",
            padding:"0px"
          }}>
            <img src={logo} />
          </div>
          <div 
          style={{
          fontFamily:"Poppins",
          fontStyle:"normal",
          fontWeight:"400",
          fontSize:"13px",
          lineHeight:"16px",
          width:"490px",
          margin:"auto",
          padding:"0px 0 30px 0",
          color:"#344563"
          }}>
            <p>Welcome to Omnicure Command Center</p>
          </div>
        </div>
        <div
          style={{
            margin: "auto",
            backgroundColor: "white",
            /* justifyContent: "space-evenly", */
            display: "flex",
            flexDirection: "column",
            borderRadius:"10px",
            ...mobileScreen?{
              width:'90%',
              height:'70vh',
              padding:'0 15px'
            }:{
              /* padding: "24px", */
              width: "365px",
              minHeight: "300px"
            }
          }}
        >
         {(generateOtpStatus && !props.passwordExpired) && <div>
            <p
              style={{
              /*  marginBottom: "20px", */
                padding: "24px 24px 8px 24px",
                fontFamily: "Poppins",
                fontStyle: "normal",
                fontWeight: "700",
                fontSize: "22px",
                lineHeight: "28px",
                letterSpacing: "-.2px",
                color: "#010353"
              }}
            >
              Reset Password
            </p>
            {/* <div style={{ borderBottom:"1px solid #EFF1F5", height:"0px", padding:"13px 0 0 0"}}></div> */}
            <div style={{
                textAlign:"center",
                fontFamily:"Poppins",
                fontStyle:"normal",
                fontWeight:"400",
                fontSize:"13px",
                lineHeight:"16px",
                padding:"8px 25px",
                color:"#344563"
                }}>
              <p>To reset your password enter the email address or phone number associated with your account.</p>
            </div>
            <ThemeProvider theme={theme}>
              <div>
                <FormControl component="fieldset" style={{ fontFamily:"Poppins",
                  fontStyle:"normal",
                  fontWeight:"normal",
                  fontSize:"12px !important",
                  }}>
                  <RadioGroup row aria-label="gender" name="gender1" value={value} onChange={handleChange} style={{ fontFamily:"Poppins",
                  fontStyle:"normal",
                  fontWeight:"normal",
                  fontSize:"12px",
                  }}>
                    <FormControlLabel value="email" control={<Radio />} label="Email Address"  style={{ fontFamily:"Poppins",
                    fontStyle:"normal",
                    fontWeight:"normal",
                    fontSize:"12px !important"
                    }}/>
                    <FormControlLabel value="number" control={<Radio />} label="Phone Number" />
                  </RadioGroup>
                </FormControl>
              </div>
              {radioButton && <div style={{display:'grid',gridTemplateRows:'auto auto',rowGap:10, padding: "24px 24px 24px 24px"}}>
                 <TextField
                    placeholder="Enter account email address"
                    size="small"
                    margin="dense"
                    error={errorEmail}
                    helperText={errorMsgEmail}
                    style={{borderRadius:"7px", height:"40px"}}
                    value={email}
                    InputProps={{autoComplete:'off'}}
                    onChange={(event) => {
                      setEmail(event.target.value)
                      setEmailValidation(false)
                      const value = event.target.value;
                      if(EmailValidator.validate(value)){
                        setEmailValidation(true)
                        setErrorEmail(false)
                        setErrorMsgEmail('')
                        setButtonStatus(false)
                      }else{
                        setButtonStatus(true)
                      }
                    }}
                    onBlur={()=>{
                      if(!emailValidation){
                        setErrorMsgEmail(<span style={{fontSize:"12px", color:"red"}}>Enter Valid Email</span>);
                        setErrorEmail(true)
                        setButtonStatus(true)
                      } else{
                        setErrorMsgEmail('')
                        setErrorEmail(false)
                        setButtonStatus(false)
                      }
                    }}
                    variant="outlined"
                    InputProps={{
                      classes:classes,
                      // <-- This is where the toggle button is added.
                      endAdornment: (
                        <InputAdornment position="end" style={{borderRadius:"12px !important"}}>
                          <IconButton>{emailValidation && <CheckIcon color="primary" />}</IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
              </div>}
              {!radioButton && <div style={{display:'flex',gridTemplateRows:'auto auto',rowGap:10, padding: "24px 24px 24px 24px"}}>
                <div style={{display:'flex',gridTemplateRows:'auto auto',rowGap:10, padding: "8px 0px 0px 0"}}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={code}
                    onChange={(event)=>{
                     // console.log(event.target.value)
                      setCode(event.target.value)
                      if(event.target.value && phoneNumber){
                        setButtonStatus(false)
                      }else{
                        setButtonStatus(true)
                      }
                    }}
                    style={{height: "40px", borderRadius:"7px"}}
                  >
                    <MenuItem value={+1}>+1</MenuItem>
                    <MenuItem value={+91}>+91</MenuItem>
                  </Select>
                </FormControl>
                </div>
                <div style={{display:'flex',gridTemplateRows:'auto auto',rowGap:10, padding: "0px 0px 10px 3px"}}>
                 <TextField
                    placeholder="Phone number"
                    margin="dense"
                    error={errorPhoneNumber}
                    helperText={errorMsgPhoneNumber}
                    style={{borderRadius:"7px", height:"40px", width:"255px"}}
                    value={phoneNumber}
                    inputProps={{
                      maxLength: 12,
                    }}
                    onKeyDown={keyPress}
                    onChange={(event) => {
                      let number;
                      let value = event.target.value;
                      let phone;
                      setPhoneNumberValidation(false)
                      if((value.length === 3 || value.length === 7) && event.nativeEvent.inputType === "insertText"){
                        value = event.target.value + "-"
                       setPhoneNumber(value)
                      // console.log(value.length)
                      }else if(backspaceDelete === true){
                        const chars = value.split('');
                        let list = []
                        chars.forEach((element, index) => {
                          if(index === 2 || index === 5){
                            list.push(element + "-")
                          }else{
                            list.push(element)
                          }
                          phone = list.join("")
                        });
                        setPhoneNumber(phone)
                        number = phone.toString()
                      }else{
                        setPhoneNumber(value)
                      }
                      if((value && value.match(/^[0-9-]{12}$/)) || (number && number.match(/^[0-9-]{12}$/) && (number && number.length>=12))){
                        setPhoneNumberValidation(true)
                        setErrorPhoneNumber(false)
                        setErrorMsgPhoneNumber('')
                        if(code){
                          setButtonStatus(false)
                        }else{
                          setButtonStatus(true)
                        }
                      }else{
                        setButtonStatus(true)
                      }
                    }}
                    onBlur={()=>{
                      if(!phoneNumberValidation){
                        setErrorMsgPhoneNumber(<span style={{fontSize:"12px", color:"red"}}>Enter Valid Phone Number</span>);
                        setErrorPhoneNumber(true)
                        setButtonStatus(true)
                      } else{
                        setErrorMsgPhoneNumber('')
                        setErrorPhoneNumber(false)
                        setButtonStatus(false)
                      }
                    }}
                    variant="outlined"
                    InputProps={{
                      classes:classes,
                      // <-- This is where the toggle button is added.
                      endAdornment: (
                        <InputAdornment position="end" style={{borderRadius:"12px !important"}}>
                          <IconButton>{phoneNumberValidation && <CheckIcon color="primary" />}</IconButton>
                        </InputAdornment>
                      ),
                    }}
                  /> 
                </div>
              </div>}
            </ThemeProvider>
          </div>}
         {(submitOtpStatus && !props.passwordExpired) && (loading ? <><div style={{
                textAlign:"center",
                fontFamily:"Poppins",
                fontStyle:"normal",
                fontWeight:"400",
                fontSize:"13px",
                lineHeight:"16px",
                padding:"8px 25px",
                color:"#344563",
                transform:'translateY(100%)',
                position:'relative'
                }}>{verifyOtp?"Verifying OTP, please wait": "Sending OTP, please wait"}</div><div style={{width:'100%',height:265,visibility:'hidden'}}></div></>:
        <div>
          <h6
              style={{
              /*  marginBottom: "20px", */
                fontFamily: "Poppins",
                fontStyle: "normal",
                fontWeight: "700",
                fontSize: "22px",
                lineHeight: "28px",
                letterSpacing: "-.2px",
                color: "#010353",
                padding: "24px 24px 8px 24px"
                /* borderBottom:"1px solid #EFF1F5" */
              }}
            >
              Verify OTP
            </h6>
            {/* <div style={{ borderBottom:"1px solid #EFF1F5", height:"0px", padding:"13px 0 0 0"}}></div> */}
            <div style={{
                textAlign:"center",
                fontFamily:"Poppins",
                fontStyle:"normal",
                fontWeight:"400",
                fontSize:"13px",
                lineHeight:"16px",
                padding:"8px 25px",
                color:"#344563"
                }}>
              <p>{`A one-time code has been sent to the ${email? Emailmasked(email):''}${phoneNumber?phonemasked(phoneNumber):''}`}</p>
            </div>
            <ThemeProvider theme={theme}>
              <div style={{display:'grid',gridTemplateRows:'auto auto',rowGap:10, padding: "15px 24px 15px 24px", justifyContent:"center"}}>
                <OtpInput
                  value={otp}
                  hasErrored={otpStatus}
                  isInputNum={true}
                  onChange={(event) => {
                    setOtpStatus(false)
                    const re = /^[0-9\b]+$/;
                    const otpNo = event;
                    if (otpNo === '' || re.test(otpNo)) {
                      setOtp(event);
                    }
                    if((event && event.length === 4) && event.match(/^[0-9]{4}$/)){
                      setButtonStatus(false)
                    }else if((event && event.length === 4) && !event.match(/^[0-9]{4}$/)){
                      setOtpStatus(true)
                    } else{
                      setButtonStatus(true)
                    }
                  }}
                  numInputs={4}
                  inputStyle={{width:"50px", height:"40px", textAlign:"center", margin:"3px"}}
                  type="tel"
                />
                <span style={{color:"red", fontSize:"12px",height:"15px"}}>{otpStatus?"Enter valid otp": ""}</span>
              </div>
            </ThemeProvider>
          </div>)}
          {(changePasswordStatus || props.passwordExpired) && <div>
          <h6
              style={{
                fontFamily: "Poppins",
                fontStyle: "normal",
                fontWeight: "700",
                fontSize: "22px",
                lineHeight: "28px",
                letterSpacing: "-.2px",
                color: "#010353",
                padding: "24px 24px 8px 24px"
              }}
            >
             {!props.passwordExpired?'New Password' : 'Expired Password'}
            </h6>
            {/* <div style={{ borderBottom:"1px solid #EFF1F5", height:"0px", padding:"13px 0 0 0"}}></div> */}
            <div style={{
                textAlign:"center",
                fontFamily:"Poppins",
                fontStyle:"normal",
                fontWeight:"400",
                fontSize:"13px",
                lineHeight:"16px",
                padding:"8px 25px",
                color:"#344563"
                }}>
              <p>{!props.passwordExpired?'Enter your new password below.' : 'Your password has expired. Please enter a new password to access your account.'}</p>
            </div>
            <ThemeProvider theme={theme}>
              <div style={{display:'grid',gridTemplateRows:'auto auto',rowGap:10, padding: "24px 24px 24px 24px"}}>
                <TextField
                  classes={classes}
                  style={{ borderRadius:"7px", height:"40px"}}
                  error={errorPassword}
                  helperText={errorMsgPassword}
                  value={newPassword}
                  placeholder="Enter new password"
                  arial-label="Enter new password"
                  size="small"
                  margin="dense"
                  onChange={(event) => {
                    setNewPassword(event.target.value)
                    setPasswordValidation(false)
                    setLowerCase(false)
                    setUpperCase(false)
                    setSpecialChar(false)
                    setNumber(false)
                    setMinimumChar(false)
                    if(event.target.value.length>0){
                      setEnableInfo(true)
                    }else{
                      setEnableInfo(false)
                    }
                    if(PassValidation(event.target.value)){
                      setInfoTicEnableDisable(true)
                      setErrorPassword(false)
                      setErrorMsgPassword('')
                    }else{
                      setInfoTicEnableDisable(false)
                    }
                    if(PassValidation(event.target.value)){
                      setPasswordValidation(true)
                      if(confirmPasswordValidation && event.target.value === confirmPassword){
                        setButtonStatus(false)
                        setErrorMsgConfirmPassword('')
                      }else if(confirmPasswordValidation && event.target.value !== confirmPassword){
                        setButtonStatus(true)
                        setErrorMsgConfirmPassword(<span style={{fontSize:"12px", color:"red", margin:"0px"}}>Password does not match</span>)
                      }
                    }else{
                      setButtonStatus(true)
                    }
                    if(event.target.value.match(/[a-z]/)){
                      setLowerCase(true)
                    }
                    if(event.target.value.match(/[A-Z]/)){
                      setUpperCase(true)
                    }
                    if(event.target.value.match(/[@$!%*?&]/)){
                      setSpecialChar(true)
                    }
                    if(event.target.value.match(/[0-9]/)){
                      setNumber(true)
                    }
                    if(event.target.value.length >= 8){
                      setMinimumChar(true)
                    }
                  
                  }}
                  onBlur={()=>{
                    if(!passwordValidation){
                      setErrorMsgPassword(<span style={{fontSize:"12px", color:"red", margin:"0px"}}>Invalid password. Try Again</span>)
                      setErrorPassword(true)
                      setButtonStatus(true)
                    }else if(confirmPassword !== newPassword){
                      setButtonStatus(true)
                    } else{
                      setErrorMsgPassword('')
                      setErrorPassword(false)
                      if(confirmPasswordValidation){
                        setButtonStatus(false)
                      }else{
                        setButtonStatus(true)
                      }
                    }
                  }}
                  type={showPassword ? "text" : "password"}
                  autoComplete="current-password"
                  variant="outlined"
                  InputProps={{
                    classes:classes,
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          color="primary"
                        >
                          {showPassword ? <Visibility color="primary" /> : <VisibilityOff color="primary" />}
                        </IconButton>
                        {infoTicEnableDisable &&<IconButton> <CheckIcon color="primary" /></IconButton>}
                          {enableInfo && !infoTicEnableDisable && <div>
          
                            <HtmlTooltip
                              onMouseOut={handleTooltipClose}
                                open={tooltipNew}
                                disableFocusListener
                                disableHoverListener
                                disableTouchListener
                                title={
                                <React.Fragment>
                                  <div
                                  style={{
                                    backgroundColor: "#172B4D",
                                    color: "#FFFFFF",
                                    borderRadius: "8px",
                                    width: "200px",
                                    margin: theme.spacing(-1),
                                    boxShadow: theme.shadows[5],
                                    fontFamily: "Poppins",
                                    fontStyle: "normal",
                                    fontWeight: "normal",
                                    fontSize: "12px",
                                    lineHeight: "16px"
                                  }}
                                  >
                                  <table style={{ width: "100%", padding:"8px"}}>
                                  <tbody>
                                    <tr>
                                      <td style={{ width: "90%" }}>Password required</td>
                                    </tr>
                                    <tr>
                                      <td style={{ width: "90%" }}>Minimum 8 char</td>
                                      <td style={{ width: "10%" }} align="center">
                                        {minimumChar && (
                                          <CheckCircle style={{ color: "#9b51e0", fontSize: "12px" }} />
                                        )}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style={{ width: "90%" }}>Atleast 1 lower case</td>
                                      <td style={{ width: "10%" }} align="center">
                                        {lowerCase && (
                                          <CheckCircle style={{ color: "#9b51e0", fontSize: "12px" }} />
                                        )}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style={{ width: "90%" }}>Atleast 1 upper case</td>
                                      <td style={{ width: "10%" }} align="center">
                                        {upperCase && (
                                          <CheckCircle style={{ color: "#9b51e0", fontSize: "12px" }} />
                                        )}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style={{ width: "90%" }}>Atleast 1 number</td>
                                      <td style={{ width: "10%" }} align="center">
                                        {number && (
                                          <CheckCircle style={{ color: "#9b51e0", fontSize: "12px" }} />
                                        )}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style={{ width: "80%" }}>Atleast 1 special char</td>
                                      <td style={{ width: "10%" }} align="center">
                                        {specialChar && (
                                          <CheckCircle style={{ color: "#9b51e0", fontSize: "12px" }} />
                                        )}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                  </div>
                                </React.Fragment>
                              }
                            >
                              <IconButton>
                                <InfoIcon color="primary" onMouseOver={()=>{
                                  setTooltipNew(true)
                                }} />
                              </IconButton>
                            </HtmlTooltip>
                          </div>}
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  classes={classes}
                  style={{ borderRadius:"7px", height:"40px"}}
                  error={errorConfirmPassword}
                  helperText={errorMsgConfirmPassword}
                  value={confirmPassword}
                  placeholder="Confirm new password"
                  arial-label="Confim new password"
                  size="small"
                  margin="dense"
                  onChange={(event) => {
                    setConfirmPassword(event.target.value)
                    setConfirmPasswordValidation(false)
                    setConfirmLowerCase(false)
                    setConfirmUpperCase(false)
                    setConfirmSpecialChar(false)
                    setConfirmNumber(false)
                    setConfirmMinimumChar(false)
                    if(event.target.value){
                      setConfirmEnableInfo(true)
                    }else{
                      setConfirmEnableInfo(false)
                    }
                    if(PassValidation(event.target.value)){
                      setConfirmInfoTicEnableDisable(true)
                      setErrorConfirmPassword(false)
                      setErrorMsgConfirmPassword('')
                    }else{
                      setConfirmInfoTicEnableDisable(false)
                    }
                    if(PassValidation(event.target.value)){
                      setConfirmPasswordValidation(true)
                      if(passwordValidation && (newPassword === event.target.value)){
                        setButtonStatus(false)
                      }else{
                        setButtonStatus(true)
                      }
                    }else{
                      setButtonStatus(true)
                    }
                    if(event.target.value.match(/[a-z]/)){
                      setConfirmLowerCase(true)
                    }
                    if(event.target.value.match(/[A-Z]/)){
                      setConfirmUpperCase(true)
                    }
                    if(event.target.value.match(/[@$!%*?&]/)){
                      setConfirmSpecialChar(true)
                    }
                    if(event.target.value.match(/[0-9]/)){
                      setConfirmNumber(true)
                    }
                    if(event.target.value.length >= 8){
                      setConfirmMinimumChar(true)
                    }
                  
                  }}
                  onBlur={()=>{
                    if(!confirmPasswordValidation){
                      setErrorMsgConfirmPassword(<span style={{fontSize:"12px", color:"red", margin:"0px"}}>Invalid Confirm password. Try Again</span>)
                      setButtonStatus(true)
                      setErrorConfirmPassword(true)
                     // setConfirmEnableInfo(true)
                    }else if(confirmPassword !== newPassword){
                      setErrorMsgConfirmPassword(<span style={{fontSize:"12px", color:"red", margin:"0px"}}>Password does not match</span>)
                      setButtonStatus(true)
                      setErrorConfirmPassword(true)
                    } else{
                      setErrorMsgConfirmPassword('')
                      setErrorConfirmPassword(false)
                     // setConfirmEnableInfo(false)
                     if(passwordValidation){
                        setButtonStatus(false)
                      }else{
                        setButtonStatus(true)
                      }
                    }
                  }}
                  type={showConfirmPassword ? "text" : "password"}
                  autoComplete="current-password"
                  variant="outlined"
                  InputProps={{
                    classes:classes,
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickConfimShowPassword}
                          onMouseDown={handleMouseDownConfirmPassword}
                          color="primary"
                        >
                          {showConfirmPassword ? <Visibility color="primary" /> : <VisibilityOff color="primary" />}
                        </IconButton>
                        {confirmInfoTicEnableDisable &&<IconButton> <CheckIcon color="primary" /></IconButton>}
                          {confirmEnableInfo && !confirmInfoTicEnableDisable && <div>
          
                            <HtmlTooltip
                              onMouseOut={handleTooltipClose}
                                open={tooltipConfirm}
                                disableFocusListener
                                disableHoverListener
                                disableTouchListener
                                title={
                                <React.Fragment>
                                  <div
                                  style={{
                                    backgroundColor: "#172B4D",
                                    color: "#FFFFFF",
                                    borderRadius: "8px",
                                    width: "200px",
                                    margin: theme.spacing(-1),
                                    boxShadow: theme.shadows[5],
                                    fontFamily: "Poppins",
                                    fontStyle: "normal",
                                    fontWeight: "normal",
                                    fontSize: "12px",
                                    lineHeight: "16px"
                                  }}
                                  >
                                  <table style={{ width: "100%", padding:"8px"}}>
                                  <tbody>
                                    <tr>
                                      <td style={{ width: "90%" }}>Password required</td>
                                    </tr>
                                    <tr>
                                      <td style={{ width: "90%" }}>Minimum 8 char</td>
                                      <td style={{ width: "10%" }} align="center">
                                        {confirmMinimumChar && (
                                          <CheckCircle style={{ color: "#9b51e0", fontSize: "12px" }} />
                                        )}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style={{ width: "90%" }}>Atleast 1 lower case</td>
                                      <td style={{ width: "10%" }} align="center">
                                        {confirmLowerCase && (
                                          <CheckCircle style={{ color: "#9b51e0", fontSize: "12px" }} />
                                        )}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style={{ width: "90%" }}>Atleast 1 upper case</td>
                                      <td style={{ width: "10%" }} align="center">
                                        {confirmUpperCase && (
                                          <CheckCircle style={{ color: "#9b51e0", fontSize: "12px" }} />
                                        )}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style={{ width: "90%" }}>Atleast 1 number</td>
                                      <td style={{ width: "10%" }} align="center">
                                        {confirmNumber && (
                                          <CheckCircle style={{ color: "#9b51e0", fontSize: "12px" }} />
                                        )}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style={{ width: "80%" }}>Atleast 1 special char</td>
                                      <td style={{ width: "10%" }} align="center">
                                        {confirmSpecialChar && (
                                          <CheckCircle style={{ color: "#9b51e0", fontSize: "12px" }} />
                                        )}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                  </div>
                                </React.Fragment>
                              }
                            >
                              <IconButton>
                                <InfoIcon color="primary" onMouseOver={()=>{
                                  setTooltipConfirm(true)
                                }} />
                              </IconButton>
                            </HtmlTooltip>
                          </div>}
                      </InputAdornment>
                    ),
                  }}
                />  
              </div>
            </ThemeProvider>
          </div>}
          {(successMsg && !props.passwordExpired) && <div>
            <div style={{position:"relative", margin:"25px 0 0 0"}}>
              <img src={ElipsOne} alt=""  />
              <img src={ElipsTwo} alt="" style={{position:"absolute", left:"39.5%", top:"11px"}} />
              <img src={ElipsThree} alt="" style={{position:"absolute", left:"42.5%", top:"22px"}} />
              <img src={Vector} alt="" style={{position:"absolute", left:"47%", top:"40px"}}/>  
            </div>
            <h6
              style={{
              /*  marginBottom: "20px", */
                color: "#9b51e0",
                fontSize: "20px",
                fontFamily:"Poppins",
                fontStyle:"normal",
                fontWeight:"600",
                lineHeight:"28px",
                padding: "10px 24px 10px 24px"
                /* borderBottom:"1px solid #EFF1F5" */
              }}
            >
              Success!
            </h6>
            {/* <div style={{ borderBottom:"1px solid #EFF1F5", height:"0px", padding:"13px 0 0 0"}}></div> */}
            <div style={{
                textAlign:"center",
                fontFamily:"Poppins",
                fontStyle:"normal",
                fontWeight:"normal",
                fontSize:"12px",
                lineHeight:"16px",
                padding:"0px 25px"
                }}>
              <p>Your password has been reset.</p>
            </div>
          </div>}
         {(generateOtpStatus && !props.passwordExpired) && <Button
            color="primary"
            variant="contained"
            id="signInButton"
            disabled={buttonStatus}
            style={{
              margin:"0px 24px",
              fontFamily: "Poppins,sans-serif",
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "0.875rem",
              lineHeight: "1.75",
              display: "flex",
              alignItems: "center",
              textAlign: "center",
              color:buttonStatus === false? "#ffffff" : "#344563",
              padding:"8px 20px",
              borderRadius:"8px",
              backgroundColor:buttonStatus === false? "#9B51E0" : "#d1d6dd",
              textTransform:"none"
            }}
            onClick={generateotp}
          >
            Reset
          </Button>}
          {(submitOtpStatus && !loading && !props.passwordExpired) && <Button
            color="primary"
            variant="contained"
            id="signInButton"
            disabled={buttonStatus}
            style={{
              margin:"0px 24px 15px 24px",
              fontFamily: "Poppins",
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "0.875rem",
              lineHeight: "1.75",
              display: "flex",
              alignItems: "center",
              textAlign: "center",
              color: buttonStatus === false? "#ffffff" : "#344563",
              padding:"8px 20px",
              borderRadius:"8px",
              backgroundColor:buttonStatus === false? "#9B51E0" : "#d1d6dd",
              textTransform:"none"
            }}
            onClick={submitotp}
          >
            Verify
          </Button>}
          {(changePasswordStatus || props.passwordExpired) && <Button
            color="primary"
            variant="contained"
            id="signInButton"
            disabled={buttonStatus}
            style={{
              margin:"0px 24px",
              fontFamily: "Poppins",
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "0.875rem",
              lineHeight: "1.75",
              display: "flex",
              alignItems: "center",
              textAlign: "center",
              color: buttonStatus === false? "#ffffff" : "#344563",
              padding:"8px 20px",
              borderRadius:"8px",
              backgroundColor:buttonStatus === false? "#9B51E0" : "#d1d6dd",
              textTransform:"none"
            }}
            onClick={changePassword}
          >
            Submit
          </Button>}
          {(successMsg && !props.passwordExpired) && <Button
              color="primary"
              variant="contained"
              id="signInButton"
              style={{
                margin:"10px 24px 0px 24px",
                fontFamily: "Poppins",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "0.875rem",
                lineHeight: "1.75",
                display: "flex",
                alignItems: "center",
                textAlign: "center",
                padding:"8px 20px",
                borderRadius:"8px",
              textTransform:"none"
              }}
              onClick={login}
            >
              Go to Login
            </Button>}
            {
              retryOTP&&submitOtpStatus&&!loading&&<ThemeProvider theme={theme}>{secondsValue ?<div style={{fontSize:"14px", fontFamily:"Poppins,sans-serif"}}>{`Didn’t receive a code? Receive new in ${moment.duration(secondsValue,'seconds').minutes().toString().padStart(2,'0')}:${moment.duration(secondsValue,'seconds').seconds().toString().padStart(2,'0')}`}</div>:<Link 
                onClick={()=>{
                  retryOTPFunc();
                  setRetryOTP(false)
                  setSecondsValue(25)
                  setLoginAlert("")
                }} 
                style={{cursor:'pointer',textDecoration: "underline",fontFamily: "Poppins",fontSize:12}}>Request new one</Link>}
              </ThemeProvider>
            }
            {!loading && !changePasswordStatus && !props.passwordExpired && <ThemeProvider theme={theme}><div>{!retryOTP? <div style={{fontSize:"14px", fontFamily:"Poppins,sans-serif"}}>{`Didn’t receive a code? Receive new in ${moment.duration(secondsValue,'seconds').minutes().toString().padStart(2,'0')}:${moment.duration(secondsValue,'seconds').seconds().toString().padStart(2,'0')}`}</div>:<div></div>}</div></ThemeProvider>}

         {!successMsg && !submitOtpStatus && <div style={{padding:"24px", textAlign:"end"}}>
            <p style={{fontSize:"14px", fontFamily:"Poppins,sans-serif"}}> Back to <a onClick={login} style={{fontSize:"13px", color: "#9B51E0", textDecoration: "none", cursor:"pointer", textTransform:"uppercase", fontWeight:"bold"}}>Sign In</a></p>
          </div>}
        {!successMsg && !submitOtpStatus && <ThemeProvider theme={theme}>
          <div style={{ 
              fontFamily: "Poppins",
              fontStyle: "normal",
              fontWeight: "normal",
              fontSize: "12px",
              lineHeight: "16px",
              padding:"20px 20px",
              color: "#344563"
              }}>
            <span>View our  
              <Link style={{textDecoration: "underline"}} href="https://www.omnicuremd.com/privacy" target="_blank"> Privacy Policy</Link> &  
              <Link style={{textDecoration: "underline"}} href="https://www.omnicuremd.com/terms" target="_blank"> Terms of Usage</Link>
            </span>
          </div>
        </ThemeProvider>}
          {loading && <LoginLoader />}
        </div>
      </div>
      {expiredMsg && <div><ExpiredMessage expiredMsg={expiredMsg} setexpiredLogin={setexpiredLogin}/></div>}
    </div>
  );
};
export default ForgotPasswordScreen;
