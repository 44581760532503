import React, { useEffect, useState } from "react";
import {
  useHistory,
} from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Menu,
  MenuItem,IconButton} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import "../admin/screens/LoginScreen.css";
import oc_logo from "../../assets/images/omnicure_logo.svg";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import {ReactComponent as OmnicureTextIcon} from 'assets/images/OmnicureTextIcon.svg';
import {ReactComponent as HomeIcon} from 'assets/images/Home.svg';
import {ReactComponent as SettingIcon} from 'assets/images/Setting.svg';
import {ReactComponent as Logout} from 'assets/images/Logout_Latest.svg';
const usePasswordStyles = makeStyles({
  error: {
    whiteSpace: "pre-wrap",
  },
  root: {
    "& label.Mui-focused": {
      color: "green",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "green",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "green",
      },
    },
  },
  partialCorrect: {
    "& label.Mui-focused": {
      color: "yellow",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "yellow",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "yellow",
      },
    },
  },
  wrong: {
    "& label.Mui-focused": {
      color: "red",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "red",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "red",
      },
    },
  },
  ullist: {
    ".MuiList-padding": {
      padding: 0,
    },
    "& .MuiListItem-root": {
      padding: 0,
    },
    "& .MuiTypography-body1": {
      fontSize: "small",
      lineHeight: 1,
      color: "red",
    },
  },
  btndisable: {
    backgroundColor: "#ebebeb",
    cursor: " not-allowed !important",
    pointerEvents: "none !important",
    /* '&:hover': {
        backgroundColor: "#ebebeb",
        cursor:' not-allowed',
        pointerEvents: 'none'
    } */
  },
  toolBar: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft:'40px !important',
    paddingRight:'40px !important',
    boxShadow: '0px 6px 3px rgba(0, 0, 0, 0.1)'
  },

  iconStyle: {
    fontSize: "2rem",
    //marginRight: "10px",
    // margin: '5px 0px 5px 5px'
  },
  menuList: {
    padding: "0px 8px !important",
    '&:hover':{
        background:'#EFF1F5',
        fontWeight:'bold'
    }
},
menuContainer:{
    border: '1px solid #5E6C84',
    boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.15)',
    borderRadius: 8,
    fontFamily:'Poppins'
},
  container: {
    position: "relative",
    width: "100%",
    overflow: "hidden",
    paddingTop: "56.25%" /* 16:9 Aspect Ratio */
  },
  responsiveIframe: {
    position: "absolute",
    top: "0",
    left: "0",
    bottom: "0",
    right: "0",
    width: "100%",
    height: "100%",
    border: "none"
  }
});


function OcReportScreen({ setUserId, setUserDetails, setApp }) {
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const [expiredLogin] = useState(false);

  const signOut = () => {
    //sessionStorage.removeItem('userId','userDetails','app','userId')
    setUserId(null);
    setUserDetails({});
    setApp(null);
    if ('caches' in window) {
     // console.log('=caches==', caches)
      caches.keys().then((names) => {
       // console.log('==names===', names)
        names.forEach(name => {
          caches.delete(name);
        })
      });
      window.location.reload(true);
      sessionStorage.clear();
      sessionStorage.clear();

    }
    history.replace("/");
  };
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  }

  const handleMenuClose = () => {
    setAnchorEl(null);
  }
  const changePasswordScreen = () => {
    history.push("/app/changePassword");
  }
  const passwordStyles = usePasswordStyles();

  useEffect(() => {
    if (expiredLogin) {
      setUserId(null)
      history.push("/")
    }
  }, [expiredLogin])

  const navigationHome = () => {
    history.push("/homepage")
  }

  return (
    <div>
      <div
        style={{
          width: "100%",
          display: "flex",
          margin: "auto",
          alignItems: "center",
          minHeight: "calc(100vh - 60px)",
          paddingTop: 0,
          flexDirection: 'column'
        }}
      >
        <AppBar><Toolbar className={passwordStyles.toolBar}>
        <div style={{display:'flex'}}><OmnicureTextIcon /></div>
          <div>
            <IconButton className={passwordStyles.iconStyle} color="primary" onClick={navigationHome}><HomeIcon /></IconButton>
            <IconButton
              aria-controls="simple-menu"
              aria-haspopup="true"
              className={passwordStyles.iconStyle}
              color="primary"
              onClick={handleMenuClick}
            ><SettingIcon /></IconButton>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              style={{ margin: "55px 0 0 0" }}
              classes={{paper:passwordStyles.menuContainer}}
            >
              <MenuItem className={passwordStyles.menuList} disabled>
                <a onClick={changePasswordScreen}>Change Password</a>
              </MenuItem>
            </Menu>
            <IconButton
              className={passwordStyles.iconStyle}
              color="primary"
              onClick={signOut}
            ><Logout/></IconButton>
          </div>
        </Toolbar>
        </AppBar>
        <div className={passwordStyles.container}>
          <iframe className={passwordStyles.responsiveIframe} src={process.env.REACT_APP_ocReports}></iframe>
        </div>
      </div>
    </div>
  );
}

export default OcReportScreen;
