import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import  firebase from "firebase/app";
//import "firebase/auth";
//import {firebaseId} from "../../App";
import { useRecoilValue, useResetRecoilState } from "recoil";
import { useLocation } from "react-router-dom";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { UILevelPermission, permission, PermissionCollection, usersID, DelayNotificationTime, roles, firebaseId,AlertListNotification,unReadNotificationList,ShowNotification,unreadNotificationCount } from 'App';


const approvalListtheme = createMuiTheme({
    palette: {
      primary: {
        // Purple and green play nicely together.
        main: "#9B51E0",
      },
      secondary: {
        // This is green.A700 as hex.
        main: "#172B4D",
      },
      success: {
        // This is green.A700 as hex.
        main: "#cb114c",
      },
    },
    typography: {
      fontFamily: 'Poppins'
    },
    overrides: {
      MuiMenu: {
        paper: {
          border: '1px solid #5E6C84',
          borderRadius: 8
        }
      },
      MuiDialog: {
        paper: {
          minWidth: '42vw',
          borderRadius: 10
        }
      },
      MuiDialogTitle: {
        root: {
          fontWeight: 700,
          fontSize: 18,
          lineHeight: '24px',
          borderBottom: '1px solid #EBECF0'
        }
      },
      MuiSelect: {
        icon: {
          top: 'initial',
          right: '10px'
        }
      },
      MuiDialogContentText: {
        root: {
          fontSize: 14,
          fontWeight: 400,
          lineHeight: '20px'
        }
      },
    }
  })
const ProviderNotification=({
    setUserId,
    setUserDetails,
    setApp,
    setUsersUid,
    setFirebaseId,
    setUserPermission,
    //setUserAccessId(null)
    setRefreshId,
    setTokenId,
    logoutUserToken,
    setSessionFirebaseToken,
    setLogoutUserToken
})=>{
    const firebaseUid = useRecoilValue(firebaseId)
    const location = useLocation()
    const [notification, setNotification] = useState("");
    const [deniedBy, setDeniedBy] = useState("");
    const [title, setTitle] = useState("");
    const [token, setToken] = useState("");
    const [open, setOpen] = React.useState(false);
    const history = useHistory();
    const resetDelayNotificationTime = useResetRecoilState(DelayNotificationTime)
    const resetAlertListNotificationTime = useResetRecoilState(AlertListNotification)
    const resetUnreadNotificationList = useResetRecoilState(unReadNotificationList)
    const resetShowNotification = useResetRecoilState(ShowNotification)
    useEffect(()=>{
        if(location.pathname !== '/login'){
            const dataBase = firebase.database()
            dataBase.ref(`providers/${firebaseUid}/notification`).on('value',(snapshot)=>{
                const providerSnapshot = snapshot.val()
                providerSnapshot && setNotification(providerSnapshot.messageType)
                providerSnapshot && setDeniedBy(providerSnapshot.message)
                providerSnapshot && setTitle(providerSnapshot.title)
                providerSnapshot && setToken(providerSnapshot.token)
                providerSnapshot && setSessionFirebaseToken(providerSnapshot.token)
                return () => {
                    dataBase.off('value')
                }
            })
        }
    },[location,firebaseUid])
    useEffect(()=>{
        if(logoutUserToken === token){ 
            if(notification !=='' && notification !== null){
                setOpen(true)
            }
        }
    },[notification,logoutUserToken, token])
    const handleClose = () => {
        const dataBase = firebase.database()
        dataBase.ref(`providers/${firebaseUid}/notification`).set(null)
        setOpen(false);
        setNotification(null)
        setUserId(null);
        setUserDetails(null);
        setApp(null);
        setUsersUid(null)
        setFirebaseId(null)
        setUserPermission(null)
        setRefreshId(null)
        setTokenId(null)
        resetDelayNotificationTime()
        resetAlertListNotificationTime()
        resetUnreadNotificationList()
        resetShowNotification()
        history.replace("/");
        setLogoutUserToken('')
    };
    return (
        <div>
            <ThemeProvider theme={approvalListtheme} >
                <Dialog
                    open={open}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{...title==='Denied User'&&{backdropFilter:'blur(10px)'}}}
                >
                    <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {deniedBy}
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={handleClose} color="primary" variant="contained">
                        Ok
                    </Button>
                    </DialogActions>
                </Dialog>
            </ThemeProvider>
        </div>
    )
}
export default ProviderNotification;