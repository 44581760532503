import React,{useState, useEffect} from "react";
import MenuItem from "@material-ui/core/MenuItem";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import './AcuityScreen.css';
import  Firebase from "firebase";
import {Firebase_APP} from "../../../App";
import FormControl from "@material-ui/core/FormControl";
import Select from '@material-ui/core/Select';
import InputLabel from "@material-ui/core/InputLabel";
import API from "api";
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { resetContent } from "medium-editor";
import CustomLoader from "../../common/CustomLoader";
//import "firebase/auth";

const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    highlight: {
      borderBottom: "4px solid #e0516b",
    },
    bottom: {
      marginBottom: "0px",
    },
    headertext: {
      color: "#ac8cf2",
      fontSize: "2rem",
      lineHeight: window.innerWidth<375?1.5:3,
    },
    subheadertext: {
      color: "#c7c0c0",
      fontSize: "1.2rem",
      alignSelf: "flex-end",
      marginBottom: "100px",
    },
    gridBox:{
        boxShadow:"0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);",
        backgroundColor:"#ffffff"
    },
    paper: {
      position: "absolute",
      width: 300,
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #500c0c",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      margin: "20% 48%",
      borderRadius: " 5px",
      textAlign: "center",
    },
  }));
const {REACT_APP_databaseURL} = process.env
/* const useTableStyles = makeStyles({
    gridBox:{
        boxShadow:"0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);",
        backgroundColor:"#ffffff"
    }
  }); */
const AcuityComponents=()=>{
    const {gridBox} = useStyles();
    const [dataBaseValueOne, setdataBaseValueOne] = useState([]);
    const [dataBaseValueTwo, setdataBaseValueTwo] = useState([]);
    const [dataBaseValueThree, setdataBaseValueThree] = useState([]);
    const [drpValues, setDrpValues] = useState({});
    const [newPatient, setNewPatient] = useState([]);
    const [patientUrgent, setPatientUrgent] = useState(false);
    const [loader, setLoader] = useState(true);
    const [notification, setNotification] = useState("")
    /* const Item = styled(Paper)(({ theme }) => ({
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
      })); */
     useEffect(()=>{
         let dataBase
        dataBase = Firebase_APP.database();
        dataBase.ref(`tmpproviders`).on('value',(snapshot)=>{
            const snapValue = {}
            snapshot && snapshot.val() && snapshot.val().forEach((value, index) => {
                const snapValueInner = {}
                /* value.active.forEach((innervalue, innerIndex)=>{
                    snapValueInner[innerIndex] = innervalue
                }) */
               // value.active = snapValueInner
                snapValue[index] = value
            });
            setdataBaseValueOne(snapValue)
        })
        dataBase.ref(`tmpnotification`).on('value', (snapshot)=>{
            snapshot && snapshot.val() &&  setNotification(snapshot.val().message)
        })
     },[])
     useEffect(()=>{
        if(dataBaseValueOne){
            setLoader(false)
        }
     },[dataBaseValueOne])
    const acceptPatientInvite = (providerId) => {
        API.post("/userEndpoints/v1/acceptInvite",{
            "patientId":drpValues[providerId],
            "id":providerId
        }).then((res)=>{
            setDrpValues(oldValues=>{delete oldValues[providerId]; return {...oldValues}})
        }).catch(()=>{

        })
     }
     
    const requestConsult=(event)=>{
        API.post("/userEndpoints/v1/addPatient",{
            "score":newPatient,
            "urgent":patientUrgent?patientUrgent: false
        }).then((res)=>{
            setPatientUrgent(false)
            setNewPatient([])
        }).catch(()=>{

        })
    }
    return(
        <div>
            <div>
                <header className="App-header">
                    <p>Acuity Status POC</p>
                </header>
            </div>
            <div style={{padding:"5px", borderRadius:"8px"}}>
                <div style={{width:"100%", display:"flex", fontSize:"14px"}}>
                    <div className="acuityTable" style={{width:"75%", marginRight:"5px", backgroundColor:"#ffffff"}}>
                        <div style={{border:"1px solid #f7f5f5", padding:"10px 0", margin:"5px", borderRadius:"6px", color:"#9b51e0", backgroundColor:"#eef5fb", color:"#9b51e0"}}>{notification}</div>
                        <div style={{width:"99%", display:"flex", justifyContent:"space-between", padding:"10px 15px", margin:"5px", borderRadius:"6px", border:"1px solid #f7f5f5"}}>
                            {Object.entries(dataBaseValueOne).map(([providerId,value])=>{
                            return(

                                <div>
                                    <div style={{border:"1px solid #e1dfdf"}}>
                                        <div style={{padding:"10px 40px"}}>
                                            <span>{`MD/DO ${providerId}`}</span>
                                        </div>
                                    </div>
                                    <div style={{height:"200px", overflow:"auto", marginBottom:"10px"}}>{
                                       value && value.active && Object.entries(value.active).map(([patientId,innerValues])=>{
                                            if(innerValues.status === 'Active'){
                                                return (
                                                    <div style={{border:innerValues.score === 'High'?"2px solid #E52727" : innerValues.score === 'Medium'?"2px solid #EB7B18" : "2px solid #5EC89B", backgroundColor:innerValues.score === 'High'?"#FBDDDD" : innerValues.score === 'Medium'?"#FCF4DB" : "#DDF2E9", margin:"3px", borderRadius:"6px", marginBottom:"10px"}}>{`${innerValues.score}`}</div>
                                                )
                                            }
                                        })
                                    }</div>
                                    <div>
                                        <div style={{padding:"0 0 15px 0"}}>
                                        <FormControl
                                            /* className="form-text-field" */
                                            variant="outlined"
                                            >
                                            <InputLabel
                                                id="demo-simple-select-label"
                                            >
                                                Select
                                            </InputLabel>
                                            <Select
                                                style={{fontSize:'0.875rem', width:"180px"}}
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={drpValues[providerId]}
                                                label={"Select"}
                                                onChange={(event) => {
                                                    setDrpValues(oldValues=>({...oldValues, [providerId]:event.target.value}))
                                                }}
                                            >
                                                {
                                               value && value.active && Object.entries(value.active).map(([patientId,innerValues])=>{
                                                    if(innerValues.status === 'Invited'){
                                                        return(
                                                            <MenuItem value={innerValues.id}>{`${innerValues.id} ${innerValues.score} ${innerValues.urgent?`urgent`:`not urgent`}`}</MenuItem>
                                                        )
                                                    }
                                                }
                                                )
                                                }
                                            </Select>
                                        </FormControl>
                                        </div>
                                        <Button variant="contained"
                                        color="primary"
                                        style={{margin:"5px"}}
                                        disabled={value.accept === false}
                                        onClick={()=>{
                                            acceptPatientInvite(providerId);
                                        }}
                                        >
                                        Accept
                                        </Button>
                                    </div>
                                </div>
                            )

                            })} 
                        </div>
                    </div>
                    <div className="acuityTable" style={{width:"25%", backgroundColor:"#ffffff"}}>
                        <div style={{border:"1px solid #f7f5f5",  padding:"0", margin:"5px", borderRadius:"6px", height:"200px"}}>
                            <div style={{textAlign:"left", borderBottom:"1px solid #f7f5f5", padding:"10px 10px",backgroundColor:"#eef5fb"}}>Title</div>
                        </div>
                        <div  style={{border:"1px solid #f7f5f5",  padding:"0", margin:"5px", borderRadius:"6px"}}>
                            <div style={{textAlign:"left", padding:"10px", borderBottom:"1px solid #f7f5f5",backgroundColor:"#eef5fb"}}>Bedside provider</div>
                            <div style={{display:"flex", justifyContent:"space-between", alignItems:"center", padding:"10px"}}>
                                <div><span>Acuity</span></div>
                                <FormControl
                                    /* className="form-text-field" */
                                    variant="outlined"
                                >
                                    <InputLabel id="demo-simple-select-label">
                                        Select
                                    </InputLabel>
                                    <Select
                                        style={{fontSize:'0.875rem', width:"150px"}}
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={newPatient}
                                        label={"Select"}
                                        onChange={(event) => {
                                            setNewPatient(event.target.value)
                                        }}
                                    >
                                        <MenuItem value="High">High</MenuItem>
                                        <MenuItem value="Medium">Medium</MenuItem>
                                        <MenuItem value="Low">Low</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            <div style={{display:"flex", justifyContent:"space-between", alignItems:"center", padding:"10px"}}>
                            <FormControl 
                                style={{
                                    fontFamily: "Poppins",
                                    fontStyle: "normal",
                                    fontWeight: "500",
                                    fontSize: "12px",
                                    lineHeight: "16px",
                                    float:"left"
                                    }}
                                >
                                <FormGroup aria-label="position">
                                    <FormControlLabel
                                    value={patientUrgent}
                                    control={<Checkbox color="primary" checked={patientUrgent}  onChange={(event)=>{
                                        setPatientUrgent(event.target.checked)
                                    }}/>}
                                    label="Mark as urgent"
                                    labelPlacement="end"
                                    />
                                </FormGroup>
                            </FormControl>
                            </div>
                            <div>
                            <Button variant="contained"
                                        color="primary"
                                        style={{margin:"5px"}}
                                        onClick={()=>{
                                            requestConsult();
                                        }}
                                        >
                                        Request Consulst
                                        </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default AcuityComponents;